import { DefaultBackNextButtons, DefaultCancelProceedButtons, DefaultFinishButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";
import { WorkflowActionState } from "./workflow.interface";

function getPathwayStateSelector(state: WorkflowActionState) {
    return `[data-tour="pathway-state-${WorkflowActionState[state]}"]`;
}

export const WorkflowTour: GuidedTour = {
    steps: [{
        title: "Pathways",
        text: "This is the pathways page. These pathways are designed to help you build a resilient, sustainable and scalable business.",
        buttons: DefaultCancelProceedButtons,
    }, {
        title: "Outcomes",
        text: `
        This is an outcome.
        <br/><br/>
        The outcomes are what your business can achieve from following the adapt way framework.
        <br/><br/>
        We’ve broken the adapt way into 7 outcomes you can expect from following the process.
        Each outcome consists of one or more pathways, which are shown to the right.`,
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="Foundation"] adapt-display-workflow-category`,
        attachTo: {
            on: "top-start",
        },
        scrollTo: {
            behavior: "smooth",
            block: "center",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Pathway",
        text: "This is a pathway. It's a piece of work that an individual or a team will do to move them towards an outcome.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="DefineOwnersWhy"]`,
        attachTo: {
            on: "top",
        },
        scrollTo: {
            behavior: "smooth",
            block: "nearest",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Pathway title",
        text: "You can open a pathway and browse its contents by clicking on the title.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="pathway-title-DefineOwnersWhy"]`,
        attachTo: {
            on: "right",
        },
        scrollTo: {
            behavior: "smooth",
            block: "nearest",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Start a pathway",
        text: "You can start a pathway by clicking the start button.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: getPathwayStateSelector(WorkflowActionState.NotStarted),
        attachTo: {
            on: "top",
        },
        scrollTo: {
            behavior: "smooth",
            block: "center",
        },
        buttons: DefaultBackNextButtons,
        showOn: () => {
            // this step will only be shown if there is a pathway to start
            const orgDiagNode = GuidedTourUtils.getFirstElementWithTextContent(getPathwayStateSelector(WorkflowActionState.NotStarted));
            return GuidedTourUtils.isElementVisible(orgDiagNode);
        },
    }, {
        title: "Locked pathways",
        text: "This is a locked pathway. You can hover over the lock icon to see why it might be locked.",
        waitForAndAttachToElementSelector: getPathwayStateSelector(WorkflowActionState.PrerequisitesNotSatisfied),
        attachTo: {
            on: "top",
        },
        scrollTo: {
            behavior: "smooth",
            block: "center",
        },
        buttons: DefaultBackNextButtons,
        showOn: () => {
            // this step will only be shown if there is a locked pathway
            const orgDiagNode = GuidedTourUtils.getFirstElementWithTextContent(getPathwayStateSelector(WorkflowActionState.PrerequisitesNotSatisfied));
            return GuidedTourUtils.isElementVisible(orgDiagNode);
        },
    }, {
        title: "Pathway filtering",
        text: "You can filter the displayed pathways by ticking or unticking the boxes.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="pathways-filter"]`,
        attachTo: {
            on: "bottom",
        },
        scrollTo: {
            behavior: "smooth",
            block: "start",
        },
        buttons: DefaultFinishButtons,
    }],
};

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { BaseDialogComponent, DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";

@Component({
    selector: "adapt-coach-access-session-dialog",
    templateUrl: "./coach-access-session-dialog.component.html",
})
export class CoachAccessSessionDialogComponent extends BaseDialogComponent<void> implements OnInit, OnDestroy {
    public readonly dialogName = "CoachAccessSessionDialog";

    public organisation?: Organisation;

    public constructor(private orgService: OrganisationService) {
        super(DialogResolveData.NotRequired);
    }

    public async ngOnInit() {
        this.organisation = await this.orgService.promiseToGetOrganisation();
    }
}

<div *ngIf="!dismissed"
     class="cta">
    <div class="cta-wrapper">
        <img class="cta-image"
             src="/content/shell/images/content_header3.png"
             alt="Happy guy" />

        <div class="cta-body">
            <div class="cta-title">
                <ng-content select="[adapt-cta-title]"></ng-content>
            </div>
            <div class="cta-content">
                <ng-content></ng-content>
            </div>
        </div>

        <div class="cta-action">
            <ng-content select="[adapt-cta-action]"></ng-content>
        </div>
    </div>

    <button adaptButton="closeBorderless"
            adaptTooltip="Dismiss"
            class="cta-dismiss"
            (click)="dismiss()"></button>
</div>

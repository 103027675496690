import { Inject, Injectable, InjectionToken, Injector, Optional, Provider } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection, RoleConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleTypeBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-type";
import { Person, PersonBreezeModel } from "@common/ADAPT.Common.Model/person/person";
import { PersonContact, PersonContactBreezeModel } from "@common/ADAPT.Common.Model/person/person-contact";
import { PersonDetailBreezeModel } from "@common/ADAPT.Common.Model/person/person-detail";
import { PersonProfileCategoryBreezeModel } from "@common/ADAPT.Common.Model/person/person-profile-category";
import { PersonProfileItem, PersonProfileItemBreezeModel } from "@common/ADAPT.Common.Model/person/person-profile-item";
import { PersonProfileItemType } from "@common/ADAPT.Common.Model/person/person-profile-item-type";
import { PersonProfileItemValue, PersonProfileItemValueBreezeModel } from "@common/ADAPT.Common.Model/person/person-profile-item-value";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { PeopleQueryUtilities } from "@common/user/people-query-utilities";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import moment from "moment";
import { forkJoin, lastValueFrom, map, of } from "rxjs";
import { CommonIntegratedArchitectureFrameworkAuthService } from "../architecture/common-integrated-architecture-framework-auth.service";
import { IntegratedArchitectureFrameworkQueryUtilities } from "../architecture/integrated-architecture-framework-query-utilities";
import { Tier1ArchitectureAuthService } from "../architecture/tier1-architecture-auth.service";
import { AfterOrganisationInitialisation, AfterOrganisationInitialisationObservable } from "../organisation/after-organisation-initialisation.decorator";
import { ConnectionQueryUtilities } from "./connection-query-utilities";
import { DirectoryAuthService } from "./directory-auth.service";
import { MY_PROFILE_ROUTE_PROVIDER, PERSON_PROFILE_ROUTE_PROVIDER } from "./person-profile-route-provider";

// TODO: doesn't seem to be an issue in .net core anymore, possibly increase or removing batching
const PersonQueryChunkSize = 20;

export const EMPLOYEE_DIRECTORY_PAGE_TOKEN = new InjectionToken("EMPLOYEE_DIRECTORY_PAGE_TOKEN");
export function provideEmployeeDirectoryPageRoute(route: IAdaptRoute<{}>): Provider {
    return {
        provide: EMPLOYEE_DIRECTORY_PAGE_TOKEN,
        useValue: route,
        multi: false,
    };
}

export const STAKEHOLDER_DIRECTORY_PAGE_TOKEN = new InjectionToken("STAKEHOLDER_DIRECTORY_PAGE_TOKEN");
export function provideStakeholderDirectoryPageRoute(route: IAdaptRoute<{}>): Provider {
    return {
        provide: STAKEHOLDER_DIRECTORY_PAGE_TOKEN,
        useValue: route,
        multi: false,
    };
}

@Injectable({
    providedIn: "root",
})
export class DirectorySharedService extends BaseOrganisationService {
    private archData = new IntegratedArchitectureFrameworkQueryUtilities(this.commonDataService);
    private peopleData = new PeopleQueryUtilities(this.commonDataService);
    private connectionData = new ConnectionQueryUtilities(this.commonDataService);

    constructor(
        injector: Injector,
        private dialogService: AdaptCommonDialogService,
        private userService: UserService,
        private authService: AuthorisationService,
        private organisationService: OrganisationService,
        @Optional() @Inject(PERSON_PROFILE_ROUTE_PROVIDER) public personProfilePageRoute?: IAdaptRoute<{ personId: number }>,
        @Optional() @Inject(MY_PROFILE_ROUTE_PROVIDER) public myProfilePageRoute?: IAdaptRoute<any>,
        @Optional() @Inject(EMPLOYEE_DIRECTORY_PAGE_TOKEN) private employeeDirectoryRoute?: IAdaptRoute<any>,
        @Optional() @Inject(STAKEHOLDER_DIRECTORY_PAGE_TOKEN) private stakeholderDirectoryPageRoute?: IAdaptRoute<any>,
    ) {
        super(injector);
    }

    public static isTeamBasedRole(role: Role) {
        return !!role.teamId;
    }

    public static isTeamBasedRoleConnection(roleConnection: RoleConnection) {
        return !!roleConnection.teamId;
    }

    public static isNotTeamBasedRole(role: Role) {
        return !DirectorySharedService.isTeamBasedRole(role);
    }

    public static isActiveTeamRoleConnection(roleConnection: RoleConnection) {
        return DirectorySharedService.isTeamBasedRoleConnection(roleConnection) && roleConnection.isActive();
    }

    public static isNotTeamBasedRoleConnection(roleConnection: RoleConnection) {
        return !DirectorySharedService.isTeamBasedRoleConnection(roleConnection);
    }

    public static isNotAccessLevelRoleConnection(roleConnection: RoleConnection) {
        return !roleConnection.role?.extensions.hasAccessPermissions();
    }

    public static isAccessLevelRoleConnection(roleConnection: RoleConnection) {
        return roleConnection.role?.extensions.hasAccessPermissions();
    }

    public static isNotAccessLevelRole(role: Role) {
        return !role?.extensions.hasAccessPermissions();
    }

    public static isAccessLevelRole(role: Role) {
        return role?.extensions.hasAccessPermissions();
    }

    protected organisationInitialisationActions() {
        return [
            this.commonDataService.getAll(RoleTypeBreezeModel),
            // Prime to reduce excessive amount of getPersonId server query. Person is used very frequently - anywhere with person-link.
            // This is making objectives page faster without n queries for n number of different people assigned to objectives from person-link.
            this.commonDataService.getActive(PersonBreezeModel),
        ];
    }


    public promiseToGetDirectoryUrl(person: Person) {
        const latestConnectionType = person.getLatestConnection()?.connectionType;
        if (latestConnectionType === ConnectionType.Employee) {
            return lastValueFrom(this.employeeDirectoryRoute!.getRoute());
        } else {
            return lastValueFrom(this.stakeholderDirectoryPageRoute!.getRoute());
        }
    }

    // People

    public async promiseToGetProfileUrl(person: Person) {
        const currentPerson = await this.userService.getCurrentPerson();
        if (person !== currentPerson) {
            const hasAccess = await this.authService.promiseToGetHasAccess(DirectoryAuthService.ReadPublicProfiles);
            if (!hasAccess || !this.personProfilePageRoute) {
                // no permission to see other peoples profiles
                return undefined;
            } else {
                // link to another persons profile
                return lastValueFrom(this.personProfilePageRoute.getRoute({ personId: person.personId }));
            }
        } else if (this.myProfilePageRoute) {
            // link to your own profile
            return lastValueFrom(this.myProfilePageRoute.getRoute());
        } else {
            return undefined;
        }
    }

    // this is mapping connection to people - need to wait for initialisation to avoid having to prime
    @AfterOrganisationInitialisation
    public promiseToGetAllPeople(forceRemote?: boolean) {
        return lastValueFrom(this.peopleData.getAllPeople(forceRemote));
    }

    public promiseToGetAllPersonDetails(forceRemote?: boolean) {
        return lastValueFrom(this.commonDataService.getAll(PersonDetailBreezeModel, forceRemote));
    }

    @AfterOrganisationInitialisation
    public promiseToGetPersonById(id: number, forceRemote?: boolean) {
        return lastValueFrom(this.commonDataService.getById(PersonBreezeModel, id, forceRemote));
    }

    @AfterOrganisationInitialisationObservable
    public primePeopleWithIds(personIds: number[]) {
        if (personIds.length > 0) {
            const chunksOfIds = ArrayUtilities.splitArrayIntoChunksOfSize(personIds, PersonQueryChunkSize);
            return forkJoin(chunksOfIds.map((ids) => this.commonDataService.getByPredicate(PersonBreezeModel, new MethodologyPredicate<Person>("personId", "in", ids)))).pipe(
                map((queryResults) => ArrayUtilities.mergeArrays(queryResults)),
            );
        } else {
            return of([] as Person[]);
        }
    }

    // Person Profile Categories

    public promiseToGetAllPersonProfileCategories(forceRemote?: boolean) {
        return lastValueFrom(this.commonDataService.getAll(PersonProfileCategoryBreezeModel, forceRemote));
    }

    public createProfileCategory(ordinal?: number) {
        return this.commonDataService.create(PersonProfileCategoryBreezeModel, {
            organisationId: this.organisationService.getOrganisationId(),
            ordinal,
        });
    }

    // Person Profile Items

    public promiseToGetAllPersonProfileItems(forceRemote?: boolean) {
        return lastValueFrom(this.commonDataService.getAll(PersonProfileItemBreezeModel, forceRemote));
    }

    public promiseToGetPersonProfileItemsById(profileItemId: number, forceRemote?: boolean) {
        return lastValueFrom(this.commonDataService.getById(PersonProfileItemBreezeModel, profileItemId, forceRemote));
    }

    public createProfileItem(profileCategoryId: number, ordinal?: number) {
        return this.commonDataService.create(PersonProfileItemBreezeModel, {
            personProfileCategoryId: profileCategoryId,
            userEditable: true,
            ordinal,
            itemType: PersonProfileItemType.RichText,
        });
    }

    public async promiseToGetAccessiblePersonProfileItems(forceRemote?: boolean) {
        const canAccessPrivate = await this.authService.promiseToGetHasAccess(DirectoryAuthService.ReadAllProfileItems);
        if (canAccessPrivate) {
            return this.promiseToGetAllPersonProfileItems(forceRemote);
        }

        const predicate = new MethodologyPredicate<PersonProfileItem>("isPrivate", "==", false);
        return lastValueFrom(this.commonDataService.getByPredicate(PersonProfileItemBreezeModel, predicate, forceRemote));
    }

    // Person Profile Item Values

    public promiseToGetAllPersonProfileItemValues(forceRemote?: boolean) {
        return lastValueFrom(this.commonDataService.getAll(PersonProfileItemValueBreezeModel, forceRemote));
    }

    public promiseToGetPersonProfileItemValuesByItemId(profileItemId: number, forceRemote?: boolean) {
        const predicate = new MethodologyPredicate<PersonProfileItemValue>("personProfileItemId", "==", profileItemId);
        return lastValueFrom(this.commonDataService.getByPredicate(PersonProfileItemValueBreezeModel, predicate, forceRemote));
    }

    // Connections/Roles

    public promiseToGetAllConnections(forceRemote?: boolean) {
        return lastValueFrom(this.commonDataService.getAll(ConnectionBreezeModel, forceRemote));
    }

    public promiseToGetConnectionsForPersonId(personId: number, forceRemote?: boolean) {
        return lastValueFrom(this.connectionData.getConnectionsForPersonId(personId, forceRemote));
    }

    public promiseToGetActiveConnectionsByPredicate(predicate?: MethodologyPredicate<Connection>, forceRemote?: boolean) {
        return lastValueFrom(this.commonDataService.getActiveByPredicate(ConnectionBreezeModel, predicate, forceRemote));
    }

    @AfterOrganisationInitialisation
    public promiseToGetAllRoles(forceRemote?: boolean) {
        return lastValueFrom(this.commonDataService.getAll(RoleBreezeModel, forceRemote));
    }

    public promiseToGetActiveRolesByPredicate(predicate?: MethodologyPredicate<Role>) {
        return lastValueFrom(this.archData.getActiveRolesByPredicate(predicate));
    }

    // Role Connections

    public promiseToGetAllRoleConnections(activeOnly?: boolean) {
        return lastValueFrom(this.archData.getAllRoleConnections(activeOnly));
    }

    public async promiseToGetRoleConnectionsForPersonId(personId: number, activeOnly = false) {
        const currentPerson = await this.userService.getCurrentPerson();
        if (currentPerson!.personId === personId) {
            // role connections for the person is already primed (from user login sequence, i.e. get connections, role connections and permissions)
            return this.getRoleConnectionsForPerson(currentPerson!, activeOnly ?? false);
        }

        return lastValueFrom(this.archData.getRoleConnectionsForPersonId(personId, activeOnly));
    }

    public getRoleConnectionsForPerson(person: Person, activeOnly: boolean) {
        const connections = person.getActiveConnections();
        return connections.reduce((acc, connection) => {
            const activeRoleConnections = activeOnly
                ? connection.roleConnections.filter((rc) => rc.isActive())
                : connection.roleConnections;
            return acc.concat(activeRoleConnections);
        }, [] as RoleConnection[]);
    }

    // Person access

    public async promiseToRemoveAccess(connection: Connection) {
        const dialogData: IConfirmationDialogData = {
            title: "Disallow login for " + connection.person.fullName + "?",
            message: "Removing the ability to log in will suspend their ability to access embedADAPT for your organisation. Are you sure?",
            confirmButtonText: "Disallow log in",
            cancelButtonText: "Cancel",
        };

        const result = await lastValueFrom(this.dialogService.openConfirmationDialogWithBoolean(dialogData));
        if (result) {
            connection.hasAccess = false;
            return lastValueFrom(this.commonDataService.saveEntities([connection]));
        }
    }

    public async promiseToEnableAccess(connection: Connection) {
        const dialogData: IConfirmationDialogData = {
            title: "Log in enabled for " + connection.person.fullName,
            message: connection.person.firstName + " can now log in and access embedADAPT for your organisation. Do you want to send an invitation email?",
            confirmButtonText: "Send invitation email",
            cancelButtonText: "Close",
        };

        connection.hasAccess = true;
        await lastValueFrom(this.commonDataService.saveEntities([connection]));
        return (await lastValueFrom(this.dialogService.openConfirmationDialogWithBoolean(dialogData))) as boolean;
    }

    // Contact Details

    public promiseToGetAllPersonContacts(forceRemote?: boolean) {
        return lastValueFrom(this.commonDataService.getAll(PersonContactBreezeModel, forceRemote));
    }

    public promiseToGetContactDetailsByPersonId(personId: number, forceRemote?: boolean) {
        const predicate = new MethodologyPredicate<PersonContact>("personId", "==", personId);

        return lastValueFrom(this.commonDataService.getByPredicate(PersonContactBreezeModel, predicate, forceRemote));
    }

    public promiseToGetContactDetailsByPersonIds(personIds: number[], forceRemote?: boolean) {
        const breezeNodeLimit = 10;
        const predicates = ArrayUtilities.splitAndProcessArrayChunks(
            personIds,
            breezeNodeLimit,
            (personIdChunk) => new MethodologyPredicate<PersonContact>("personId", "in", personIdChunk),
        );
        const queries = predicates.map((p) => this.commonDataService.getByPredicate(PersonContactBreezeModel, p, forceRemote));

        return lastValueFrom(forkJoin(queries).pipe(
            map(ArrayUtilities.mergeArrays),
        ));
    }

    // Verify Access

    public promiseToVerifyFeaturesToDisplayTeams() {
        return this.authService.promiseToGetHasAccess(Tier1ArchitectureAuthService.ReadTier1);
    }

    public promiseToVerifyFeaturesToDisplayRole() {
        return this.authService.promiseToGetHasAccess(CommonIntegratedArchitectureFrameworkAuthService.ReadTier2);
    }

    public promiseToVerifyAccessToManageAccess() {
        return this.authService.promiseToGetHasAccess("manageAccess");
    }

    public promiseToVerifyAccessToManagePositionAndRoles(person: Person) {
        return this.authService.promiseToGetHasAccess(DirectoryAuthService.ManagePositionAndRoles, person);
    }

    public async promiseToVerifyRoleRemoval(role?: Role, person?: Person) {
        if (!role) {
            return true;
        }

        const currentPerson = await this.userService.getCurrentPerson();
        if (person && currentPerson !== person) {
            return true;
        }

        const accessManagementRoles = await this.promiseToGetRolesForPersonWithPermission(currentPerson!, FeaturePermissionName.OrganisationAccessManagementConfigure);
        return accessManagementRoles.length > 1 || !accessManagementRoles.includes(role);
    }

    public promiseToVerifyPermissionRemovalFromRole(featurePermissionName: FeaturePermissionName, role?: Role) {
        if (featurePermissionName !== FeaturePermissionName.OrganisationAccessManagementConfigure) {
            return Promise.resolve(true);
        }

        return this.promiseToVerifyRoleRemoval(role, undefined);
    }

    public async promiseToGetRolesForPersonWithPermission(person: Person, featurePermissionName: FeaturePermissionName) {
        const roleConnections = await this.promiseToGetRoleConnectionsForPersonId(person.personId, true);
        return roleConnections.map((roleConnection: RoleConnection) => roleConnection.role)
            .filter((role: Role) => {
                // check role first as newly created role connection will not have any role or connection, e.g. clicking new to add role for a person
                return role && role.extensions.hasPermission(featurePermissionName);
            });
    }

    // Utilities

    public async promiseToEndConnection(connection: Connection, endDate: Date) {
        endDate = endDate || moment().toDate();
        const actualEndDate = moment(endDate)
            .utc() // when connection is reactivated, it will be start of day in utc - to be consistent here
            .startOf("day")
            .toDate();

        if (moment(connection.startDate).isAfter(actualEndDate)) {
            connection.startDate = actualEndDate;
            const modifiedRoleConnections = connection.roleConnections
                .filter((rc) => moment(rc.startDate).isAfter(actualEndDate));
            modifiedRoleConnections.forEach((rc) => rc.startDate = actualEndDate);
            // need to save role connections first before saving connection as server will be setting the endDate for them
            // and will get confused with modified role connections in the SaveMap
            await lastValueFrom(this.commonDataService.saveEntities(modifiedRoleConnections));
        }

        // remove access
        connection.hasAccess = false;
        connection.endDate = actualEndDate;

        return lastValueFrom(this.commonDataService.saveEntities(connection));
    }

    public async cleanupUncommittedRoleConnections() {
        let uncommittedRoleConnections = this.commonDataService.getUncommittedChanges(RoleConnectionBreezeModel);

        for (const roleConnection of uncommittedRoleConnections) {
            // reject without connection
            if (!roleConnection.connectionId) {
                roleConnection.entityAspect.rejectChanges();
            }
        }

        // new list after rejecting invalid role connections
        uncommittedRoleConnections = this.commonDataService.getUncommittedChanges(RoleConnectionBreezeModel);

        // search for the list of newly added role connections
        const newRoleConnections = uncommittedRoleConnections.filter((rc) => rc.entityAspect.entityState.isAdded());
        const uniqueNewRoleConnections: RoleConnection[] = [];

        for (const newRoleConnection of newRoleConnections) {
            // add to UniqueNewRoleConnections and discard duplicates
            if (uniqueNewRoleConnections.some((uniqueNewRP: RoleConnection) => uniqueNewRP.teamId === newRoleConnection.teamId
                && uniqueNewRP.roleId === newRoleConnection.roleId
                && uniqueNewRP.connectionId === newRoleConnection.connectionId)
            ) {
                newRoleConnection.entityAspect.rejectChanges();
            } else {
                uniqueNewRoleConnections.push(newRoleConnection);
            }
        }

        // new list after rejecting duplicate new
        uncommittedRoleConnections = this.commonDataService.getUncommittedChanges(RoleConnectionBreezeModel);
        for (const roleConnection of uniqueNewRoleConnections) {
            const existingRoleConnections = uncommittedRoleConnections
                .filter((checkRoleConnection: RoleConnection) => !checkRoleConnection.entityAspect.entityState.isAdded()
                    && !checkRoleConnection.entityAspect.entityState.isDeleted()
                    && !checkRoleConnection.entityAspect.entityState.isDetached())
                .filter((checkRoleConnection: RoleConnection) => checkRoleConnection.teamId === roleConnection.teamId
                    && checkRoleConnection.roleId === roleConnection.roleId
                    && checkRoleConnection.connectionId === roleConnection.connectionId);

            if (existingRoleConnections.length > 0) {
                // can be multiple existing role connections as user may be editing start/end dates of previous roles
                let overlappingRoleConnection = this.getRoleConnectionWithLatestStartDate(
                    existingRoleConnections.filter((existingRoleConnection: RoleConnection) =>
                        moment(existingRoleConnection.endDate).isSameOrAfter(roleConnection.startDate, "day")),
                );

                if (!overlappingRoleConnection) {
                    // not overlapping - get the one with latest start date where the endDate has just been set
                    overlappingRoleConnection = this.getRoleConnectionWithLatestStartDate(
                        existingRoleConnections.filter((endedRoleConnection: RoleConnection) =>
                            endedRoleConnection.endDate && endedRoleConnection.entityAspect.originalValues.endDate === null),
                    );
                }

                if (overlappingRoleConnection) {
                    overlappingRoleConnection.endDate = roleConnection.endDate;

                    if (overlappingRoleConnection.endDate && overlappingRoleConnection.endDate < overlappingRoleConnection.startDate) {
                        // invalid endDate -> set it to null
                        // @ts-ignore allow setting this to null
                        overlappingRoleConnection.endDate = null;
                    }

                    // throw away the newly added role connection
                    roleConnection.entityAspect.rejectChanges();
                }
            }
        }

        // new list after merging
        return this.commonDataService.getUncommittedChanges(RoleConnectionBreezeModel);
    }

    private getRoleConnectionWithLatestStartDate(roleConnections: RoleConnection[]) {
        let result: RoleConnection | null = null;
        for (const rp of roleConnections) {
            if (result === null || result.startDate < rp.startDate) {
                result = rp;
            }
        }
        return result;
    }
}

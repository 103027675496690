<div class="d-grid"
     data-tour="pathways">
    <div class="d-flex flex-column flex-md-row row-gap-2 mw-100 overflow-auto">
        <div class="outcome-column p-3 d-none d-md-flex">
            <h2 class="mb-0">Outcomes</h2>
        </div>
        <div class="d-flex workflows p-3">
            <h2 class="me-auto mb-0">Pathways</h2>
            <adapt-workflow-map-status-filter adapt-dashboard-title-buttons
                                              data-tour="pathways-filter"
                                              [(hiddenStatuses)]="hiddenStatuses"
                                              (hiddenStatusesChange)="updateData()"></adapt-workflow-map-status-filter>
        </div>
    </div>
    <div *ngFor="let category of categoriesWorkflows">
        <div *ngIf="category && category.items.length > 0"
             [attr.data-tour]="category.key"
             class="d-flex flex-column flex-md-row row-gap-2 mw-100 overflow-auto">
            <div class="outcome-column justify-content-center">
                <adapt-display-workflow-category [category]="category.key!"></adapt-display-workflow-category>
            </div>
            <div class="workflows">
                <adapt-display-workflow-card *ngFor="let workflow of category.items"
                                             [workflow]="workflow"
                                             [attr.data-tour]="workflow.workflowId"></adapt-display-workflow-card>
            </div>
        </div>
    </div>
</div>

import { Component, Inject, Injector } from "@angular/core";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { EMPTY, forkJoin, lastValueFrom } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";

@Component({
    selector: "adapt-merge-labels-dialog",
    templateUrl: "./merge-labels-dialog.component.html",
    styleUrls: ["./merge-labels-dialog.component.scss"],
})
export class MergeLabelsDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Label> {
    public readonly dialogName = "MergeLabels";

    public selectedDestination?: Label;
    public sourceItemCount = 0;
    public sourceKeyFunctionCount = 0;
    public sourceTeamCount = 0;
    public sourceObjectiveCount = 0;
    public sourceSystemCount = 0;
    public sourceProcessStepCount = 0;
    public sourceRoleCount = 0;
    public confirmMerge?: boolean | null;

    private labelsUsage: { [labelId: number]: LabelLocation[] } = {};
    private changedLabelLocations: LabelLocation[] = [];
    private deletedLabels: Label[] = [];

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) public sourceLabels: Label[],
        protected commonDataService: CommonDataService,
        labellingService: LabellingService,
    ) {
        super(injector);

        forkJoin(
            sourceLabels.map((label) => labellingService.getLabelLocationsForLabel(label.labelId).pipe(
                tap((labelLocations) => this.labelsUsage[label.labelId] = labelLocations),
            )),
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public get entitiesToConfirm() {
        return [...this.changedLabelLocations, ...this.deletedLabels];
    }

    public async onSelectedLabelChanged(selectedLabel: Label) {
        this.selectedDestination = selectedLabel;
        this.autoResolveData = selectedLabel;

        // will need to confirm merge again after selection changed
        this.confirmMerge = false;

        // cancel previously deleted labels
        await lastValueFrom(this.commonDataService.rejectChanges(this.deletedLabels));
        this.deletedLabels = [];

        // undo previous changed label locations if selection changed
        if (this.changedLabelLocations.length > 0) {
            await lastValueFrom(this.commonDataService.rejectChanges(this.changedLabelLocations));
            this.changedLabelLocations = [];
        }

        // update counts to be shown in impact statement
        const mergeLabels = this.sourceLabels.filter((i) => i.labelId !== selectedLabel.labelId);
        const affectedLabelLocations: LabelLocation[] = [];
        // can't use forEach here or it won't wait for async remove to finish
        for (const label of mergeLabels) {
            const labelLocations = this.labelsUsage[label.labelId];
            affectedLabelLocations.push(...labelLocations);

            // Only change label id if there is no already a label location with the same pair of value!
            for (const labelLocation of labelLocations) {
                if (this.hasDuplicateLocationInDestination(labelLocation)) {
                    await lastValueFrom(this.commonDataService.remove(labelLocation));
                } else {
                    labelLocation.labelId = selectedLabel.labelId;
                }
            }
            this.changedLabelLocations = this.changedLabelLocations.concat(labelLocations);
        }

        this.sourceItemCount = this.countDistinct(affectedLabelLocations.filter((l) => !!l.itemId).map((l) => l.itemId!));
        this.sourceKeyFunctionCount = this.countDistinct(affectedLabelLocations.filter((l) => !!l.keyFunctionId).map((l) => l.keyFunctionId!));
        this.sourceTeamCount = this.countDistinct(affectedLabelLocations.filter((l) => !!l.teamId).map((l) => l.teamId!));
        this.sourceObjectiveCount = this.countDistinct(affectedLabelLocations.filter((l) => !!l.objectiveId).map((l) => l.objectiveId!));
        this.sourceSystemCount = this.countDistinct(affectedLabelLocations.filter((l) => !!l.systemId).map((l) => l.systemId!));
        this.sourceProcessStepCount = this.countDistinct(affectedLabelLocations.filter((l) => !!l.processStepId).map((l) => l.processStepId!));
        this.sourceRoleCount = this.countDistinct(affectedLabelLocations.filter((l) => !!l.roleId).map((l) => l.roleId!));

        forkJoin(mergeLabels.map((i) => this.commonDataService.remove(i))).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.deletedLabels = mergeLabels);
    }

    private countDistinct(idArrays: number[]) {
        return ArrayUtilities.distinct(idArrays).length;
    }

    private hasDuplicateLocationInDestination(labelLocation: LabelLocation) {
        if (this.selectedDestination) {
            const destinationLocations = this.labelsUsage[this.selectedDestination.labelId];
            return !!destinationLocations.find((l) =>
                l.objectiveId === labelLocation.objectiveId &&
                l.itemId === labelLocation.itemId &&
                l.keyFunctionId === labelLocation.keyFunctionId &&
                l.teamId === labelLocation.teamId &&
                l.systemId === labelLocation.systemId &&
                l.processStepId === labelLocation.processStepId &&
                l.roleId === labelLocation.roleId);
        }

        return false;
    }

    @Autobind
    public saveAndClose() {
        return this.commonDataService.saveEntities(this.changedLabelLocations).pipe(
            switchMap(() => this.commonDataService.saveEntities(this.deletedLabels)),
            tap(() => this.resolve(this.autoResolveData!)),
            catchError((e) => {
                console.log(ErrorHandlingUtilities.getHttpResponseMessage(e));
                return EMPTY;
            }),
        );

    }
}

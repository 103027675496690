import { Component, Inject } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseConfirmationDialogComponent } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/base-confirmation-dialog.component";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { KanbanService } from "../kanban.service";

export interface IConfirmDeleteBoardData extends IConfirmationDialogData {
    board: Board;
}

@Component({
    selector: "adapt-delete-board-dialog",
    templateUrl: "./delete-board-dialog.component.html",
    styleUrls: ["./delete-board-dialog.component.scss"],
})
export class DeleteBoardDialogComponent extends BaseConfirmationDialogComponent {
    public readonly dialogName = "DeleteBoardConfirmation";
    public readonly ViewTeamKanban = CommonTeamsAuthService.ViewTeamKanban;

    public board: Board;
    public team: Team;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IConfirmDeleteBoardData,
        kanbanService: KanbanService,
    ) {
        super(dialogData);

        dialogData.title = "Delete Board: " + dialogData.board.name;
        dialogData.confirmButtonText = "Delete & save";

        this.board = dialogData.board;
        this.team = dialogData.board.team;
        // prime board items
        kanbanService.getItemsByBoardId(this.board.boardId).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }
}

import { Injectable } from "@angular/core";
import { BrowserAuthErrorCodes } from "@azure/msal-browser";
import { EventSeriesType } from "@common/ADAPT.Common.Model/organisation/event-series";
import { IMeetingLocation, Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { Logger } from "@common/lib/logger/logger";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { GraphError } from "@microsoft/microsoft-graph-client";
import { Attendee, AttendeeBase, DayOfWeek, EmailAddress, Event, LocationConstraint, MeetingTimeSuggestionsResult, NullableOption, RecurrencePatternType, Room, ScheduleInformation, TimeConstraint, WeekIndex } from "@microsoft/microsoft-graph-types-beta";
import moment from "moment";
import { MicrosoftAuthService, MicrosoftGraphClientError } from "../oauth/microsoft-oauth/microsoft-auth.service";
import { IScheduleMeeting } from "./calendar.interface";

@Injectable({
    providedIn: "root",
})
export class MicrosoftCalendarService {
    private log = Logger.getLogger(MicrosoftCalendarService.name);

    constructor(
        private authService: MicrosoftAuthService,
    ) {
    }

    // Get list of meeting rooms
    public async getMeetingRooms() {
        if (!this.authService.graphClient) {
            throw new MicrosoftGraphClientError("Graph client is not initialized");
        }

        try {
            const result = await this.authService.graphClient
                .api("/places/microsoft.graph.room")
                .get();
            this.log.debug("Got meeting rooms", result);
            return result.value as Room[];
        } catch (error) {
            // don't log the monitor_window_timeout error that occurs when auth is stale
            const isAuthTimeoutError = error instanceof GraphError
                && error.code === "BrowserAuthError"
                && error.body.includes(BrowserAuthErrorCodes.monitorWindowTimeout);
            if (!isAuthTimeoutError) {
                this.log.error("Could not get rooms", error);
            }
        }

        return undefined;
    }

    // Get calendar availability for the meeting attendants and locations at the meeting's time.
    public async getCalendarAvailability(meeting: Meeting, locations: IMeetingLocation[]) {
        if (!this.authService.graphClient) {
            throw new MicrosoftGraphClientError("Graph client is not initialized");
        }

        const attendees = meeting.meetingAttendees
            .map((attendee) => attendee.attendee.getLoginEmail()?.value)
            .filter((address) => !!address);

        const schedules = ArrayUtilities.distinct([
            ...locations.map((l) => l.emailAddress)
                .filter((l) => !!l),
            ...attendees,
        ]);

        const body = {
            schedules,
            startTime: {
                dateTime: moment(meeting.meetingDateTime).toISOString(),
                timeZone: "UTC",
            },
            endTime: {
                dateTime: moment(meeting.endTime).toISOString(),
                timeZone: "UTC",
            },
            availabilityViewInterval: 15,
        };

        if (body.schedules.length === 0) {
            return [] as ScheduleInformation[];
        }

        const result = await this.authService.graphClient
            .api("/me/calendar/getSchedule")
            .post(body);
        this.log.debug("Got calendar availability", { body, result });
        return result.value as ScheduleInformation[];
    }

    public isLocationAvailable(locationSchedule: ScheduleInformation, meeting: Meeting) {
        return this.isScheduleAvailable(locationSchedule, meeting, false)
            // a 2 in the availability view means the location is busy for that slice
            || !locationSchedule.availabilityView?.includes("2");
    }

    public isScheduleAvailable(schedule: ScheduleInformation, meeting: Meeting, isPerson = true) {
        return !schedule.scheduleItems
            || this.checkAvailabilityForExistingMeeting(schedule, meeting)
            // exclude workingElsewhere etc. from triggering not available
            || (isPerson && schedule.scheduleItems.every((item) => item.status !== "busy"));
    }

    private checkAvailabilityForExistingMeeting(schedule: ScheduleInformation, meeting: Meeting) {
        if (schedule.availabilityView?.split("").every((slot) => slot === "0")) {
            // all slots in the availability view are 0, so they are completely free at this time, no need for more checks
            return true;
        }

        // exclude events that end at the same time as our meeting starts
        const scheduleItems = schedule.scheduleItems?.filter((item) => {
            // remove free/WFH events from consideration
            return item.status !== "free"
                && item.status !== "workingElsewhere"
                && !moment.utc(item.end?.dateTime).isSame(meeting.meetingDateTime, "minute");
        }) ?? [];

        // no events that conflict
        if (scheduleItems.length === 0) {
            return true;
        }

        // find an event with the same name
        const matchingScheduleItem = scheduleItems.find((item) => {
            if (!item.subject) {
                return false;
            }

            if (meeting.name && (item.subject === meeting.name || item.subject.includes(meeting.name))) {
                return true;
            }

            // current name didn't match, check the original meeting name
            const originalName = meeting.entityAspect.originalValues?.name;
            return originalName && (item.subject === originalName || item.subject.includes(originalName));
        });

        // available if there is a single item that matches our meeting
        return scheduleItems.length === 1 && !!matchingScheduleItem;
    }

    // We aren't using this at the moment, but we will need it in the future when we allow time suggestions
    // TODO: calling code needs to account for all emptySuggestionsReason,
    //       see https://learn.microsoft.com/en-us/graph/api/resources/meetingtimesuggestionsresult?view=graph-rest-1.0
    public async getMeetingTimeSuggestions(meeting: Meeting, location?: EmailAddress) {
        if (!this.authService.graphClient) {
            throw new MicrosoftGraphClientError("Graph client is not initialized");
        }

        const body = {
            attendees: [
                ...meeting.meetingAttendees.map((attendee) => ({
                    emailAddress: {
                        address: attendee.attendee.getLoginEmail()?.value,
                        name: attendee.attendee.fullName,
                    },
                    type: "required",
                } as AttendeeBase)),
            ],
            timeConstraint: {
                timeslots: [
                    {
                        start: {
                            dateTime: moment(meeting.meetingDateTime).toISOString(),
                            timeZone: "UTC",
                        },
                        end: {
                            dateTime: moment(meeting.endTime).toISOString(),
                            timeZone: "UTC",
                        },
                    },
                ],
            } as TimeConstraint,
            locationConstraint: location
                ? {
                    isRequired: true,
                    suggestLocation: false,
                    locations: [{ displayName: location.name, locationEmailAddress: location.address }],
                } as LocationConstraint
                : null,
            meetingDuration: moment.duration(moment(meeting.endTime).diff(meeting.meetingDateTime)).toISOString(),
            returnSuggestionReasons: true,
        };

        const result = await this.authService.graphClient
            .api("/me/findMeetingTimes")
            .post(body);
        this.log.debug("Got meeting time suggestions", { body, result });
        return result as MeetingTimeSuggestionsResult;
    }

    // Get the meeting represented by the given uniqueId
    public async getMeeting(uid: string) {
        if (!this.authService.graphClient) {
            throw new MicrosoftGraphClientError("Graph client is not initialized");
        }

        const result = await this.authService.graphClient
            .api(`/me/events`)
            .filter(`uid eq '${uid}'`)
            .top(1)
            .get();
        this.log.debug("Got event", { uid, result });
        return ArrayUtilities.getSingleFromArray(result.value as Event[]);
    }

    // Get the meeting represented by the given uniqueId
    public async getMeetingByEventId(eventId: string) {
        if (!this.authService.graphClient) {
            throw new MicrosoftGraphClientError("Graph client is not initialized");
        }

        const result = await this.authService.graphClient
            .api(`/me/events/${eventId}`)
            .get();
        this.log.debug("Got event", { eventId, result });
        return result as Event;
    }

    // Get the event instances represented by the given eventId
    public async getEventInstances(eventId: string, startDateTime: Date, endDateTime: Date) {
        if (!this.authService.graphClient) {
            throw new MicrosoftGraphClientError("Graph client is not initialized");
        }

        const result = await this.authService.graphClient
            .api(`/me/events/${eventId}/instances`)
            .query({
                startDateTime: startDateTime.toISOString(),
                endDateTime: endDateTime.toISOString(),
            })
            .top(50) // only returns 10 events otherwise
            .get();
        this.log.debug("Got event instances", { eventId, result });
        return result.value as Event[];
    }

    // Schedule a meeting
    public async scheduleMeeting(options: IScheduleMeeting) {
        if (!this.authService.graphClient) {
            throw new MicrosoftGraphClientError("Graph client is not initialized");
        }

        const event = this.createEventBody(options);
        const result = await this.authService.graphClient
            .api("/me/events")
            .post(event);
        this.log.debug("Created event", { event, result });
        return result as Event;
    }

    // Update the meeting represented by the given eventId
    public async updateMeeting(eventId: string, options: IScheduleMeeting) {
        if (!this.authService.graphClient) {
            throw new MicrosoftGraphClientError("Graph client is not initialized");
        }

        const event = this.createEventBody(options);
        const result = await this.authService.graphClient
            .api(`/me/events/${eventId}`)
            .patch(event);
        this.log.debug("Updated event", { eventId, event, result });
        return result as Event;
    }

    // Delete the meeting represented by the given eventId
    public async deleteMeeting(eventId: string) {
        if (!this.authService.graphClient) {
            throw new MicrosoftGraphClientError("Graph client is not initialized");
        }

        const result = await this.authService.graphClient
            .api(`/me/events/${eventId}`)
            .delete();
        this.log.debug("Deleted event", { eventId, result });
    }

    // generate a full Event body from an IScheduleMeeting object
    private createEventBody(options: Partial<IScheduleMeeting>) {
        const event: Event = {
            allowNewTimeProposals: false,
            attendees: [],
            body: {
                contentType: "html",
                content: options.body ?? null,
            },
            location: null,
            locations: [],
            // if we request responses for a recurrence, the room may deny the request
            // as you can only book a recurrence within 180 days
            responseRequested: !options.recurrence,
        };

        if (options.name) {
            event.subject = options.name;
        }

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (options.startTime) {
            event.start = {
                // need to strip the offset else it'll just be UTC
                dateTime: moment(options.startTime).toISOString(true).substring(0, 23),
                timeZone: timezone,
            };
        }

        if (options.endTime) {
            event.end = {
                // need to strip the offset else it'll just be UTC
                dateTime: moment(options.endTime).toISOString(true).substring(0, 23),
                timeZone: timezone,
            };
        }

        if (options.recurrence && options.recurrence.type !== EventSeriesType.Once) {
            // convert from e.g. "RelativeMonthly" to "relativeMonthly"
            const type = (options.recurrence.type.charAt(0).toLowerCase() + options.recurrence.type.slice(1)) as RecurrencePatternType;

            event.recurrence = {
                pattern: {
                    type,
                    interval: options.recurrence.interval,
                    month: options.recurrence.month ?? 0,
                    // convert from e.g. "Saturday" to "saturday"
                    daysOfWeek: options.recurrence.daysOfWeek ? [options.recurrence.daysOfWeek.toLowerCase() as DayOfWeek] : null,
                    // convert from e.g. "First" to "first"
                    index: options.recurrence.index?.toLowerCase() as NullableOption<WeekIndex>,
                },
                range: {
                    type: "endDate",
                    startDate: moment(options.recurrence.startDate).format("YYYY-MM-DD"),
                    endDate: moment(options.recurrence.endDate).format("YYYY-MM-DD"),
                },
            };
        }

        if (options.attendees) {
            event.attendees!.push(...options.attendees.map((attendee) => ({
                type: attendee.type,
                emailAddress: {
                    address: attendee.address,
                    name: attendee.name,
                } as EmailAddress,
            } as Attendee)));
        }

        if (options.location) {
            event.location = {
                locationEmailAddress: options.location.emailAddress,
                locationUri: options.location.emailAddress,
                locationType: "conferenceRoom",
                displayName: options.location.name,
                uniqueId: options.location.id,
            };
            event.locations = [event.location];

            // add room as attendee
            event.attendees!.push({
                type: "resource",
                emailAddress: {
                    address: options.location.emailAddress,
                    name: options.location.name,
                },
            });
        }

        if (options.createOnlineMeeting) {
            event.isOnlineMeeting = true;
            event.onlineMeetingProvider = "teamsForBusiness";
        }

        return event;
    }
}

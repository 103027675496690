export enum InvoiceType {
    Platform = "Platform",
    CoachScopingSession = "CoachScopingSession",
    CoachCustomSession = "CoachCustomSession",
}

export class InvoiceTypeMetadata {
    public static readonly Platform = new InvoiceTypeMetadata(InvoiceType.Platform, "Platform subscription");
    public static readonly CoachScopingSession = new InvoiceTypeMetadata(InvoiceType.CoachScopingSession, "Scoping session");
    public static readonly CoachCustomSession = new InvoiceTypeMetadata(InvoiceType.CoachCustomSession, "In-depth coach session");

    public static readonly ByType: { [status in InvoiceType]: InvoiceTypeMetadata } = {
        [InvoiceType.Platform]: InvoiceTypeMetadata.Platform,
        [InvoiceType.CoachScopingSession]: InvoiceTypeMetadata.CoachScopingSession,
        [InvoiceType.CoachCustomSession]: InvoiceTypeMetadata.CoachCustomSession,
    };

    public static readonly ByIntValue: { [value: number]: InvoiceTypeMetadata } = {
        0: InvoiceTypeMetadata.Platform,
        1: InvoiceTypeMetadata.CoachScopingSession,
        2: InvoiceTypeMetadata.CoachCustomSession,
    };

    private constructor(
        public readonly type: InvoiceType,
        public readonly name: string,
    ) { }
}

import { Component, Inject, Injector, OnInit } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { WorkflowStatusEnum } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { PersonFlag } from "@common/ADAPT.Common.Model/person/person-flag.enum";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { DefaultBackFinishButtons, DefaultFinishButtons, DefaultNextButtons, DefaultProceedButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { PERSONAL_CONFIGURATION_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { KanbanAuthService } from "@org-common/lib/kanban/kanban-auth.service";
import { ObjectivesAuthService } from "@org-common/lib/objectives/objectives-auth.service";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { PersonFlagService } from "@org-common/lib/person/person-flag.service";
import { OrganisationDiagnosticAuthService } from "@org-common/lib/survey/organisation-diagnostic/organisation-diagnostic-auth.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { StartOnboardingSearchParam, WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { finalize, forkJoin, lastValueFrom, merge, Observable, startWith, switchMap } from "rxjs";
import { debounceTime, filter, tap } from "rxjs/operators";
import { AltoShellTour, InviteLeadershipTeamStep, PathwayStep, UnderstandJourneyStep } from "../../tours/alto-shell-tour";
import { onboardingWorkflow } from "../../workflows/onboarding/onboarding-workflow";
import { Workflows } from "../../workflows/workflows";

@Component({
    selector: "adapt-personal-dashboard-page",
    templateUrl: "./personal-dashboard-page.component.html",
    styleUrls: ["./personal-dashboard-page.component.scss"],
})
export class PersonalDashboardPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly EditOrganisationDiagnostic = OrganisationDiagnosticAuthService.EditOrganisationDiagnostic;
    public readonly CanReadMeetings = CommonTeamsAuthService.ViewAnyTeamMeeting;
    public readonly ReadObjectives = ObjectivesAuthService.ReadAnyObjectives;
    public readonly CanReadStewardship = KanbanAuthService.ViewAnyBoard;
    public readonly CanEditPersonalStewardship = KanbanAuthService.EditPersonalStewardshipKanban;

    public personalConfigLink$: Observable<string>;
    public person$ = this.userService.currentPerson$;
    public team?: Team;

    public emailConfirmed = false;
    public tourDismissed = false;

    public firstSubscriptionPaymentReceived$: Observable<boolean>;
    private onboardingFinished = false;

    constructor(
        private userService: UserService,
        private workflowService: WorkflowService,
        private responsiveService: ResponsiveService,
        private dialogService: AdaptCommonDialogService,
        private accountService: AccountService,
        personFlagService: PersonFlagService,
        injector: Injector,
        @Inject(PERSONAL_CONFIGURATION_PAGE) personalConfigurationPageRoute: IAdaptRoute<{}>,
    ) {
        super(injector);

        this.personalConfigLink$ = personalConfigurationPageRoute.getRoute();

        this.person$.pipe(
            tap((person) => this.emailConfirmed = !!person?.methodologyUser?.emailConfirmed),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.person$.pipe(
            filter((person) => !!person),
            switchMap((person) => personFlagService.isFlagEnabled$(person!.personId, PersonFlag.AltoShellTourDismissed)),
            this.takeUntilDestroyed(),
        ).subscribe((tourDismissed) => this.tourDismissed = tourDismissed);

        this.firstSubscriptionPaymentReceived$ = accountService.firstSubscriptionPaymentReceived$;
    }

    public async ngOnInit() {
        // accountStatusChanged$ will emit if subscription changed - want to continue onboarding after signing up
        merge(this.navigationEnd, this.accountService.accountStatusChanged$).pipe(
            startWith(undefined),
            switchMap(() => this.workflowService.continueWorkflowFromSearchParam()),
            switchMap(() => this.accountService.getAccount()),
            this.takeUntilDestroyed(),
        ).subscribe(async (account) => {
            // inactive account shouldn't have workflow permission
            // workflowService.executeJourney will cause 403 on SaveChanges when it runs otherwise
            if (!account?.extensions.isZeroDayTrial && !account?.extensions.isInactive) {
                const workflowStatus = await lastValueFrom(this.workflowService.getLatestStatusForWorkflow(onboardingWorkflow));
                const runOnboarding = this.getSearchParameterBoolValue(StartOnboardingSearchParam);
                if (runOnboarding || workflowStatus && workflowStatus.status === WorkflowStatusEnum.Incomplete) {
                    this.runOnboardingWorkflow();
                }
            }
        });

        this.searchParameterChanged.pipe(
            startWith({}),
            debounceTime(1000),
            // only act when no search params, and the onboarding has run in this session
            filter((params) => Object.keys(params).length === 0 && this.onboardingFinished),
            // reset onboarding status so we don't run it again when the parameters change
            tap(() => this.onboardingFinished = false),
            switchMap(() => forkJoin([
                this.workflowService.hasWorkflowCompletedBeforeByCurrentPerson(Workflows[WorkflowIdentifier.InviteBusinessLeaders]),
                this.workflowService.hasWorkflowCompletedBeforeByCurrentPerson(Workflows[WorkflowIdentifier.UnderstandJourney]),
            ])),
            this.takeUntilDestroyed(),
        ).subscribe(([inviteLeadersComplete, understandJourneyComplete]) => {
            const pathwayTour: GuidedTour = {
                steps: [PathwayStep],
            };
            if (!this.guidedTourService.tourHasRun(pathwayTour)) {
                // add steps for invite people/understand journey
                if (!inviteLeadersComplete) {
                    pathwayTour.steps.push({
                        ...InviteLeadershipTeamStep,
                        buttons: !understandJourneyComplete
                            ? DefaultNextButtons
                            : DefaultFinishButtons,
                    });
                }

                if (!understandJourneyComplete) {
                    pathwayTour.steps.push({
                        ...UnderstandJourneyStep,
                        buttons: !inviteLeadersComplete
                            ? DefaultBackFinishButtons
                            : DefaultFinishButtons,
                    });
                }

                // extra steps were added, we should update the first step to use "Next" button instead of "OK"
                if (pathwayTour.steps.length > 1) {
                    const step = pathwayTour.steps[0];
                    pathwayTour.steps[0] = { ...step, buttons: DefaultProceedButtons };
                }

                // no point trying to run this on mobile
                if (!this.responsiveService.currentBreakpoint.isMobileSize) {
                    this.guidedTourService.run(pathwayTour);
                } else {
                    const message = `
                        <p>The ${AdaptClientConfiguration.AdaptProjectLabel} platform content is quite information dense, and may be hard to read or use on a mobile device.</p>
                        <p>For a better experience we recommend you use a device with a larger screen, such as a tablet, laptop, or desktop computer.</p>`;
                    this.dialogService.showMessageDialog(`Thanks for using ${AdaptClientConfiguration.AdaptProjectLabel}!`, message, "OK").subscribe();
                }
            }
        });

        this.notifyActivated();
    }

    private runOnboardingWorkflow() {
        this.workflowService.executeJourney(Workflows[WorkflowIdentifier.WelcomeOnboarding], false).pipe(
            finalize(() => this.onboardingFinished = true),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public runTour() {
        this.guidedTourService.run(AltoShellTour);
    }
}

import { Component, Inject, Injector, ViewChild } from "@angular/core";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { DxTextBoxComponent } from "devextreme-angular";
import { timer } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";
import { MeetingsService } from "../meetings.service";

export interface IEditAgendaItemDialogData {
    agendaItem: MeetingAgendaItem;
    saveOnClose: boolean;
}

export interface IInputDataProperties {
    agendaItemName: string;
    plannedDuration?: number;
    description?: string;
}

@Component({
    selector: "adapt-edit-agenda-item-dialog",
    templateUrl: "./edit-agenda-item-dialog.component.html",
})
export class EditAgendaItemDialogComponent extends BaseDialogWithDiscardConfirmationComponent<MeetingAgendaItem> {
    public readonly dialogName = "EditAgendaItemDialog";

    @ViewChild("agendaItemName") public itemNameComponent!: DxTextBoxComponent;

    private inputDataProperties?: IInputDataProperties;
    public isEditorValid = true;

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) public data: IEditAgendaItemDialogData,
        private meetingsService: MeetingsService,
        rxjsBreezeService: RxjsBreezeService,
        dialogService: AdaptCommonDialogService,
    ) {
        super(injector);
        this.autoResolveData = data.agendaItem;
        if (!data.saveOnClose) {
            this.inputDataProperties = {
                agendaItemName: data.agendaItem.name,
                plannedDuration: data.agendaItem.plannedDurationInMinutes,
            };
        }

        rxjsBreezeService.entityTypeDetached(MeetingAgendaItem).pipe(
            filter((item) => item.meetingAgendaItemId === data.agendaItem.meetingAgendaItemId),
            switchMap(() => dialogService.showMessageDialog(
                "Agenda item deleted",
                "This meeting agenda item has been deleted remotely. The agenda editing dialog will now be closed.",
                "Close")),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.cancel());

        this.updateSupplementaryData();
    }

    public get entitiesToConfirm() {
        const changeCandidates: IBreezeEntity[] = [this.data.agendaItem];
        if (this.data.agendaItem.supplementaryData) {
            changeCandidates.push(this.data.agendaItem.supplementaryData);
        }

        return changeCandidates;
    }

    public get saveEnabled() {
        if (this.data.saveOnClose) {
            return this.hasUnsavedEntity;
        } else {
            return this.hasChanges;
        }
    }

    @Autobind
    public onClosed() {
        if (this.data.saveOnClose) {
            return this.saveAndClose();
        } else {
            return timer(0).pipe(
                tap(() => this.resolve(this.data.agendaItem)),
            );
        }
    }

    @Autobind
    public cancel() {
        if (this.data.saveOnClose) {
            super.cancel();
        } else {
            this.data.agendaItem.name = this.inputDataProperties?.agendaItemName ?? "";
            this.data.agendaItem.plannedDurationInMinutes = this.inputDataProperties?.plannedDuration;
            if (this.data.agendaItem.supplementaryData) {
                this.data.agendaItem.supplementaryData.itemDescription = this.inputDataProperties?.description ?? "";
            }

            super.close();
        }
    }

    private get hasChanges() {
        if (this.inputDataProperties) {
            return this.inputDataProperties.agendaItemName !== this.data.agendaItem.name ||
                this.inputDataProperties.plannedDuration !== this.data.agendaItem.plannedDurationInMinutes ||
                this.inputDataProperties.description !== this.data.agendaItem.supplementaryData?.itemDescription;
        } else {
            return false;
        }
    }

    private updateSupplementaryData() {
        if (!this.data.agendaItem.supplementaryData) {
            this.meetingsService.createAgendaItemSupplementaryData(this.data.agendaItem).pipe(
                tap((supplementaryData) => {
                    if (this.inputDataProperties) {
                        this.inputDataProperties.description = supplementaryData.itemDescription;
                    }
                }),
            ).subscribe();
        } else {
            if (this.inputDataProperties) {
                this.inputDataProperties.description = this.data.agendaItem.supplementaryData.itemDescription;
            }
        }
    }
}

import { Directive } from "@angular/core";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { BaseDialogComponent } from "../base-dialog.component/base-dialog.component";
import { ConfirmationDialogComponent, IConfirmationDialogData } from "./confirmation-dialog.component";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseConfirmationDialogComponent extends BaseDialogComponent<IConfirmationDialogData> {
    protected static readonly DEFAULT_CONFIRMATION_CHECKBOX_MESSAGE = "Check to confirm that you understand that this action cannot be undone";

    public confirmationDialogInstance = new QueuedCaller<ConfirmationDialogComponent>();

    public constructor(public dialogData: IConfirmationDialogData) {
        super();
        if (!dialogData.checkboxMessage) {
            this.dialogData.checkboxMessage = BaseConfirmationDialogComponent.DEFAULT_CONFIRMATION_CHECKBOX_MESSAGE;
        }
    }

    public setVisible(visible: boolean) { // won't be a AdaptDialog/dxPopup here
        this.confirmationDialogInstance.call((popup) => popup.setVisible(visible));
    }

    public onInitialised(confirmationDialog: ConfirmationDialogComponent) {
        this.confirmationDialogInstance.setCallee(confirmationDialog);
    }

    public onDialogClosed(accept: boolean) {
        this.dialogData.result = accept;
        this.resolveImmediate(this.dialogData);
    }
}

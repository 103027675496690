import { Component, ElementRef, Input, OnChanges, TemplateRef, ViewChild } from "@angular/core";
import { InvoiceTypeMetadata } from "@common/ADAPT.Common.Model/account/invoice-type";
import { CoachSession, CoachSessionBreezeModel } from "@common/ADAPT.Common.Model/organisation/coach-session";
import { CoachSessionStatus } from "@common/ADAPT.Common.Model/organisation/coach-session-status";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Practitioner } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { DxGridWrapperHelper } from "@common/ux/base.component/dx-component-wrapper-builder";
import { DateFormats } from "@common/ux/date-formats";
import { InitializedEvent } from "devextreme/ui/data_grid";
import { DxDataGridComponent } from "devextreme-angular";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-coach-sessions-grid",
    templateUrl: "./coach-sessions-grid.component.html",
    styleUrl: "./coach-sessions-grid.component.scss",
})
export class CoachSessionsGridComponent implements OnChanges {
    public readonly CoachSessionStatus = CoachSessionStatus;
    public readonly dateFormat = DateFormats.globalize.shortdatetime;

    @Input() public organisation!: Organisation;
    @Input() public showInternalData = false;

    @Input() public personTemplate?: TemplateRef<{$implicit: Person}>;
    @Input() public coachTemplate?: TemplateRef<{$implicit: Practitioner}>;
    @Input() public actionTemplate?: TemplateRef<{$implicit: CoachSession, updated: () => void}>;

    @ViewChild(DxDataGridComponent) public gridInstance?: DxDataGridComponent;

    public coachSessions: CoachSession[] = [];
    public errorMessage?: string;

    public dxGridWrapperHelper: DxGridWrapperHelper;

    constructor(
        private commonDataService: CommonDataService,
        elementRef: ElementRef,
    ) {
        this.dxGridWrapperHelper = new DxGridWrapperHelper("adapt-coach-sessions", jQuery(elementRef.nativeElement));
    }

    public async ngOnChanges() {
        this.dxGridWrapperHelper.saveGridState(`-${this.organisation.organisationId}`);
        // setColumnsReady first before loading so that the grid at least shows up without data and show a loading panel instead of blank panel
        this.dxGridWrapperHelper.callGrid((grid) => grid.setColumnsReady());

        await this.updateData();
    }

    @Autobind
    public async actionsUpdated() {
        await this.updateData();
    }

    public onGridInitialized(e: InitializedEvent) {
        this.dxGridWrapperHelper.initialiseGrid(e);
        e.component!.option().onToolbarPreparing = this.dxGridWrapperHelper.addResetToolbarButton;
    }

    public showColumnChooser() {
        this.dxGridWrapperHelper.callGrid((grid) => grid.component.showColumnChooser());
    }

    public resetColumns() {
        this.dxGridWrapperHelper.callGrid((grid) => grid.resetState());
    }

    public calculateTypeCellValue(coachSession: CoachSession) {
        return InvoiceTypeMetadata.ByType[coachSession.invoice.type].name;
    }

    public calculateStatusCellValue(coachSession: CoachSession) {
        return coachSession.statusMetadata.name;
    }

    public getStatusTooltip(coachSession: CoachSession) {
        switch (coachSession.status) {
            case CoachSessionStatus.Paid: {
                let msg = "Invoice paid but the coaching session has not been scheduled";
                if (!this.showInternalData) {
                    msg += ". Please contact us.";
                }
                return msg;
            }
            case CoachSessionStatus.FeedbackRequested:
                return "Feedback has been requested but no response has been recorded yet";
            default:
                return undefined;
        }
    }

    private async updateData() {
        const predicate = new MethodologyPredicate<CoachSession>("organisationId", "==", this.organisation.organisationId);
        this.coachSessions = await lastValueFrom(this.commonDataService.getByPredicate(CoachSessionBreezeModel, predicate, true));
    }
}

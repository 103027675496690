import { KanbanAuthService } from "@org-common/lib/kanban/kanban-auth.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { PersonalConfigurationPageComponent } from "./personal-configuration-page.component";

export const personalConfigurationPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-personal-configuration-page", PersonalConfigurationPageComponent)
    .atOrganisationUrl("/people/configure")
    .withTitle("My Configuration")
    .withSearchKeyword("Configure Kanban Boards")
    .withSearchKeyword("Configure Stewardship Activity")
    // all items in personal-configuration-page.component.ts require EditPersonalStewardshipKanban only
    // TODO: if more items added to personal-configuration-page.component.ts with different permission, this will need to change
    .verifyingAccess(KanbanAuthService.EditPersonalStewardshipKanban)
    .build();

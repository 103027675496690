import { Component, Inject } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent, DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { AccountService } from "@org-common/lib/organisation/account/account.service";

@Component({
    selector: "adapt-update-billing-details-dialog",
    templateUrl: "./update-billing-details-dialog.component.html",
})
export class UpdateBillingDetailsDialogComponent extends BaseDialogComponent<void> {
    public readonly dialogName = "UpdateBillingDetailsDialog";

    public constructor(
        private accountService: AccountService,
        @Inject(ADAPT_DIALOG_DATA) public account: Account | undefined) {

        super(DialogResolveData.NotRequired);
    }

    public cancel() {
        return this.accountService.rejectChanges(this.account!)
            .subscribe(() => super.cancel());

    }
    public saveAndClose() {
        return this.accountService.saveEntities(this.account)
            .subscribe(() => this.resolve());
    }
}

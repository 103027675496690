import { Injectable, Injector } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { MeetingItem } from "@common/ADAPT.Common.Model/organisation/meeting-item";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { RouteService } from "@common/route/route.service";
import { BaseService } from "@common/service/base.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { forkJoin, lastValueFrom, Observable, of, throwError } from "rxjs";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { MeetingsService } from "../meetings/meetings.service";
import { CommonTeamsService } from "../teams/common-teams.service";
import { ConfigureBoardDetailsDialogComponent } from "./configure-board-details-dialog/configure-board-details-dialog.component";
import { DeleteBoardDialogComponent, IConfirmDeleteBoardData } from "./delete-board-dialog/delete-board-dialog.component";
import { DeleteItemDialogComponent } from "./items/delete-item-dialog/delete-item-dialog.component";
import { EditItemDialogComponent } from "./items/edit-item-dialog/edit-item-dialog.component";
import { ItemUtilities } from "./items/item-utilities";
import { MoveItemDialogComponent } from "./items/move-item-dialog/move-item-dialog.component";
import { MoveItemsDialogComponent } from "./items/move-items-dialog/move-items-dialog.component";
import { ISelectItemDialogInputData, ISelectItemDialogResolveData, SelectItemDialogComponent } from "./items/select-item-dialog/select-item-dialog.component";
import { ICreateItemOptions, KanbanService } from "./kanban.service";
import { KanbanAuthService } from "./kanban-auth.service";
import { LinkMeetingAgendaItemDialogComponent } from "./link-meeting-agenda-item-dialog/link-meeting-agenda-item-dialog.component";

@Injectable({
    providedIn: "root",
})
export class KanbanUiService extends BaseService {
    public constructor(
        private dialogService: AdaptCommonDialogService,
        private routeService: RouteService,
        private meetingsService: MeetingsService,
        private teamsService: CommonTeamsService,
        private kanbanService: KanbanService,
        private authService: AuthorisationService, // TODO: remove this after moving items into kanban module
        injector: Injector,
    ) {
        super(injector);
    }

    public createBoardForTeam(team: Team) {
        return this.kanbanService.createBoardForTeam(team).pipe(
            switchMap((board) => this.openEditBoardDialog(board)),
        );
    }

    public createBoardForPerson(person: Person) {
        return this.kanbanService.createBoardForPerson(person).pipe(
            switchMap((board) => this.openEditBoardDialog(board)),
        );
    }

    public openEditBoardDialog(board: Board) {
        return this.dialogService.open(ConfigureBoardDetailsDialogComponent, board);
    }

    public openMoveItemDialog(item: Item) {
        return this.dialogService.open(MoveItemDialogComponent, item);
    }

    public openMoveItemsDialog(items: Item[]) {
        return this.dialogService.open(MoveItemsDialogComponent, items);
    }

    public openLinkMeetingAgendaItemDialog(item: Item) {
        return this.dialogService.open(LinkMeetingAgendaItemDialogComponent, item);
    }

    public archiveBoard(board: Board) {
        return this.dialogService.openConfirmationDialog({
            title: "Archive board",
            message: `<p>Archiving the <b>${board.name}</b> board will hide the actions from any board view and the board itself will be hidden.</p>
                <p>Actions from the archived board will still show while searching, and you can un-archive a board at any time.</p>`,
            confirmButtonPreset: "archiveAndSave",
        }).pipe(
            tap(() => board.isArchived = true),
            switchMap(() => this.commonDataService.saveEntities([board])),
            catchError(() => this.commonDataService.rejectChanges([board])),
        );
    }

    public deleteBoard(board: Board) {
        // this is previously in stewardship.service
        const otherBoards = board.isPersonalBoard
            ? board.person.boards
            : board.team.boards;

        const dialogData: IConfirmDeleteBoardData = { board };
        return this.dialogService.open(DeleteBoardDialogComponent, dialogData).pipe(
            filter((dialogResponse) => !!dialogResponse.result),
            switchMap(() => this.commonDataService.remove(board)),
            switchMap(() => {
                SortUtilities.updateIntegerSortedArrayAfterItemRemoval(otherBoards, "ordinal", board.ordinal);
                return this.commonDataService.saveEntities([board, ...otherBoards]);
            }),
            catchError(() => this.commonDataService.rejectChanges([board, ...otherBoards])),
        );
    }

    public deleteItem(item: Item) {
        return this.dialogService.open(DeleteItemDialogComponent, { item }).pipe(
            filter((dialogResponse) => !!dialogResponse.result),
            switchMap(() => this.commonDataService.remove(item)),
            switchMap(() => this.commonDataService.saveEntities([item])),
            tap(() => this.dialogService.closeAll()),
        );
    }

    public removeMeetingItem(meetingItem: MeetingItem) {
        // Don't need to check permission here anymore as you won't be able to get the MeetingItem if you can't edit/remove it
        let affectedItem: Item;
        return this.dialogService.openConfirmationDialog({
            title: "Remove meeting agenda action association",
            message: `<p>This action is currently associated with a meeting:</p>
                    <p class="border-start border-3 border-primary ps-2">${meetingItem.meeting.name}</p>
                    <p>In the meeting agenda item:</p>
                    <p class="border-start border-3 border-primary ps-2">${meetingItem.meetingAgendaItem.name}</p>
                    <p>Are you sure you want to remove the association?</p>`,
            confirmButtonText: "Remove & save",
            cancelButtonText: "Cancel",
        }).pipe(
            tap(() => affectedItem = meetingItem.item), // after promiseToRemove, meetingItem.item will be null -> take a reference for saving
            switchMap(() => this.commonDataService.remove(meetingItem)),
            // item entity state changed to Modified even if there is nothing changed, will have to save it as well to prevent change manager
            // from detecting it as unsaved changes
            switchMap(() => this.commonDataService.saveEntities([affectedItem, meetingItem])),
        );
    }

    public openItem(item: Item, options?: ICreateItemOptions) {
        return this.dialogService.open(EditItemDialogComponent, { item, ...(options ?? {}) });
    }

    public openItemLinkDialog(linkSingle = false) {
        const teamId = this.routeService.getRouteParamInt("teamId");
        const team$: Observable<Team | undefined> = teamId
            ? this.teamsService.getTeamById(teamId)
            : of(undefined);

        return team$.pipe(
            switchMap((team) => this.dialogService.open<ISelectItemDialogInputData, ISelectItemDialogResolveData>(
                SelectItemDialogComponent,
                { team, linking: true, linkSingle },
            )),
        );
    }

    public openAddItemLinkDialog(item: Item, saveAfterAdd = false) {
        let newItem: Item | undefined;
        const excludedItemIds = [item.itemId, ...item.links.map((i) => i.primaryItemId), ...item.links.map((i) => i.secondaryItemId)];
        return this.dialogService.open<ISelectItemDialogInputData, ISelectItemDialogResolveData>(
            SelectItemDialogComponent,
            { team: item.board?.team, linking: true, excludedItemIds: [...new Set(excludedItemIds)] },
        ).pipe(
            switchMap((result) => {
                newItem = result.isNew ? result.items[0] : undefined;
                const destItems = result.items;
                if (destItems.length > 0) {
                    return forkJoin(destItems.map((destItem) => this.kanbanService.createItemLink(item, destItem)));
                }

                return of([]);
            }),
            tap(() => {
                if (item.links?.length) {
                    const statusAndItemCodeComparator = ItemUtilities.getItemLinkStatusAndItemCodeSortComparator(item);
                    item.links.sort(statusAndItemCodeComparator);
                }
            }),
            switchMap((links) => {
                if (saveAfterAdd) {
                    return this.kanbanService.saveEntities(links).pipe(
                        map(() => links),
                    );
                } else {
                    return of(links);
                }
            }),
            switchMap(() => newItem
                ? this.promptToAssociateMeetingItem(newItem)
                : of(undefined)),
            catchError((err: AdaptError) => this.dialogService.showMessageDialog("Error saving link", err.message).pipe(
                switchMap(() => throwError(() => err)),
            )),
        );
    }

    public openItemSearchDialog(team?: Team) {
        return this.dialogService.open(SelectItemDialogComponent, { team });
    }

    public createItemOnFirstEditableBoard(boardChoices?: Board[], options?: ICreateItemOptions) {
        if (!boardChoices || !boardChoices.length) {
            return this.createAndEditItem(undefined, options);
        }

        const hasEditAccessPromises = boardChoices.map((board: Board) =>
            this.authService.promiseToGetHasAccess(KanbanAuthService.EditBoard, board));

        return forkJoin(hasEditAccessPromises).pipe(
            map((hasEditAccess: boolean[]) => {
                const firstHasAccess = hasEditAccess.indexOf(true);
                if (firstHasAccess >= 0) {
                    return boardChoices[firstHasAccess];
                } else {
                    return undefined; // if no permission on any of the team boards, you can still create action on the boards you have access to
                }
            }),
            switchMap((editableBoard) => this.createAndEditItem(editableBoard, options)),
        );
    }

    public createAndEditItem(board?: Board, options?: ICreateItemOptions) {
        return this.kanbanService.createItem(board, options?.itemOptions ?? {}).pipe(
            switchMap((item: Item) => this.openItem(item, options)),
        );
    }

    public promptToAssociateMeetingItem(item: Item) {
        if (item.board?.isTeamBoard) {
            return this.meetingsService.getFirstActiveMeetingForCurrentPerson().pipe(
                switchMap((meeting) => {
                    const currentAgendaItem = meeting?.extensions.firstInProgressItem;
                    if (currentAgendaItem) {
                        return this.dialogService.openConfirmationDialogWithBoolean({
                            title: "Link action to meeting agenda item?",
                            message: `<p>You are currently in an active meeting at agenda item:</p>
                                    <p class="border-start border-3 border-primary ps-2">${currentAgendaItem.name}</p>
                                    <p>Do you want to link the newly created action with summary:</p>
                                    <p class="border-start border-3 border-primary ps-2">${item.summary}</p>
                                    <p>to the agenda item?</p>`,
                            confirmButtonPreset: "default",
                            confirmButtonText: "Link & save",
                            cancelButtonText: "No",
                        }).pipe(
                            switchMap((confirm) => {
                                if (confirm) {
                                    return this.meetingsService.createMeetingItemForAgendaItem(
                                        currentAgendaItem,
                                        item.itemId,
                                    ).pipe(
                                        switchMap((meetingItem) => this.meetingsService.saveEntities([meetingItem])),
                                    );
                                } else {
                                    return of(undefined);
                                }
                            }),
                        );
                    }

                    return of(undefined);
                }),
            );
        } else {
            return of(undefined);
        }
    }

    public createItem(boardChoices?: Board[], options?: ICreateItemOptions) {
        return this.createItemOnFirstEditableBoard(boardChoices, options).pipe(
            switchMap((item: Item) => {
                if (!options?.skipMeetingAgendaItemLinkPrompt) {
                    return this.promptToAssociateMeetingItem(item).pipe(
                        map(() => item),
                    );
                } else {
                    return of(item);
                }
            }),
        );
    }

    public async cloneItem(item: Item) {
        if (item.entityAspect.entityState.isAddedModifiedOrDeleted() ||
            item.comments.some((c) => c.entityAspect.entityState.isModified())) {
            // emptyError when not setting defaultValue
            return lastValueFrom(
                this.dialogService.showErrorDialog("Error duplicating action", "Please save your changes before attempting to duplicate this action."),
                { defaultValue: undefined },
            );
        }

        const confirm = await lastValueFrom(this.dialogService.openConfirmationDialogWithBoolean({
            title: "Duplicate action",
            message: `<p>Are you sure you would like to duplicate this action?</p>
            <p>The action details, comments and links you are allowed to create will be copied to the new action.</p>
            <p>The edit action dialog will open for the duplicated action.</p>`,
            confirmButtonPreset: "duplicateAndEdit",
        }));

        if (confirm) {
            this.dialogService.closeAll();
            // emptyError when not setting defaultValue
            return lastValueFrom(this.kanbanService.cloneAndSaveItem(item).pipe(
                switchMap((newItem: Item) => this.openItem(newItem)),
            ), { defaultValue: undefined });
        }

        return null;
    }
}

<adapt-dialog [maxWidth]="480">
    <h3 adapt-dialog-title>Change Password for {{person.fullName}}</h3>
    <ng-container adapt-dialog-content>
        <dx-validation-group>
            <!-- Existing Password -->
            <div class="form-group row align-items-center">
                <label class="col-md-5"
                       for="inputExistingPassword">Existing Password</label>
                <div class="col-md-7">
                    <dx-text-box id="inputExistingPassword"
                                 [(value)]="oldPassword"
                                 (onValueChanged)="validateGroup()"
                                 mode="password"
                                 placeholder="Existing Password"
                                 data-test="existing-password-input">
                        <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>

            <!-- New Password -->
            <div class="form-group row align-items-center">
                <label class="col-md-5"
                       for="inputNewPassword">New Password</label>
                <div class="col-md-7">
                    <dx-text-box id="inputNewPasswor"
                                 [(value)]="newPassword"
                                 (onValueChanged)="validateGroup()"
                                 mode="password"
                                 placeholder="New Password"
                                 data-test="new-password-input">
                        <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                            <dxi-validation-rule type="stringLength"
                                                 [min]="5"
                                                 message="Password must be at least five characters"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>

            <!-- Confirm Password -->
            <div class="form-group row align-items-center">
                <label class="col-md-5"
                       for="inputConfirmPassword">Confirm Password</label>
                <div class="col-md-7">
                    <dx-text-box id="inputConfirmPassword"
                                 [(value)]="confirmPassword"
                                 (onValueChanged)="validateGroup()"
                                 mode="password"
                                 placeholder="Confirm Password"
                                 data-test="confirm-password-input">
                        <dx-validator>
                            <dxi-validation-rule type="compare"
                                                 [comparisonTarget]="passwordComparison"
                                                 message="Passwords do not match"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
        </dx-validation-group>
    </ng-container>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="saveDisabled"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

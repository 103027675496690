<adapt-context-sidebar-implementation-kit [articleId]="helpArticleId"
                                          context="page"></adapt-context-sidebar-implementation-kit>
<div adaptToolbarContent
     *ngIf="isActivated && hasGoals">
    <button *adaptIfAuthorised="EditStrategy"
            adaptButton="reorder"
            [adaptButtonToggle]="isReordering"
            class="me-2"
            (click)="reorderGoals()"
            data-tour="reorder-goals-button">Reorder goals</button>
</div>

<adapt-strategic-goals *ngIf="hasGoals; else strategicCta"
                       [isReordering]="isReordering"
                       [isEditing]="true"></adapt-strategic-goals>

<ng-template #strategicCta>
    <adapt-call-to-action leftColumnWidthClasses="col-xl-6">
        <adapt-call-to-action-title>Strategic goals</adapt-call-to-action-title>
        <br />
        <p>
            Agreeing upon your organisation's strategic goals is a key element to building a great organisation strategy as they
            give you a framework upon which to measure your progress.
        </p>

        <button adaptCallToActionButton
                *adaptIfAuthorised="EditStrategy"
                adaptButtonIcon="add"
                [adaptBlockingClick]="addGoal">Add strategic goal</button>
    </adapt-call-to-action>
</ng-template>

import { OrganisationCategoryValue } from "@common/ADAPT.Common.Model/embed/workflow";
import { AdaptProject, AdaptProjectLabel } from "@common/configuration/adapt-client-configuration";

export interface IOrganisationOutcomeDetails {
    shortName: string;
    name: string;
    longName: string;
    description: string;
    value: OrganisationCategoryValue;
    colour: string;
    icon?: string;
}

// Source of the data: https://adaptbydesign.atlassian.net/wiki/spaces/DEV/pages/2102820915/LYB+Outcomes
export const OrganisationOutcomes: { [type in OrganisationCategoryValue]: IOrganisationOutcomeDetails } = {
    Onboarding: {
        name: "Onboarding",
        shortName: "Onboarding",
        longName: `Welcome to ${AdaptProjectLabel[AdaptProject.Alto]}!`,
        description: "These pathways will guide you through your first steps on the platform.",
        value: OrganisationCategoryValue.Onboarding,
        colour: "var(--adapt-burgundy-650)",
        icon: "fal fa-fw fa-graduation-cap",
    },
    EffectiveLeadershipTeam: {
        name: "Your leadership team is effective",
        shortName: "Effective leadership team",
        longName: "You have built an effective leadership team",
        description: `You have the right people in the key leadership seats. You have a team who are committed, aligned,
            and working together cohesively to lead the business.`,
        value: OrganisationCategoryValue.EffectiveLeadershipTeam,
        colour: "var(--adapt-open-blue-500)",
        icon: "fal fa-fw fa-people-group",
    },
    Foundation: {
        name: "Your strategy has a firm foundation",
        shortName: "Firm strategy foundation",
        longName: "You have a firm foundation for your strategy",
        description: `Firm cultural foundations are the most important element of a good strategy. Your purpose and values form the foundation of your strategy for success.`,
        value: OrganisationCategoryValue.Foundation,
        colour: "var(--adapt-clementine-500)",
        icon: "fal fa-fw fa-bullseye",
    },
    BuildStrategy: {
        name: "You have a strategy for success",
        shortName: "Strategy for success",
        longName: "You have built your strategy for success",
        description: `You have a well-documented strategy that includes a compelling vision for your future,
            a realistic evaluation of your current state and insightful strategies and goals to bridge the gap.`,
        value: OrganisationCategoryValue.BuildStrategy,
        colour: "var(--adapt-clementine-500)",
        icon: "fal fa-fw fa-bullseye-arrow",
    },
    ImplementStrategy: {
        name: "Your strategy is alive",
        shortName: "Strategy is alive",
        longName: "You follow a framework to ensure you successfully implement your strategy",
        description: `You follow a framework that gives you the focus and discipline needed to effectively implement your business strategy and keep it on track.`,
        value: OrganisationCategoryValue.ImplementStrategy,
        colour: "var(--adapt-clementine-500)",
        icon: "fal fa-fw fa-monitor-waveform",
    },
    DoTheRightWork: {
        name: "You are doing the right work",
        shortName: "Doing the right work",
        longName: "You and your leadership team are doing the 'right' work",
        description: `Everyone on the leadership team knows what work needs to be done to build the business and they understand
            how this work will help leverage time and maximise the opportunity for business success.`,
        value: OrganisationCategoryValue.DoTheRightWork,
        colour: "var(--adapt-burgundy-650)",
        icon: "fal fa-fw fa-ballot-check",
    },
    FocusAccountability: {
        name: "Your leaders are focused & accountable",
        shortName: "Focused & accountable leaders",
        longName: "You have structured your business so there is focus and clear leadership accountability",
        description: `You have identified the key functions in the business and have assigned leadership accountability.
            The function leaders understand their accountabilities and have the commitment, capacity, and capability to deliver.`,
        value: OrganisationCategoryValue.FocusAccountability,
        colour: "var(--adapt-burgundy-650)",
        icon: "fal fa-fw fa-arrows-to-circle",
    },
    CriticalNumbers: {
        name: "You understand your critical numbers",
        shortName: "Critical numbers",
        longName: "You understand your business’s critical numbers",
        description: "You are focused on outcomes NOT output. You understand the numbers that matter in your business and focus on them.",
        value: OrganisationCategoryValue.CriticalNumbers,
        colour: "var(--adapt-clementine-500)",
        icon: "fal fa-fw fa-square-1",
    },
    Others: {
        name: "Others",
        shortName: "Others",
        longName: "This is not categorised",
        description: "This is reserved for pathways which are not falling into any of the above categories",
        value: OrganisationCategoryValue.Others,
        colour: "var(--adapt-burgundy-650)",
        icon: "fal fa-fw fa-computer",
    },
};

<ng-container *adaptLoading="!isInitialised">
    <div *ngIf="hasGoals; else noGoalsTemplate">
        <adapt-render-zone-map [zoneContentTemplate]="zoneContentTemplate"
                               [zoneMenuTemplate]="zoneMenuTemplate"
                               [verticalLayout]="true"
                               [fullLayoutBreakpoint]="FullLayoutBreakpoint"></adapt-render-zone-map>
    </div>
</ng-container>

<ng-template #zoneContentTemplate
             let-zone>
    <adapt-strategic-goal-zone [zone]="zone"
                               [isReordering]="isReordering"
                               [expandGoals]="expandGoals"
                               class="avoid-page-break-inside"
                               [isEditing]="isEditing && hasEditAccess"
                               (isExpandedOnItemChanged)="updateUrlWithExpandedZones(zone, $event)"></adapt-strategic-goal-zone>
</ng-template>

<ng-template #zoneMenuTemplate
             let-zone>
    <div class="d-flex align-items-center gap-1 me-1"
         data-tour="goal-button-group">
        <button *ngIf="isEditing && hasEditAccess"
                class="btn btn-blank zone-menu-button-color m-0"
                adaptTooltip="Add strategic goal"
                adaptButtonIcon="fas fa-plus"
                [adaptBlockingClick]="addGoal"
                [adaptBlockingClickParam]="zone"></button>
        <button class="zone-menu-button-color my-auto m-0"
                adaptButton="collapseBorderless"
                adaptTooltip="Collapse goals"
                (click)="detectChanges(zone)"></button>
        <button class="zone-menu-button-color my-auto m-0"
                adaptButton="expandBorderless"
                adaptTooltip="Expand goals"
                (click)="detectChanges(zone, true)"></button>
    </div>
</ng-template>

<ng-template #noGoalsTemplate>
    No strategic goals have been defined.
</ng-template>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptCoachAccessSharedModule } from "@common/coach-access/coach-access.module";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptPaymentProcessingModule } from "@common/payment-processing/payment-processing.module";
import { provideApplicationBarItemType } from "@common/shell/application-bar/application-bar.component";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { RatingStarsModule } from "@common/ux/rating-stars/rating-stars.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { DxNumberBoxModule, DxRadioGroupModule, DxTextAreaModule, DxValidatorModule } from "devextreme-angular";
import { ApplicationBarCoachAccessItem } from "./application-bar-coach-access-item/application-bar-coach-access-item";
import { ApplicationBarCoachAccessItemComponent } from "./application-bar-coach-access-item/application-bar-coach-access-item.component";
import { CoachAccessFeedbackDialogComponent } from "./coach-access-feedback-dialog/coach-access-feedback-dialog.component";
import { CoachAccessPageComponent, coachAccessPageRoute } from "./coach-access-page/coach-access-page.component";
import { CoachAccessScheduleDialogComponent } from "./coach-access-schedule-dialog/coach-access-schedule-dialog.component";
import { CoachAccessSessionDialogComponent } from "./coach-access-session-dialog/coach-access-session-dialog.component";


@NgModule({
    declarations: [
        ApplicationBarCoachAccessItemComponent,
        CoachAccessPageComponent,
        CoachAccessFeedbackDialogComponent,
        CoachAccessScheduleDialogComponent,
        CoachAccessSessionDialogComponent,
    ],
    imports: [
        CommonModule,

        AdaptButtonModule,
        AdaptCoachAccessSharedModule,
        AdaptCommonDialogModule,
        AdaptDirectorySharedModule,
        AdaptImplementationKitModule,
        AdaptLoadingSpinnerModule,
        AdaptPaymentProcessingModule,
        AdaptToolbarModule,
        AdaptTooltipModule,

        DxNumberBoxModule,
        DxRadioGroupModule,
        DxTextAreaModule,
        DxValidatorModule,
        RatingStarsModule,
    ],
    providers: [
        provideApplicationBarItemType(ApplicationBarCoachAccessItem),
    ],
})
export class AdaptCoachAccessModule {
    public static readonly Routes = [
        ...coachAccessPageRoute.routes,
    ];
}

export enum CategoryWorkflowStatus {
    ComingSoon,
    Completed,
    Blocked,
    Current,
}

export const CategoryWorkflowStatuses: { name: string, value: CategoryWorkflowStatus }[] = [
    { name: "Planned", value: CategoryWorkflowStatus.ComingSoon },
    { name: "Completed", value: CategoryWorkflowStatus.Completed },
    { name: "Locked", value: CategoryWorkflowStatus.Blocked },
];

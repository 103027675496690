<ng-template #loggedOut>
    <div *ngIf="provider && hasAssociatedProviderMeeting">
        <i class="fal fa-fw fa-calendar-days me-1"></i>This meeting has been integrated with {{provider.name}}. You can view the status by logging in.
    </div>
</ng-template>

<ng-container *ngIf="provider && authenticated; else loggedOut">
    <span *ngIf="loading; else loaded"
          class="text-muted">
        <i class="fal fa-fw fa-spin fa-spinner me-1"></i>Checking calendar integration status...
    </span>

    <ng-template #loaded>
        <div *ngIf="meetingLink">
            <i class="fal fa-fw fa-calendar-days me-1"></i>
            <a [href]="meetingLink"
               target="_blank">View meeting in {{provider.name}}</a>
        </div>

        <div *ngIf="teamsLink">
            <i class="fal fa-fw fa-camera-web me-1"></i>
            <a [href]="teamsLink"
               target="_blank">Join Teams meeting</a>
        </div>

        <div *ngIf="meeting.extensions.isNotStarted && (outOfSync || providerMeetingMissing || userCalendarMeetingMissing)">
            <ng-container *ngIf="userCalendarMeetingMissing; else userMeetingNotMissing">
                <i class="fal fa-fw fa-triangle-exclamation me-1"></i>We could not find the meeting in your calendar.
                This can happen if you deleted the event.
            </ng-container>
            <ng-template #userMeetingNotMissing>
                <i class="fal fa-fw fa-triangle-exclamation me-1"></i>This meeting
                {{outOfSync ? 'is out of sync with' : 'has not been synced to'}} {{provider.name}}.
                <button *ngIf="providerMeetingMissing || organiser.isOrganiser; else notOrganiser"
                        class="btn btn-link"
                        [adaptBlockingClick]="syncLocalMeeting">
                    Click here to {{outOfSync ? 'sync now' : 'create the ' + provider.name + ' meeting'}}.
                </button>
                <ng-template #notOrganiser>
                    <ng-container *ngIf="organiser as organiserInfo">
                        Only <b>{{organiserInfo.name}}</b> can update the calendar entry. Please ask them to sync the meeting.
                    </ng-container>
                </ng-template>
            </ng-template>
        </div>
    </ng-template>
</ng-container>

<div class="card"
     [ngClass]="{disabled: loading || isLocked}"
     [style.--category-colour]="categoryMetadata.colour">
    <div class="card-body">
        <ng-content></ng-content>
    </div>
    <div class="card-footer"
         #workflowCardAction
         [adaptTooltip]="tooltip"
         [adaptTooltipIsHtml]="true"
         [tooltipEnabled]="isLocked || state === WorkflowActionState.Completed"
         tooltipContentClasses="text-start"
         [ngClass]="{disabled: loading || isLocked}"
         [attr.data-test]="'workflow-card-action-' + workflow?.workflowId"
         (click)="onActionClicked()">
        <div>{{startWorkflowButtonText}}</div>
        <div class="card-footer-icon"><i [ngClass]="startWorkflowButtonIcon"></i></div>
    </div>
</div>

import { Component, Input, OnChanges } from "@angular/core";
import { OrganisationCategoryValue, Workflow } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { WorkflowStatus } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IGroupedData } from "@common/lib/utilities/grouped-data.interface";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { merge } from "rxjs";
import { WorkflowService } from "../workflow.service";
import { CategoryWorkflowStatus } from "./category-workflow-status.enum";

@Component({
    selector: "adapt-workflow-map-dashboard-element",
    templateUrl: "./workflow-map-dashboard-element.component.html",
    styleUrls: ["./workflow-map-dashboard-element.component.scss"],
})
export class WorkflowMapDashboardElementComponent extends BaseComponent implements OnChanges {
    public categories = Object.values(OrganisationCategoryValue)
        .filter((category: string) => category !== OrganisationCategoryValue.Others);
    public categoriesWorkflows: IGroupedData<OrganisationCategoryValue | undefined, Workflow>[] = [];

    @Input() public hiddenStatuses: CategoryWorkflowStatus[] = [];

    constructor(
        private workflowService: WorkflowService,
        rxjsBreezeService: RxjsBreezeService) {
        super();

        merge(
            rxjsBreezeService.entityTypeChanged(WorkflowConnection),
            rxjsBreezeService.entityTypeChanged(WorkflowStatus),
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateData());
    }

    public async ngOnChanges() {
        await this.updateData();
    }

    public async updateData() {
        const workflows: Workflow[] = [];

        for (const category of this.categories) {
            const categoryWorkflows = this.workflowService.getWorkflowsByCategory(category)
                .filter((i) => !i.private && !i.isStateless)
                .sort(SortUtilities.getSortByFieldFunction<Workflow>("ordinal"));

            const filteredWorkflows = await this.workflowService.filterWorkflows(categoryWorkflows, this.hiddenStatuses);
            workflows.push(...filteredWorkflows);
        }

        this.categoriesWorkflows = ArrayUtilities.groupArrayBy(workflows, (w) => w.category);
    }
}

<dx-select-box class="h-100 transparent-background"
               fieldTemplate="field"
               [dropDownOptions]="{minWidth: 110}"
               [showDropDownButton]="false"
               [items]="OrderedGoalStatuses"
               [displayExpr]="statusDisplayExpr"
               [value]="status"
               [(opened)]="selectOpen"
               (valueChange)="statusChange.emit($event)">
    <div *dxTemplate="let status of 'item'"
         class="d-flex">
        <adapt-goal-status [status]="status"></adapt-goal-status>
    </div>
    <div *dxTemplate="let status of 'field'"
         class="align-self-center d-flex mx-2 justify-content-end">
        <adapt-goal-status [showCollapsible]="true"
                           [isCollapsed]="!selectOpen"
                           [status]="status"></adapt-goal-status>
        <dx-text-box class="d-none"
                     value="hidden element required by dxTemplate"
                     [readOnly]="true"></dx-text-box>
    </div>
</dx-select-box>

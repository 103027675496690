// prod environment shared by all apps
// each app specifies its own environment.prod.ts that is based off this file

import { RawClientConfiguration } from "./adapt-client-configuration";
import { clientConfiguration as baseClientConfiguration } from "./environment.base";

export const clientConfiguration: Partial<RawClientConfiguration> = Object.assign({}, baseClientConfiguration, {
    AdaptEnvironmentName: "Production",
    AltoApiBaseUri: "https://hq-api.theadaptway.com",
    AltoAzureTablesUrl: "https://hqproductionstorage.table.core.windows.net",
    AltoBaseUri: "https://hq.theadaptway.com",
    AnalyticsProductId: "bb64b7a522bae72b9d3b6a08e6687b68553c8948",
    ApiBaseUri: "https://api.adaptbydesign.com.au",
    DeepDiveDomain: "https://adaptdeepdive.z8.web.core.windows.net",
    EmbedApiBaseUri: "https://api.adaptbydesign.com.au",
    EmbedAzureTablesUrl: "https://adapt.table.core.windows.net",
    EmbedBaseUri: "https://embed.adaptbydesign.com.au",
    GoogleClientId: "799098505879-ibljfqb9tlghbq5ni10n2b6qlsp4mjvl.apps.googleusercontent.com",
    IntercomAppId: "ujeq49vy",
    MicrosoftClientId: "cfbe3782-7692-496e-8342-fe2fcc128502",
    OneDriveClientId: "d59951b7-01a4-4618-9b3b-23df46be1aef",
    ServiceApiBaseUri: "https://service.adaptbydesign.com.au",
    StripePublicKey: "pk_live_TLSfEBi3H498WN4mNuzeRyeA",
    ToasterTraceLevelName: "Error",
    TraceLevelName: "Warning",
} as Partial<RawClientConfiguration>);

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { Goal } from "@common/ADAPT.Common.Model/organisation/goal";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { NumberUtilities } from "@common/lib/utilities/number-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxSortableEvent } from "@common/ux/dx.types";
import { Orientation } from "devextreme/common";
import { AddEvent, RemoveEvent } from "devextreme/ui/sortable";
import { debounceTime, merge, of, Subject, switchMap, tap } from "rxjs";
import { StrategicGoalItemComponent } from "../strategic-goal-item/strategic-goal-item.component";
import { StrategicGoalsService } from "../strategic-goals.service";

@Component({
    selector: "adapt-strategic-goal-zone",
    templateUrl: "./strategic-goal-zone.component.html",
    styleUrls: ["../../strategy-board/strategy-zone/strategy-zone.component.scss", "./strategic-goal-zone.component.scss"],
})
export class StrategicGoalZoneComponent extends BaseComponent implements OnInit {
    @Input() public isEditing = false;
    @Input() public zone?: Zone;
    @Input() public isReordering = false;
    @Input() public expandGoals = false;

    @Output() public isExpandedOnItemChanged = new EventEmitter<boolean>();
    public goals: Goal[] = [];

    public isDraggingGoal = false;
    public isDraggingCategorisedGoal = false;
    public categoryDragOrientation: Orientation = "vertical";
    public goalDropGroup = "Unknown";

    public hasDisplayGoals = false;

    @ViewChildren(StrategicGoalItemComponent) private goalComponentList?: QueryList<StrategicGoalItemComponent>;
    private triggerUpdateZone = new Subject<void>();

    public constructor(
        elementRef: ElementRef,
        private goalsService: StrategicGoalsService,
        private commonDataService: CommonDataService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(elementRef);

        merge(
            rxjsBreezeService.entityTypeChanged(Goal),
            this.triggerUpdateZone.asObservable(),
        ).pipe(
            debounceTime(500),
            switchMap(() => this.updateZone()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public ngOnInit(): void {
        this.triggerUpdateZone.next();

        this.goalsService.draggingGoal$.subscribe((started) => this.isDraggingGoal = started);
    }

    private updateZone() {
        if (this.zone) {
            return this.goalsService.getGoalsByZone(this.zone).pipe(
                tap((goals) => {
                    this.goals = goals;
                    this.hasDisplayGoals = goals.length > 0;
                }),
                tap(() => this.isInitialised = true),
                this.takeUntilDestroyed(),
            );
        } else {
            return of(undefined);
        }
    }

    public detectChanges(expand = false) {
        this.goalComponentList?.forEach((goalComponent) => goalComponent.expand(expand));
    }

    public reorderGoal(e: IDxSortableEvent<Goal[]>) {
        // this is called on drag end
        this.goalsService.setDraggingGoal(false);
        this.isDraggingCategorisedGoal = false;

        let hasChanges = false;
        // Checked and confirmed that fromData and toData are referring to the same array instance
        // - ordering of fromData will also also sort toData and the source collection passed to [data] of dxSortable
        // - so only need to order fromData/toData and re-index all should work
        if (e.fromData === e.toData) {
            if (e.fromIndex !== e.toIndex) {
                SortUtilities.moveItemInArray(e.fromData!, e.fromIndex!, e.toIndex!);
                hasChanges = true;
            } else if (e.event?.clientX && e.event?.clientY) {
                // this is to handle dxSortable issue where we want to drop at the bottom of another sortable,
                // the bottom is being pushed (i.e. last item has extra 48px margin-bottom) but if you drop, event is not reflecting that.
            }
        } else {
            hasChanges = true;
        }

        if (hasChanges) {
            this.reIndexGoalsOrdinal().subscribe();
        }
    }

    private reIndexGoalsOrdinal() {
        SortUtilities.sequenceNumberFieldInArray(this.goals, "ordinal");
        return this.goalsService.saveEntities(this.goals);
    }

    public onGoalDragStart(e: IDxSortableEvent<Goal[]>) {
        e.cancel = !this.isEditing;
        if (!e.cancel) {
            this.goalsService.setDraggingGoal(true);

            const draggedGoal = e.fromData![e.fromIndex!];
            this.isDraggingCategorisedGoal = !!draggedGoal?.themeId;

            if (!this.isDraggingCategorisedGoal) {
                // need to refresh sortable as the whole sortable may have been pushed down by the appearing of drop zone above it:
                // - need to do this next digest cycle as this is a callback from the same sortable
                // - without this, you cannot reorder any uncategorised goals as the displacement for the sortable are all shifted
                setTimeout(() => e.component?.update());
            }
        }
    }

    public onGoalRemove(e: RemoveEvent) {
        //pushing ordinals
        SortUtilities.updateIntegerSortedArrayAfterItemRemoval(this.goals, "ordinal", e.fromIndex);
        this.commonDataService.saveEntities(this.goals).subscribe();
    }

    public onGoalAdd(e: AddEvent) {
        const goal: Goal = e.fromData.find((a: Goal) => a.goalId === NumberUtilities.parseNumber((e.itemElement as any)[0].id)!);
        goal.zone = this.zone!;
        goal.ordinal = e.toIndex;
        this.goals.splice(e.toIndex, 0, goal);

        SortUtilities.sequenceNumberFieldInArray(this.goals, "ordinal");
        this.commonDataService.saveEntities(this.goals).subscribe();
    }

    public onIsExpandChangedOnItem() {
        const hasAnyExpandedItems = this.goalComponentList?.filter((goalComponent) => goalComponent.isExpanded);
        this.isExpandedOnItemChanged.next(hasAnyExpandedItems?.length === this.goalComponentList?.length);
    }
}

export enum PersonFlag {
    ProfileEditDismissed = "ProfileEditDismissed",
    AltoShellTourDismissed = "AltoShellTourDismissed",
    RanActiveMeetingTour = "RanActiveMeetingTour",
    RanHealthCheckPageTour = "RanHealthCheckPageTour",
    BlurPrivateActions = "BlurPrivateActions",
    UseLegacyObjectiveCreation = "UseLegacyObjectiveCreation",
}

// functions called for each flag to check if they should be default enabled
export const PersonFlagDefaults: { [k in PersonFlag]?: () => boolean } = {
    [PersonFlag.BlurPrivateActions]: () => false,
};

export enum ConfigArea {
    OrganisationProfile,
    Access,
    Api,
    Billing,
    CustomWelcomeEmail,
    ExternalLink,
    ValuesConstitution,
    CareerValuation,
    CulturalIndex,
    Tier1,
    People,
    PurposeAndVision,
    ObjectivesAndKeyResults,
    CulturalLeadership,
    Tier2,
    Kanban,
    Meetings,
    PeerCatchUp,
    Stakeholders,
    PersonalProfileCategories,
    Team,
    EmployeeEngagement,
    TeamAssessment,
    ResilientBusinessAssessment,
    ResilientBusinessGoals,
    TeamProfile,
    AccessPermissions,
    Notifications,
    Labels,
    CalendarIntegration,
    FeatureFlags,
    Bullseye,
    StrategyBoard,
    StrategicGoals,
    StrategicInputs,
    StrategicAnchors,
    PathwayCompletion,
}

import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BehaviorSubject } from "rxjs";
import { WorkflowStepComponentAdapter } from "../../workflow/workflow-step-component-adapter";
import { IScheduleMeetingWorkflowRunData } from "./schedule-meeting-workflow";

export class ScheduleMeetingWorkflowStepBaseComponent extends WorkflowStepComponentAdapter {
    public runData!: IScheduleMeetingWorkflowRunData;

    protected isValid = new BehaviorSubject<boolean>(false);
    public workflowStepCompleted = this.isValid.asObservable();

    public constructor() {
        super();
    }

    public workflowStepOnInit() {
        const runData: IScheduleMeetingWorkflowRunData = this.workflowStep?.workflow.runData;
        if (!runData || !runData.meeting) {
            throw new Error("Meeting needs to be provided to the workflow");
        }

        if (!runData.entitiesToConfirm) {
            runData.entitiesToConfirm = [];
        }

        this.runData = runData;

        this.validateEntities();
    }

    public onEntitiesChanged(entities: IBreezeEntity[]) {
        // filter out any null entities. this can happen when removing an entity (like removing an attendee)
        this.runData.entitiesToConfirm = ArrayUtilities.addElementIfNotAlreadyExists(this.runData.entitiesToConfirm, ...entities)
            .filter((e) => !!e);
        this.runData.entitiesToConfirm.forEach((ent) => this.emitEntityChange(ent));
        this.validateEntities();
    }

    public validateEntities() {
        this.isValid.next(this.runData.entitiesToConfirm.every((e) => e && e.entityAspect.validateEntity()));
    }
}

<div class="d-flex flex-column"
     *ngIf="survey && isInitialised">
    <div *ngIf="workflowCard; else notCard"
         class="d-flex flex-column gap-2">
        <ng-container *ngIf="surveyCompleted; else notCompletedCard">
            <a *ngIf="surveySummaryLink"
               [routerLink]="surveySummaryLink.path"
               [queryParams]="surveySummaryLink.queryParams">View survey summary</a>

            <adapt-progress-bar [percentageProgress]="participationRate"
                                [overrideText]="participationText"></adapt-progress-bar>

            <adapt-workflow-card-action [connection]="workflowConnection">
                <div>Survey complete, please continue by clicking below</div>
            </adapt-workflow-card-action>
        </ng-container>
        <ng-template #notCompletedCard>
            <button class="btn btn-link"
                    (click)="viewOutstandingResponses()">Manage survey
            </button>

            <adapt-progress-bar [percentageProgress]="participationRate"
                                [overrideText]="participationText"></adapt-progress-bar>

            <adapt-workflow-card-action [connection]="workflowConnection"
                                        [actionText]="surveyLink ? 'Take survey now' : undefined"
                                        [actionIcon]="surveyLink ? 'fas fa-fw fa-arrow-right' : undefined"
                                        [action]="surveyLink ? takeSurvey : undefined">
                <div>Survey will end {{survey.endTime | adaptDateTime}}</div>
            </adapt-workflow-card-action>
        </ng-template>
    </div>

    <ng-template #notCard>
        <ng-container *ngIf="surveyCompleted; else notCompleted">
            <p>The survey has been completed.
                <span *ngIf="surveySummaryLink">The result of the survey can be viewed <a [routerLink]="surveySummaryLink.path"
                       [queryParams]="surveySummaryLink.queryParams">here</a></span>.
                You are now ready to move on to next step.
            </p>
        </ng-container>
        <ng-template #notCompleted>
            <ng-container *ngIf="outstandingSurveyResponses.length > 0; else allCompleted">
                <div *ngIf="surveyLink"
                     class="py-3 mb-3 d-flex flex-column align-items-center alert alert-info">
                    <button class="btn btn-secondary"
                            adaptButtonIcon="fal fa-clipboard-list-check"
                            data-test="take-survey-now-button"
                            (click)="takeSurvey()">Take survey now
                    </button>
                </div>

                <h5>Outstanding responses from the team&nbsp;
                    <span class="fal fa-info-circle"
                          adaptTooltip="Note that {{projectLabel}} will only track survey responses which have not been submitted. Once a response is submitted, it can no longer be tracked."></span>
                </h5>
                <adapt-view-survey-outstanding-responses [ngClass]="(isMobile$ | async) === false ? 'responses' : ''"
                                                         [survey]="survey"
                                                         [showNote]="false"
                                                         [showEmail]="false"></adapt-view-survey-outstanding-responses>
            </ng-container>
            <ng-template #allCompleted>
                <p>All survey responses have been submitted.</p>
            </ng-template>
        </ng-template>
    </ng-template>
</div>

import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { CoachSession, CoachSessionBreezeModel } from "@common/ADAPT.Common.Model/organisation/coach-session";
import { CoachSessionStatus } from "@common/ADAPT.Common.Model/organisation/coach-session-status";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { PERSONAL_DASHBOARD_PAGE } from "@common/page-route-providers";
import { CoachRequestType, ICoachRequest } from "@common/payment-processing/payment-processing.service";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { UserService } from "@common/user/user.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent, DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { catchError, lastValueFrom, of } from "rxjs";
import { CALENDLY_EVENT_NAME, CalendlyEvent, formatCalendlyUrl, isCalendlyEvent } from "../calendly.interface";

export interface ICoachAccessScheduleDialogData extends ICoachRequest {
    coachSessionId: number;
}

@Component({
    selector: "adapt-coach-access-schedule-dialog",
    templateUrl: "./coach-access-schedule-dialog.component.html",
    styleUrl: "./coach-access-schedule-dialog.component.scss",
})
export class CoachAccessScheduleDialogComponent extends BaseDialogComponent<ICoachAccessScheduleDialogData, void> implements OnInit, OnDestroy {
    public readonly dialogName = "CoachAccessScheduleDialog";

    public frameUrl?: SafeResourceUrl;

    public loading = true;
    public loadingUpdater = this.createThrottledUpdater<boolean>((loading) => this.loading = loading);

    public failedToLoad = false;
    public scheduled = false;

    private coachSession?: CoachSession;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: ICoachAccessScheduleDialogData,
        @Inject(PERSONAL_DASHBOARD_PAGE) public personalDashboardPageRoute: IAdaptRoute<{}>,
        private sanitizer: DomSanitizer,
        private userService: UserService,
        private directorySharedService: DirectorySharedService,
        private commonDataService: CommonDataService,
    ) {
        super(DialogResolveData.NotRequired);
    }

    public get closeButtonDisabled() {
        return !this.scheduled && !this.failedToLoad;
    }

    public async ngOnInit() {
        window.addEventListener(CALENDLY_EVENT_NAME, this.onMessageEvent);

        const frameUrl = await this.getCalendlyFrameUrl(this.data);
        this.frameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(frameUrl);

        this.commonDataService.getById(CoachSessionBreezeModel, this.data.coachSessionId).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((coachSession) => {
            if (!coachSession) {
                throw new Error("Failed to get your coach session details.");
            }

            this.coachSession = coachSession;
        });
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        window.removeEventListener(CALENDLY_EVENT_NAME, this.onMessageEvent);
    }

    public cancel() {
        super.cancel();

        setTimeout(() => this.personalDashboardPageRoute.gotoRoute().subscribe());
    }

    @Autobind
    private async onMessageEvent(e: MessageEvent) {
        if (isCalendlyEvent(e)) {
            if (e.data.event === CalendlyEvent.EVENT_SCHEDULED) {
                await lastValueFrom(this.updateSessionStatus(CoachSessionStatus.Scheduled));
                this.scheduled = true;
            }
        }
    }

    private updateSessionStatus(status: CoachSessionStatus) {
        if (!this.coachSession) {
            return of(undefined);
        }

        this.coachSession.status = status;
        return this.commonDataService.saveEntities(this.coachSession).pipe(
            catchError((err) => {
                const error = ErrorHandlingUtilities.getHttpResponseMessage(err);
                this.setErrorMessage(error);
                return of(undefined);
            }),
            this.takeUntilDestroyed(),
        );
    }

    private async getCalendlyFrameUrl(coachRequest: ICoachRequest) {
        const currentPerson = await this.userService.getCurrentPerson();
        if (!currentPerson) {
            throw new Error("Current person could not be found");
        }

        // make sure login email is primed
        await this.directorySharedService.promiseToGetContactDetailsByPersonId(currentPerson.personId);

        const url = coachRequest.RequestType === CoachRequestType.CustomCoachingSession && coachRequest.RequestedTimeHrs
            ? `${AdaptClientConfiguration.CalendlyBaseUri}/coaching-session-${coachRequest.RequestedTimeHrs}`
            : `${AdaptClientConfiguration.CalendlyBaseUri}/scoping-session`;

        return formatCalendlyUrl({
            url,
            embedType: "Inline",
            prefill: {
                // form can be configured within calendly to have either full name or first/last name fields
                // just provide both so we don't have to change any code
                name: currentPerson.fullName,
                firstName: currentPerson.firstName,
                lastName: currentPerson.lastName,
                email: currentPerson.getLoginEmail()?.value,
                customAnswers: {
                    a1: coachRequest.Description,
                },
            },
        });
    }
}

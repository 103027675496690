import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { GoalStatus, GoalStatusLabel, OrderedGoalStatuses } from "@common/ADAPT.Common.Model/organisation/goal";

@Component({
    selector: "adapt-select-goal-status",
    templateUrl: "./select-goal-status.component.html",
    styleUrls: ["./select-goal-status.component.scss"],
})
export class SelectGoalStatusComponent {
    public readonly OrderedGoalStatuses = OrderedGoalStatuses;

    @Input() public status?: GoalStatus;
    @Output() public statusChange = new EventEmitter<GoalStatus>();

    public selectOpen = false;

    public statusDisplayExpr(status: GoalStatus) {
        return GoalStatusLabel[status];
    }

    // Need this so that button click on this component won't be propagated through to it parent, e.g.
    // the card header where click on it will toggle collapse.
    @HostListener("click", ["$event"])
    public stopPropagation(e: Event) {
        e.stopPropagation();
    }
}

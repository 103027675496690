import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable, InjectionToken, Injector, Provider } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Meeting, MeetingBreezeModel, MeetingStatus } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem, MeetingAgendaItemBreezeModel, MeetingAgendaItemStatus, MeetingAgendaItemType } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaItemSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item-supplementary-data";
import { MeetingAgendaTemplate, MeetingAgendaTemplateBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { MeetingAttendee, MeetingAttendeeBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting-attendee";
import { MeetingItem, MeetingItemBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting-item";
import { MeetingNote, MeetingNoteBreezeModel, MeetingNoteType } from "@common/ADAPT.Common.Model/organisation/meeting-note";
import { MeetingSupplementaryData, MeetingSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting-supplementary-data";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamMeetingGuidanceBreezeModel } from "@common/ADAPT.Common.Model/organisation/team-meeting-guidance";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { ServiceUri } from "@common/configuration/service-uri";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { UserService } from "@common/user/user.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import moment from "moment";
import { EMPTY, forkJoin, Observable, of, Subject } from "rxjs";
import { catchError, filter, map, share, switchMap, take, tap } from "rxjs/operators";
import { KanbanAuthService } from "../kanban/kanban-auth.service";
import { CommonTeamsService } from "../teams/common-teams.service";
import { CommonTeamsAuthService } from "../teams/common-teams-auth.service";
import { teamActiveMeetingPageRoute } from "./team-active-meeting-page/team-active-meeting-page.route";

type AdaptRoute = IAdaptRoute<{}>;
const TEAM_MEETINGS_PAGE = new InjectionToken<AdaptRoute>("TEAM_MEETINGS_PAGE_TOKEN");
export function provideTeamMeetingsPageRoute(route: AdaptRoute): Provider {
    return {
        provide: TEAM_MEETINGS_PAGE,
        useValue: route,
        multi: false,
    };
}

const AllMeetingsForPermissionVerificationRequestKey = "allMeetingsForPermissionVerification";

@Injectable({
    providedIn: "root",
})
export class MeetingsService extends BaseOrganisationService {
    // this is just to flow event from meeting-tab-content to team-meetings-page
    private _meetingIdChange$ = new Subject<number>();
    private _meetingForMeetingAttendeeChangedForCurrentPerson$: Observable<Meeting | undefined>;

    public constructor(
        injector: Injector,
        private orgService: OrganisationService,
        private userService: UserService,
        private teamsService: CommonTeamsService,
        private authorisationService: AuthorisationService,
        private featuresService: FeaturesService,
        private httpClient: HttpClient,
        private implementationKitService: ImplementationKitService,
        private kanbanAuthService: KanbanAuthService,
        @Inject(TEAM_MEETINGS_PAGE) private teamMeetingsPageRoute: AdaptRoute,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector);
        this._meetingForMeetingAttendeeChangedForCurrentPerson$ = rxjsBreezeService.entityTypeChanged(MeetingAttendee).pipe(
            filter((meetingAttendee) => meetingAttendee.attendeeId === this.userService.getCurrentPersonId()),
            tap((meetingAttendee) => {
                this.commonDataService.clearQueryCacheForRequestKey(AllMeetingsForPermissionVerificationRequestKey);
                this.commonDataService.clearQueryCacheForRequestKey(this.getCurrentActiveMeetingForPersonRequestKey(this.userService.getCurrentPersonId()!));
                this.commonDataService.clearQueryCacheForRequestKey(this.getMeetingByIdRequestKey(meetingAttendee.meetingId));
            }),
            // just in case is an attendee deletion -> meeting won't be defined. Need meeting entity to determine team
            switchMap((meetingAttendee) => meetingAttendee.meeting
                ? of(meetingAttendee.meeting)
                : this.getMeetingById(meetingAttendee.meetingId)),
            tap((meeting) => {
                if (meeting) {
                    this.commonDataService.clearQueryCacheForRequestKey(this.getTeamMeetingsRequestKey(meeting.teamId));
                }
            }),
            share(),
        );
    }

    public static getMeetingAgendaItemsTotalPlannedDurationInMinutes(meetingAgendaItems: MeetingAgendaItem[]) {
        return meetingAgendaItems.reduce((previous, current) => previous + (current?.plannedDurationInMinutes ?? 0), 0);
    }

    public get meetingForMeetingAttendeeChangedForCurrentPerson$() {
        return this._meetingForMeetingAttendeeChangedForCurrentPerson$;
    }

    public get meetingIdChange$() {
        return this._meetingIdChange$.asObservable();
    }

    public notifyMeetingIdChange(meetingId: number) {
        this._meetingIdChange$.next(meetingId);
    }

    public canViewTeamMeetings(teamId: number) {
        return this.authorisationService.getHasAccess(CommonTeamsAuthService.ViewTeamMeetings, { teamId });
    }

    public importAgendaItems(agendaItems: MeetingAgendaItem[], meetingId?: number, meetingAgendaTemplateId?: number, latestOrdinal?: number, hasPreWork = false) {
        if (agendaItems.length > 0) {
            return forkJoin(agendaItems.map((item, index) => this.createAgendaItem(
                latestOrdinal
                    ? latestOrdinal + index + 1
                    : item.ordinal,
                meetingId,
                meetingAgendaTemplateId,
            ).pipe(
                switchMap((newItem) => {
                    newItem.name = item.name;
                    newItem.plannedDurationInMinutes = item.plannedDurationInMinutes;
                    newItem.articleSlug = item.articleSlug;
                    newItem.componentSelector = item.componentSelector;
                    newItem.type = !hasPreWork ? item.type : MeetingAgendaItemType.AgendaItem;
                    if (item.supplementaryData?.itemDescription) {
                        return this.createAgendaItemSupplementaryData(newItem).pipe(
                            map((suppData) => {
                                suppData.itemDescription = item.supplementaryData?.itemDescription ?? "";
                                return newItem;
                            }),
                        );
                    } else {
                        return of(newItem);
                    }
                }),
            ))).pipe(
                switchMap((newItems) => this.fetchArticlesForAgendaItems(newItems)),
            );
        } else {
            return of([]);
        }
    }

    private fetchArticlesForAgendaItems(agendaItems: MeetingAgendaItem[]) {
        const articleRequests = Object.fromEntries(agendaItems
            .filter((item) => !!item.articleSlug)
            .map((item) => [item.articleSlug!, this.implementationKitService.getArticle(item.articleSlug!).pipe(
                catchError((err) => {
                    this.log.error(`Failed to fetch article ${item.articleSlug}. Error: ${err}`);
                    return of(null);
                }),
            )]),
        );

        if (Object.keys(articleRequests).length === 0) {
            // no article fetched, nothing to change for the agenda items
            return of(agendaItems);
        }

        return forkJoin(articleRequests).pipe(
            switchMap((articles) => {
                return forkJoin(agendaItems.map((item) => {
                    const article = item.articleSlug ? articles[item.articleSlug] : null;
                    if (!article) {
                        return of(undefined);
                    }

                    const suppData$ = item.supplementaryData
                        ? of(item.supplementaryData)
                        : this.createAgendaItemSupplementaryData(item);
                    return suppData$.pipe(
                        tap((suppData) => suppData.itemDescription = article.answer),
                    );
                }));
            }),
            map(() => agendaItems),
        );
    }

    public fetchArticlesForMeetingSuppData(meeting: Meeting, articleSlug?: ImplementationKitArticle) {
        if (!articleSlug) {
            return of(meeting);
        }

        return this.implementationKitService.getArticle(articleSlug).pipe(
            switchMap((article) => {
                if (!article) {
                    return of(meeting);
                }
                const suppData$ = meeting.supplementaryData
                    ? of(meeting.supplementaryData)
                    : this.createMeetingSupplementaryData(meeting);
                return suppData$.pipe(
                    tap((suppData) => suppData.purpose = article.answer),
                    map(() => meeting),
                );
            }),
            catchError((err) => {
                this.log.error(`Failed to fetch article ${articleSlug}. Error: ${err}`);
                return of(null);
            }),
        );
    }

    public createMeeting(teamId: number) {
        const now = new Date();
        const initialData: Partial<Meeting> = {
            teamId,
            organisationId: this.orgService.getOrganisationId(),
            meetingDateTime: moment(now).add(1, "h").startOf("h").toDate(), // default to start of next hour
            createdDateTime: now,
            status: MeetingStatus.NotStarted,
        };

        return this.commonDataService.create(MeetingBreezeModel, initialData);
    }

    public rescheduleMeeting(meeting: Meeting) {
        const endTime = moment(meeting.endTime);
        const meetingDuration = endTime.isAfter(meeting.meetingDateTime)
            ? moment.duration(endTime.diff(meeting.meetingDateTime))
            : moment.duration(1, "h");
        meeting.meetingDateTime = moment().add(1, "h").startOf("h").toDate(); // default 1h from now
        meeting.endTime = moment(meeting.meetingDateTime).add(meetingDuration).toDate(); // maintain previous planned duration
        meeting.status = MeetingStatus.NotStarted;
        return meeting;
    }

    public getMeetingCalendarFile(meeting: Meeting) {
        const uri = `${ServiceUri.MethodologyServicesServiceBaseUri}/MeetingCalendarExport`;
        let params = new HttpParams();
        params = params.set("meetingId", meeting.meetingId.toString());

        return this.httpClient.get(
            uri,
            {
                params,
                responseType: "blob", // makes response.body a Blob object
                observe: "response", // without this, response.body will be unknown
            },
        );
    }

    public sendMeetingCalendarInvites(meeting: Meeting) {
        if (meeting.extensions.isEnded) {
            this.log.error("MeetingService::sendMeetingCalendarInvites tried to send an invite, but it was ended");
            return EMPTY;
        }

        const uri = `${ServiceUri.MethodologyServicesServiceBaseUri}/MeetingCalendarSendInvites`;
        let params = new HttpParams();
        params = params.set("meetingId", meeting.meetingId.toString());

        return this.httpClient.post(uri, null, { params, observe: "response" });
    }

    public createMeetingAttendee(meetingId: number, personId: number) {
        const initialData: Partial<MeetingAttendee> = {
            meetingId,
            attendeeId: personId,
        };

        return this.commonDataService.create(MeetingAttendeeBreezeModel, initialData);
    }

    public createDefaultMeetingAttendees(meeting: Meeting) {
        return this.teamsService.getTeamById(meeting.teamId).pipe(
            switchMap((team) => this.teamsService.promiseToGetTeamMemberRoleConnections(team!, true)),
            map((roleConnections) => roleConnections.map((rc) => rc.connection.person)),
            switchMap((people) => people?.length
                ? forkJoin(people.map((p) => this.createMeetingAttendee(meeting.meetingId, p.personId)))
                : of([])),
        );
    }

    public createAgendaItem(ordinal: number, meetingId?: number, meetingAgendaTemplateId?: number, type = MeetingAgendaItemType.AgendaItem) {
        return this.commonDataService.create(MeetingAgendaItemBreezeModel, {
            meetingId,
            meetingAgendaTemplateId,
            ordinal,
            status: MeetingAgendaItemStatus.NotStarted,
            type,
        });
    }

    public createAgendaTemplate(teamId?: number) {
        const initialData: Partial<MeetingAgendaTemplate> = {
            organisationId: this.orgService.getOrganisationId(),
            teamId,
        };

        return this.commonDataService.create(MeetingAgendaTemplateBreezeModel, initialData);
    }

    public createAgendaItemSupplementaryData(meetingAgendaItem: MeetingAgendaItem) {
        // check if supplementary data exists first before creating
        const existingSuppData = meetingAgendaItem.entityAspect.entityState.isAdded()
            ? of(meetingAgendaItem.supplementaryData) // newly added agenda item entity -> if there is already supp data attached, use it
            : this.commonDataService.getById(MeetingAgendaItemSupplementaryDataBreezeModel, meetingAgendaItem.meetingAgendaItemId);

        return existingSuppData.pipe(switchMap((suppData) => suppData
            ? of(suppData)
            : this.commonDataService.create(MeetingAgendaItemSupplementaryDataBreezeModel, { meetingAgendaItem })));
    }

    public createMeetingSupplementaryData(meeting: Meeting) {
        // check if supplementary data exists first before creating
        const existingSuppData = meeting.entityAspect.entityState.isAdded()
            ? of(meeting.supplementaryData) // newly added meeting entity -> if there is already supp data attached, use it
            : this.getSupplementaryDataForMeeting(meeting.meetingId);

        return existingSuppData.pipe(switchMap((suppData) => suppData
            ? of(suppData)
            : this.commonDataService.create(MeetingSupplementaryDataBreezeModel, { meeting })));
    }

    public getMeetingAgendaTemplates() {
        return this.commonDataService.getAll(MeetingAgendaTemplateBreezeModel);
    }

    public getMeetingAgendaTemplateByCode(code: string) {
        const predicate = new MethodologyPredicate<MeetingAgendaTemplate>("code", "==", code);
        return this.commonDataService.getByPredicate(MeetingAgendaTemplateBreezeModel, predicate).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    public ascendingMeetingsSortFunction(a: Meeting, b: Meeting) {
        return a.meetingDateTime.getTime() - b.meetingDateTime.getTime();
    }

    public descendingMeetingsSortFunction(a: Meeting, b: Meeting) {
        return b.meetingDateTime.getTime() - a.meetingDateTime.getTime();
    }

    public getEndedMeetingsForTeam(teamId: number) {
        return this.getTeamMeetings(teamId).pipe(
            map((meetings) => meetings
                .filter((meeting) => meeting.extensions.isEnded)
                .sort(this.descendingMeetingsSortFunction)),
        );
    }

    public getUpcomingMeetingsForTeam(teamId: number) {
        return this.getTeamMeetings(teamId).pipe(
            map((meetings) => meetings
                .filter((meeting) => meeting.extensions.isNotStarted)
                // earliest meeting first
                .sort(this.ascendingMeetingsSortFunction)),
        );
    }

    public getOngoingMeetingsForTeam(teamId: number) {
        return this.getTeamMeetings(teamId).pipe(
            map((meetings) => meetings
                .filter((meeting) => meeting.extensions.isInProgress)
                // latest meeting first - ongoing meeting has started agenda item
                .sort(this.descendingMeetingsSortFunction)),
        );
    }

    public getMeetingGuidanceForTeam(teamId: number) {
        return this.commonDataService.getById(TeamMeetingGuidanceBreezeModel, teamId);
    }

    public primeAgendaItemsForNotStartedMeeting(teamId: number) {
        const predicate = new MethodologyPredicate<MeetingAgendaItem>("meeting.teamId", "==", teamId)
            .and(new MethodologyPredicate<MeetingAgendaItem>("meeting.status", "==", MeetingStatus.NotStarted));
        const key = this.getNotStartedMeetingAgendaItemsEncompassingKey(teamId);
        return this.commonDataService.getWithOptions(MeetingAgendaItemBreezeModel, key!, { predicate });
    }

    public primeAttendeesForNotStartedMeeting(teamId: number) {
        const predicate = new MethodologyPredicate<MeetingAttendee>("meeting.teamId", "==", teamId)
            .and(new MethodologyPredicate<MeetingAttendee>("meeting.status", "==", MeetingStatus.NotStarted));
        const key = this.getNotStartedMeetingAttendeesEncompassingKey(teamId);
        return this.commonDataService.getWithOptions(MeetingAttendeeBreezeModel, key!, { predicate });
    }

    public getAgendaItemsForMeeting(meeting: Meeting, primeNotesAndItems = true, forceRemote = false) {
        const meetingId = meeting.meetingId;
        const key = `meetingAgendaItemsForMeeting${meetingId}${forceRemote}`;
        const predicate = new MethodologyPredicate<MeetingAgendaItem>("meetingId", "==", meetingId);
        const encompassingKey = meeting.extensions.isNotStarted
            ? this.getNotStartedMeetingAgendaItemsEncompassingKey(meeting.teamId)
            : undefined;

        return this.commonDataService.getWithOptions(MeetingAgendaItemBreezeModel, key, {
            forceLocal: meetingId < 0,
            forceRemote,
            predicate,
            encompassingKey,
            navProperty: "supplementaryData",
        }).pipe(
            map((meetingAgendaItems) => meetingAgendaItems.sort((a, b) => a.ordinal - b.ordinal)),
            switchMap((agendaItems) => primeNotesAndItems
                ? forkJoin([
                    this.getMeetingNotesForMeeting(meetingId),
                    this.getMeetingItemsForMeeting(meetingId)]).pipe(
                        map(() => agendaItems),
                    )
                // don't have to prime when importing or linking items to ongoing meeting or when display meeting summary
                : of(agendaItems)),
        );
    }

    public getMeetingItemsForMeeting(meetingId: number) {
        const predicate = new MethodologyPredicate<MeetingItem>("meetingId", "==", meetingId);
        return this.commonDataService.getWithOptions(
            MeetingItemBreezeModel,
            this.getMeetingItemsEncompassingKey(meetingId),
            {
                forceLocal: meetingId < 0,
                predicate,
                navProperty: "item",
            },
        );
    }

    public getMeetingNotesForMeeting(meetingId: number) {
        const predicate = new MethodologyPredicate<MeetingNote>("meetingId", "==", meetingId);
        return this.commonDataService.getWithOptions(
            MeetingNoteBreezeModel,
            this.getMeetingNotesEncompassingKey(meetingId),
            {
                forceLocal: meetingId < 0,
                predicate,
            },
        );
    }

    public getAgendaItemsForMeetingAgendaTemplate(meetingAgendaTemplateId: number) {
        const key = `meetingAgendaItemsForMeetingAgendaTemplate${meetingAgendaTemplateId}`;
        const predicate = new MethodologyPredicate<MeetingAgendaItem>("meetingAgendaTemplateId", "==", meetingAgendaTemplateId);

        return this.commonDataService.getWithOptions(MeetingAgendaItemBreezeModel, key, {
            // if the agenda template isn't saved yet, the items will only exist in local cache. don't bother reaching out to server.
            forceLocal: meetingAgendaTemplateId < 0,
            predicate,
            navProperty: "supplementaryData",
        }).pipe(
            map((meetingAgendaItems) => meetingAgendaItems.sort((a, b) => a.ordinal - b.ordinal)),
        );
    }

    public getMeetingAttendeesForMeeting(meeting: Meeting) {
        const meetingId = meeting.meetingId;
        const predicate = new MethodologyPredicate<MeetingAttendee>("meetingId", "==", meetingId);
        const key = `meetingAttendeesForMeeting${predicate.getKey()}`;
        const encompassingKey = meeting.extensions.isNotStarted
            ? this.getNotStartedMeetingAttendeesEncompassingKey(meeting.teamId)
            : undefined;

        return this.commonDataService.getWithOptions(MeetingAttendeeBreezeModel, key, { predicate, encompassingKey });
    }

    public getSupplementaryDataForMeeting(meetingId: number) {
        return this.commonDataService.getById(MeetingSupplementaryDataBreezeModel, meetingId);
    }

    public getSupplementaryDataForMeetings(meetingIds: number[]) {
        const breezeNodeLimit = 10;
        const predicates = ArrayUtilities.splitAndProcessArrayChunks(
            meetingIds,
            breezeNodeLimit,
            (meetingIdChunk) => new MethodologyPredicate<MeetingSupplementaryData>("meetingId", "in", meetingIdChunk),
        );
        const queries = predicates.map((predicate) => this.commonDataService.getByPredicate(MeetingSupplementaryDataBreezeModel, predicate));

        return forkJoin(queries).pipe(
            map(ArrayUtilities.mergeArrays),
        );
    }

    public getMeetingNotesForAgendaItem(agendaItem: MeetingAgendaItem) {
        const key = `meetingNotesForAgendaItem${agendaItem.meetingAgendaItemId}`;
        const encompassingKey = this.getMeetingNotesEncompassingKey(agendaItem.meetingId);
        const predicate = new MethodologyPredicate<MeetingNote>("meetingAgendaItemId", "==", agendaItem.meetingAgendaItemId);

        return this.commonDataService.getWithOptions(MeetingNoteBreezeModel, key, {
            // a new agenda item doesn't ever have notes
            forceLocal: agendaItem.entityAspect.entityState.isAdded(),
            encompassingKey,
            predicate,
        });
    }

    public createMeetingNoteForAgendaItem(agendaItem: MeetingAgendaItem, type: MeetingNoteType) {
        const initialData: Partial<MeetingNote> = {
            meetingId: agendaItem.meetingId,
            meetingAgendaItemId: agendaItem.meetingAgendaItemId,
            type,
        };

        return this.commonDataService.create(MeetingNoteBreezeModel, initialData);
    }

    public createMeetingItemForAgendaItem(agendaItem: MeetingAgendaItem, itemId: number) {
        const initialData: Partial<MeetingItem> = {
            meetingId: agendaItem.meetingId,
            meetingAgendaItemId: agendaItem.meetingAgendaItemId,
            itemId,
        };

        return this.commonDataService.create(MeetingItemBreezeModel, initialData);
    }

    public getMeetingItemsForAgendaItem(agendaItem: MeetingAgendaItem) {
        const key = `meetingItemsForAgendaItem${agendaItem.meetingAgendaItemId}`;
        const encompassingKey = this.getMeetingItemsEncompassingKey(agendaItem.meetingId);
        const predicate = new MethodologyPredicate<MeetingItem>("meetingAgendaItemId", "==", agendaItem.meetingAgendaItemId);

        return this.commonDataService.getWithOptions(MeetingItemBreezeModel, key, {
            // a new agenda item doesn't ever have notes
            forceLocal: agendaItem.entityAspect.entityState.isAdded(),
            predicate,
            encompassingKey,
            navProperty: "item",
        }).pipe(
            // previously actions are always on with meetings - so this was not required. Now meetings can be run with disabled actions
            map((meetingItems) => meetingItems.filter((meetingItem) =>
                !!meetingItem.item?.board && this.kanbanAuthService.currentPersonCanViewBoard(meetingItem.item.board))),
        );
    }

    public getMeetingItemsForItem(itemId: number) {
        const key = `meetingItemsForItem${itemId}`;
        const predicate = new MethodologyPredicate<MeetingItem>("itemId", "==", itemId);

        return this.commonDataService.getWithOptions(MeetingItemBreezeModel, key, {
            predicate,
            navProperty: "meeting,meetingAgendaItem",
        });
    }

    // returns empty string (falsy) if meeting startable by current person
    public getNonStartableMeetingInfo(meeting: Meeting, startWithoutAgenda = false) {
        if (meeting.extensions.isNotStarted) {
            return forkJoin([
                this.getFirstActiveMeetingForCurrentPerson(),
                this.getMeetingAttendeesForMeeting(meeting), // just to prime attendees
            ]).pipe(
                map(([activeMeeting]) => {
                    const currentPersonId = this.userService.getCurrentPersonId();
                    let meetingInfo = "";
                    if (meeting.meetingAgendaItems.length < 1 && !startWithoutAgenda) {
                        meetingInfo = "You cannot start this meeting now. There is no meeting agenda.";
                    } else if (!meeting.meetingAttendees.some((attendee) => attendee.attendeeId === currentPersonId)) {
                        meetingInfo = "You cannot start this meeting now. The meeting can only be started by one of its participants.";
                    } else if (activeMeeting) {
                        meetingInfo = "You cannot start this meeting now. You are already participating in an ongoing meeting.";
                    } else if (!this.canEditMeetingForTeam(meeting.team!)) {
                        meetingInfo = "You do not have the permission to start this meeting.";
                    }

                    return meetingInfo;
                }),
            );
        } else {
            // started or completed meeting - return empty string
            return of("");
        }
    }

    public canStartMeeting(meeting: Meeting) {
        const currentPersonId = this.userService.getCurrentPersonId();
        return forkJoin([
            this.getFirstActiveMeetingForCurrentPerson(),
            this.getMeetingAttendeesForMeeting(meeting), // just to prime attendees
        ]).pipe(
            map(([activeMeeting]) => !(activeMeeting
                || meeting.meetingAgendaItems.length < 1
                || !meeting.meetingAttendees.some((attendee) => attendee.attendeeId === currentPersonId)
                || !this.canEditMeetingForTeam(meeting.team!))),
        );
    }

    public isCurrentPersonInMeeting(meeting: Meeting) {
        const currentPersonId = this.userService.getCurrentPersonId();
        return meeting.meetingAttendees.some((attendee) => attendee.attendeeId === currentPersonId);
    }

    public getFirstActiveMeetingForCurrentPerson() {
        return this.authorisationService.getHasAccess(CommonTeamsAuthService.ViewAnyTeamMeeting).pipe(
            take(1),
            switchMap((hasAccess) => {
                if (!hasAccess) {
                    return of([]);
                }

                const currentPersonId = this.userService.getCurrentPersonId();
                if (!currentPersonId) {
                    return of([]);
                }

                const personIdPredicate = new MethodologyPredicate<MeetingAttendee>("attendeeId", "==", currentPersonId);
                const predicate = new MethodologyPredicate<Meeting>("meetingAttendees", "any", personIdPredicate)
                    .and(new MethodologyPredicate<Meeting>("status", "==", MeetingStatus.InProgress));
                return this.commonDataService.getWithOptions(MeetingBreezeModel, this.getCurrentActiveMeetingForPersonRequestKey(currentPersonId), {
                    predicate,
                    navProperty: "meetingAgendaItems,meetingAttendees",
                }).pipe(
                    map((meetings) => meetings.filter(this.filterMeetingByTeamFeatures)),
                );
            }),
            map((activeMeetingsForPerson) => {
                // There is supposed to be only a single ongoing meeting. TODO: make sure person meetings not overlapping
                return activeMeetingsForPerson.length > 0
                    ? activeMeetingsForPerson[0]
                    : undefined;
            }),
        );
    }

    private getCurrentActiveMeetingForPersonRequestKey(personId: number) {
        return `currentActiveMeetingForPerson${personId}`;
    }

    public getRecentMeetingsForPerson(personId: number, upcomingDays = 14, previousDays = 14) {
        const fromTime = moment().add(-1 * previousDays, "days");
        const toTime = moment().add(upcomingDays, "days");
        const personIdPredicate = new MethodologyPredicate<MeetingAttendee>("attendeeId", "==", personId);
        const predicate = new MethodologyPredicate<Meeting>("meetingAttendees", "any", personIdPredicate)
            .and(new MethodologyPredicate<Meeting>("meetingDateTime", ">=", fromTime.toDate()))
            .and(new MethodologyPredicate<Meeting>("meetingDateTime", "<=", toTime.toDate()));
        const key = `meetingForPerson${personId}`;

        return this.commonDataService.getWithOptions(MeetingBreezeModel, key, {
            predicate,
            navProperty: "team,meetingAgendaItems,meetingAttendees",
        }).pipe(map((meetings) => meetings.filter(this.filterMeetingByTeamFeatures)));
    }

    @Autobind
    private filterMeetingByTeamFeatures(meeting: Meeting) {
        return this.featuresService.isFeatureActive(FeatureName.StewardshipWorkMeetings, meeting.team);
    }

    public getRecentMeetingsForTeam(teamId: number, upcomingDays = 14, previousDays = 14) {
        const fromTime = moment().add(-1 * previousDays, "days");
        const toTime = moment().add(upcomingDays, "days");
        const key = `recentTeamMeetingsWithAgendaItems${teamId}`;
        const predicate = new MethodologyPredicate<Meeting>("teamId", "==", teamId)
            .and(new MethodologyPredicate<Meeting>("meetingDateTime", ">=", fromTime.toDate()))
            .and(new MethodologyPredicate<Meeting>("meetingDateTime", "<=", toTime.toDate()));

        return this.commonDataService.getWithOptions(MeetingBreezeModel, key, {
            predicate,
            navProperty: "meetingAgendaItems",
        });
    }

    // Get all meetings the current user has access to within the last previous months
    // - don't set navProperty for this query so that entities returned are small
    public getAllRecentMeetings(previousMonths = 18, upcomingDays = 14) {
        const fromTime = moment().add(-1 * previousMonths, "months").startOf("day");    // without startOf day, it will always hit remote server
        const toTime = moment().add(upcomingDays, "days").endOf("day");                 // without startOf day, it will always hit remote server
        const predicate = new MethodologyPredicate<Meeting>("meetingDateTime", ">=", fromTime.toDate())
            .and(new MethodologyPredicate<Meeting>("meetingDateTime", "<", toTime.toDate()));
        const key = `getAllRecentMeetings_${predicate.getKey()}`;

        return this.commonDataService.getWithOptions(MeetingBreezeModel, key, {
            predicate,
            navProperty: "team",
        }).pipe(
            // latest meeting first
            map((meetings) => meetings.sort(this.descendingMeetingsSortFunction)),
        );
    }

    public getAllRecentMeetingsWithEditPermission(previousMonths = 6) {
        return this.getAllRecentMeetings(previousMonths).pipe(
            map((meetings) => meetings.filter((meeting) => this.canEditMeetingForTeam(meeting.team!))),
        );
    }

    public canEditMeetingForTeam(team: Team) {
        if (!this.authorisationService.currentPerson) {
            return false;
        }

        return this.authorisationService.personHasPermission(this.authorisationService.currentPerson, FeaturePermissionName.StewardshipWorkMeetingsEdit, team);
    }

    public canEditMeetingItemsAsParticipant(meeting: Meeting) {
        if (this.canEditMeetingForTeam(meeting.team!)) {
            return of(true);
        } else if (this.personCanParticipateInMeeting(this.authorisationService.currentPerson!, meeting)) {
            return this.personInMeeting(this.authorisationService.currentPerson!, meeting);
        } else {
            return of(false);
        }
    }

    public personCanEditMeeting(person: Person, meeting: Meeting) {
        return this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkMeetingsEdit, meeting);
    }

    public personCanReadMeeting(person: Person, meeting: Meeting) {
        return this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkMeetingsRead, meeting);
    }

    public personCanParticipateInMeeting(person: Person, meeting: Meeting) {
        return this.personCanEditMeeting(person, meeting) ||
            this.personCanReadMeeting(person, meeting) ||
            this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkMeetingsParticipantEdit, meeting);
    }

    public currentPersonInAnyTeamMeetings(teamId?: number) {
        // get all meetings instead of querying MeetingAttendee with meeting.teamId and attendeeId to reduce the number of queries
        // especially for organisation with plenty of teams, e.g. Carbon280 or acQuire
        // This is only 1 query for all teams instead of 40 queries for 40 teams which will choke the connections pool.
        // This will only be called by people with ParticipantEdit without MeetingRead and MeetingEdit from access verifier
        // - server will only return meetings readable
        // - without navProperty (which is agenda items and event series), the response is pretty small, just a few dates, numbers and short strings
        const query = this.commonDataService.getWithOptions(MeetingBreezeModel, AllMeetingsForPermissionVerificationRequestKey, {
            navProperty: undefined,
        });

        return query.pipe(
            map((meetings) => teamId
                ? !!meetings.find((meeting) => meeting.teamId === teamId)
                : meetings.length > 0));
    }

    public personInMeeting(person: Person, meeting: Meeting) {
        const predicate = new MethodologyPredicate<MeetingAttendee>("meetingId", "==", meeting.meetingId).and(
            new MethodologyPredicate<MeetingAttendee>("attendeeId", "==", person.personId));
        return this.commonDataService.getByPredicate(MeetingAttendeeBreezeModel, predicate).pipe(map((results) => results.length > 0));
    }

    public getMeetingByIdRequestKey(meetingId: number) {
        return `get${MeetingBreezeModel.identifier}ById${meetingId}`;
    }

    public getMeetingById(meetingId: number) {
        const predicate = new MethodologyPredicate<Meeting>("meetingId", "==", meetingId);

        return this.commonDataService.getWithOptions(MeetingBreezeModel, this.getMeetingByIdRequestKey(meetingId), {
            predicate,
            navProperty: "meetingAttendees, meetingAgendaItems, supplementaryData",
        }).pipe(map(ArrayUtilities.getSingleFromArray));
    }

    public gotoTeamMeetingsPage(teamId: number, meetingId?: number) {
        return this.teamMeetingsPageRoute.gotoRoute({ teamId }, { meetingId }).pipe(
            // as reloadOnSearch is false for meeting page, ngOnInit() won't be called if already in the page
            // - this is to notify the page that the meeting id is changed
            tap(() => {
                if (meetingId) {
                    this.notifyMeetingIdChange(meetingId);
                }
            }),
        );
    }

    public getTeamMeetingsPageObj(teamId: number, meetingId?: number) {
        return this.teamMeetingsPageRoute.getRouteObject({
            teamId,
        }, {
            meetingId,
        });
    }

    public getTeamMeetingsPage(teamId: number, meetingId?: number) {
        return this.teamMeetingsPageRoute.getRoute({
            teamId,
        }, {
            meetingId,
        });
    }

    @Autobind
    public gotoMeetingPage(meeting: Meeting) {
        return this.gotoTeamMeetingsPage(meeting.teamId, meeting.meetingId);
    }

    @Autobind
    public gotoActiveMeetingPage(meeting: Meeting, returnWorkflowPath?: string, showDescription: boolean = false) {
        return teamActiveMeetingPageRoute.gotoRoute({
            teamId: meeting.teamId,
            meetingId: meeting.meetingId,
        }, { returnWorkflowPath, showDescription }).pipe(
            // as reloadOnSearch is false for meeting page, ngOnInit() won't be called if already in the page
            // - this is to notify the page that the meeting id is changed
            tap(() => this.notifyMeetingIdChange(meeting.meetingId)),
        );
    }

    private getTeamMeetings(teamId: number) {
        const predicate = new MethodologyPredicate<Meeting>("teamId", "==", teamId);

        return this.commonDataService.getWithOptions(MeetingBreezeModel, this.getTeamMeetingsRequestKey(teamId), {
            predicate,
            navProperty: "meetingAgendaItems,eventSeries.eventType",
        });
    }

    private getTeamMeetingsRequestKey(teamId: number) {
        return `teamMeetingsWithAgendaItems${teamId}`;
    }

    private getMeetingNotesEncompassingKey(meetingId: number) {
        return `meetingNotesForMeeting${meetingId}`;
    }

    private getMeetingItemsEncompassingKey(meetingId: number) {
        return `meetingItemsForMeeting${meetingId}`;
    }

    private getNotStartedMeetingAgendaItemsEncompassingKey(teamId: number) {
        return `meetingAgendaItemsForNotStartedMeetingForTeam${teamId}`;
    }

    private getNotStartedMeetingAttendeesEncompassingKey(teamId: number) {
        return `meetingAttendeesForNotStartedMeetingForTeam${teamId}`;
    }
}

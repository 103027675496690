import { Injectable } from "@angular/core";
import { SupportUrlHome } from "@common/implementation-kit/implementation-kit.service";
import { IShellPopoverLink, IShellPopoverLinkItem } from "@common/shell/shell-popover-link-item/shell-popover-link-item";
import { SupportApplicationBarItemComponent } from "@common/shell/support-application-bar-item/support-application-bar-item.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class SupportApplicationBarItem extends BaseComponent implements IShellPopoverLinkItem {
    public id = "support-application-bar-item";

    private readonly canLoginToSupport$: Observable<boolean>;
    public readonly link: IShellPopoverLink;
    public readonly linkContent: IComponentRender<SupportApplicationBarItemComponent>;
    public readonly tooltip = "Support, documentation & help for the ADAPT platform";
    public readonly ordinal = 1;
    public readonly isShown$: Observable<boolean>;

    public constructor(
        responsiveService: ResponsiveService,
    ) {
        super();

        // make sure we check isStakeholderManager again if authorisation has changed
        this.canLoginToSupport$ = of(false);

        this.link = {
            href$: this.canLoginToSupport$.pipe(
                // have default link if not coach/stakeholderManager
                map((canLogin) => canLogin ? "" : SupportUrlHome),
            ),
            openInNewTab: true,
        };

        this.linkContent = {
            component: SupportApplicationBarItemComponent,
            params: {
                canLoginToSupport$: this.canLoginToSupport$,
                supportUrl: SupportUrlHome,
            },
        };

        this.isShown$ = responsiveService.isMobileSize$.pipe(
            map((isMobile) => !isMobile),
        );
    }
}

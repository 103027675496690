import { Component, Injector } from "@angular/core";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { CalendarIntegrationUtilities } from "@org-common/lib/calendar/calendar-integration-utilities";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { of, switchMap, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { MeetingsService } from "../../meetings.service";
import { MeetingsUiService } from "../../meetings-ui.service";
import { ScheduleMeetingWorkflowStepBaseComponent } from "../schedule-meeting-workflow-step-base.component";

@WorkflowStepComponent("adapt-schedule-meeting-agenda-step")
@Component({
    selector: "adapt-schedule-meeting-agenda-step",
    templateUrl: "./schedule-meeting-agenda-step.component.html",
})
export class ScheduleMeetingAgendaStepComponent extends ScheduleMeetingWorkflowStepBaseComponent {
    public addingNewAgendaItem = false;
    public allowEndedEditing = false;
    public submitting = false;

    private calendarIntegrationUtilities: CalendarIntegrationUtilities;
    public isEditorValid = true;

    public constructor(
        injector: Injector,
        private commonDataService: CommonDataService,
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
    ) {
        super();
        this.calendarIntegrationUtilities = new CalendarIntegrationUtilities(injector);
    }

    public get agendaIsReadonly() {
        return this.runData.meeting.extensions.isInProgress
            && this.meetingsService.isCurrentPersonInMeeting(this.runData.meeting);
    }

    public get agendaIsLocked() {
        return this.runData.meeting.extensions.isEnded && !this.allowEndedEditing;
    }

    @Autobind
    public allowAgendaEdit() {
        return this.meetingsUiService.promptForEndedMeetingAgendaEdit().pipe(
            tap(() => this.allowEndedEditing = true),
            this.takeUntilDestroyed(),
        );
    }

    public async onAgendaItemsChange(agendaItems: MeetingAgendaItem[]) {
        // suppData doesn't get returned in the changed entities, need to extract it out
        const suppDataEntities = agendaItems
            .filter((i) => !!i.supplementaryData)
            .map((i) => i.supplementaryData!);

        this.onEntitiesChanged([...agendaItems, ...suppDataEntities]);

        // shouldn't allow save while adding a new agenda item
        if (this.addingNewAgendaItem || !this.isEditorValid) {
            this.isValid.next(false);
        }
    }

    public onDescriptionChange(description: string) {
        const suppData = this.runData.meeting!.supplementaryData!;
        suppData.purpose = description;
        this.onEntitiesChanged([suppData]);
    }

    public workflowStepNext() {
        this.submitting = true;
        return this.commonDataService.saveEntities(this.runData.entitiesToConfirm).pipe(
            switchMap(() => this.runData.sendInvitations && !this.runData.meeting.extensions.isEnded
                ? this.calendarIntegrationUtilities.createOrUpdateProviderMeeting(this.runData.meeting, this.runData.meetingLocation, this.runData.createTeamsMeeting)
                : of(undefined)),
            tap(() => {
                this.submitting = false;
                this.workflowStepErrorMessage.next(undefined);
            }),
            catchError((err) => {
                this.submitting = false;
                const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(err);
                this.workflowStepErrorMessage.next(errorMessage);
                return throwError(() => new Error(errorMessage));
            }),
        );
    }
}

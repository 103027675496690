import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { CanvasType } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { Measurement } from "@common/ADAPT.Common.Model/organisation/measurement";
import { Theme } from "@common/ADAPT.Common.Model/organisation/theme";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ToolbarService } from "@common/shell/toolbar/toolbar.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IAdaptMenuItem } from "@common/ux/menu/menu.component";
import { BullseyeService } from "@org-common/lib/bullseye/bullseye.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { StrategicInputsService } from "@org-common/lib/strategic-inputs/strategic-inputs.service";
import { StrategyService } from "@org-common/lib/strategy/strategy.service";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { StrategicViewIcon, StrategicViewOption } from "@org-common/lib/strategy/strategy-view-constants";
import { debounceTime, merge } from "rxjs";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { IStrategyBoardViewOption, StrategyBoardComponent } from "../strategy-board/strategy-board.component";

export const StrategyBoardViewParam = "view";

export const BullseyeViewOption = {
    option: StrategicViewOption.Bullseye,
    iconClass: StrategicViewIcon.BullseyeIcon,
    text: "Bullseye",
};

export const SWTInputsViewOption = {
    option: StrategicViewOption.SWTInputs,
    iconClass: StrategicViewIcon.InputsCanvasIcon,
    text: "Strengths, weaknesses & trends",
};

export const CAInputsViewOptions = {
    option: StrategicViewOption.CAInputs,
    iconClass: StrategicViewIcon.CompetitorAnalysisIcon,
    text: "Competitor analysis",
};

export const themeViewOption: IStrategyBoardViewOption = {
    option: StrategicViewOption.Themes,
    iconClass: StrategicViewIcon.ThemeDescriptionIcon,
    text: "Theme descriptions",
    tour: "themes-descriptions",
};

export const StrategyBoardDefaultViewOptions = [StrategicViewOption.SWTInputs, StrategicViewOption.CAInputs, StrategicViewOption.Bullseye];

@Component({
    selector: "adapt-strategy-board-page",
    templateUrl: "./strategy-board-page.component.html",
})
export class StrategyBoardPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    public readonly EditStrategyBoard = StrategyAuthService.EditStrategyBoard;

    public hasGoal = false;
    public hasTheme = false;
    public viewOptions: IStrategyBoardViewOption[] = [themeViewOption];

    public viewMenuItems: IAdaptMenuItem[] = [{
        icon: "fa fa-eye-low-vision",
        text: "Show/Hide",
        items: [],
    }];

    public expandGoalsItemWithSeparator?: IAdaptMenuItem;
    public views = [...StrategyBoardDefaultViewOptions];

    private mapComponentCaller = new QueuedCaller<StrategyBoardComponent | undefined>();
    // need this or you will get ExpressionChangedAfterItHasBeenCheckedError
    private mapComponentCallerUpdater = this.createThrottledUpdater<StrategyBoardComponent | undefined>(
        (component) => this.mapComponentCaller.setCallee(component));

    @ViewChild(StrategyBoardComponent) public set mapComponent(component: StrategyBoardComponent | undefined) {
        this.mapComponentCallerUpdater.next(component);
    }

    public get mapComponent() {
        return this.mapComponentCaller.isSet
            ? this.mapComponentCaller.definedCallee
            : undefined;
    }

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        private strategyService: StrategyService,
        rxjsBreezeService: RxjsBreezeService,
        private toolbarService: ToolbarService,
        private inputsService: StrategicInputsService,
        private bullseyeService: BullseyeService,
        private authorisationService: AuthorisationService,
        featuresService: FeaturesService,
    ) {
        super(injector, elementRef);
        merge(
            rxjsBreezeService.entityTypeChanged(Theme),
            rxjsBreezeService.entityTypeChanged(Measurement),
        ).pipe(
            debounceTime(100), // just do 1 init even with multiple measurements and goal changes in a single save
            this.takeUntilDestroyed(),
        ).subscribe(() => this.ngOnInit());

        if (featuresService.isFeatureActive(FeatureName.StrategicInputs)) {
            this.inputsService.inputsAttachmentChanged$.pipe(
                this.takeUntilDestroyed(),
            ).subscribe((canvasType) => {
                switch (canvasType) {
                    case CanvasType.CompetitorAnalysis:
                        this.ensureViewIsSelected(StrategicViewOption.CAInputs);
                        break;
                    case CanvasType.StrengthsWeaknessesTrends:
                        this.ensureViewIsSelected(StrategicViewOption.SWTInputs);
                        break;
                    default:
                        this.ensureViewIsSelected(StrategicViewOption.SWTInputs);
                        this.ensureViewIsSelected(StrategicViewOption.CAInputs);
                        break;
                }
            });
        }

        if (featuresService.isFeatureActive(FeatureName.Bullseye)) {
            this.bullseyeService.bullseyeAttachmentChanged$.pipe(
                this.takeUntilDestroyed(),
            ).subscribe(() => this.ensureViewIsSelected(StrategicViewOption.Bullseye));
        }

        this.strategyService.themeAdded$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.ensureViewIsSelected(StrategicViewOption.Themes));
    }

    public async ngOnInit() {
        const canReadBoard = await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadStrategyBoard);

        if (canReadBoard) {
            this.strategyService.getAllThemes().pipe(
                this.takeUntilDestroyed(),
            ).subscribe((themes) => {
                this.hasTheme = themes.length > 0;
                this.notifyActivated();
            });
        } else {
            this.hasTheme = false;
            this.notifyActivated();
        }


        const viewValueString = this.getSearchParameterValue(StrategyBoardViewParam);
        if (viewValueString) {
            this.views = viewValueString.split(",") as StrategicViewOption[];
        }

        await this.updateView();
    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        this.toolbarService.resetToolbar();
    }

    @Autobind
    public addTheme() {
        return this.strategyService.editThemeAfterCreate();
    }

    public async updateView() {
        this.viewOptions = await this.getViewOptions();

        // need to check if the view is valid
        this.views = this.views.filter((view) => !!this.viewOptions.find((option) => option.option === view));

        if (this.views.length > 0) {
            this.setSearchParameterValue(StrategyBoardViewParam, this.views.join(","));
        } else {
            this.deleteSearchParameter(StrategyBoardViewParam);
        }

        this.viewMenuItems = [{
            ...this.viewMenuItems[0],
            items: this.mapViewOptionsToMenuItems(this.viewOptions),
        }];
    }

    private async getViewOptions() {
        const viewOptions = [themeViewOption];

        if (await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadStrategicInputs)) {
            viewOptions.push(SWTInputsViewOption);
            viewOptions.push(CAInputsViewOptions);
        }

        if (await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadBullseye)) {
            viewOptions.push(BullseyeViewOption);
        }

        return viewOptions;
    }

    // after adding theme, the view should be selected or else the newly added entity will not be visible.
    private ensureViewIsSelected(view: StrategicViewOption) {
        if (!this.isViewSelected(view)) {
            this.toggleView(view);
        }
    }

    public isViewSelected(view: StrategicViewOption) {
        return this.views.includes(view);
    }

    public toggleView(view: StrategicViewOption) {
        if (this.isViewSelected(view)) {
            ArrayUtilities.removeElementFromArray(view, this.views);
        } else {
            this.views.push(view);
        }

        // this is to toggle ngOnChanges for zones
        this.views = [...this.views];

        this.updateView();
    }

    mapViewOptionsToMenuItems(viewOptions: IStrategyBoardViewOption[]) {
        return viewOptions.map((vo) => {
            return {
                text: vo.text,
                isChecked: this.views.some((v: StrategicViewOption) => v === vo.option),
                onClick: (e: any) => this.toggleView(viewOptions.find((v) => v.text === e.itemData.text)!.option),
            } as IAdaptMenuItem;
        });
    }
}

export const StrategyBoardPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-strategy-board-page", StrategyBoardPageComponent)
    .atOrganisationUrl("/strategy-board")
    .withTitle("Strategic Working Board")
    .verifyingFeatures(FeatureName.StrategyBoard)
    .verifyingAccess(StrategyAuthService.ReadStrategyBoard)
    .reloadOnSearch(false)
    .build();

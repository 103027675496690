import { BaseEntity } from "@common/ADAPT.Common.Model/base-entity";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { BreezeModelBuilder } from "../breeze-model-builder";

export class MeetingAgendaTemplate extends BaseEntity<MeetingAgendaTemplate> {
    public meetingAgendaTemplateId!: number;
    public organisationId!: number;
    public name!: string;
    public code?: string;
    public version!: number;
    public meetingDescriptionArticleSlug?: ImplementationKitArticle;
    public teamId?: number;

    public organisation!: Organisation;
    public team?: Team;
}

export const MeetingAgendaTemplateBreezeModel = new BreezeModelBuilder("MeetingAgendaTemplate", MeetingAgendaTemplate)
    .hasSource()
    .isOrganisationEntity()
    .build();

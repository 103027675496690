import { Component, Inject } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { EMPTY, Observable } from "rxjs";
import { filter, finalize, map, switchMap, tap } from "rxjs/operators";
import { MeetingsService } from "../../meetings/meetings.service";

// Note - ended up with Maximum call stack exceeded error when this component declared in the meetings-shared module
// Moved it here just to allow agenda selection but won't be able to display fancy meeting agenda item contents
// until the other leadership/stewardship old modules are moved.
@Component({
    selector: "adapt-link-meeting-agenda-item-dialog",
    templateUrl: "./link-meeting-agenda-item-dialog.component.html",
})
export class LinkMeetingAgendaItemDialogComponent extends BaseDialogComponent<MeetingAgendaItem> {
    public readonly dialogName = "LinkMeetingAgendaItemDialog";

    public recentMeetingsDataSource$: Observable<DataSource>;
    public selectedMeeting?: Meeting;
    public loadingItems = false;
    public agendaItems: MeetingAgendaItem[] = [];
    public selectedAgendaItems: MeetingAgendaItem[] = [];

    private selectMeetingUpdater = this.createThrottledUpdater((meeting: Meeting) => this.selectMeeting(meeting));

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public item: Item,
        private meetingsService: MeetingsService,
    ) {
        super();

        this.recentMeetingsDataSource$ = this.meetingsService.getAllRecentMeetingsWithEditPermission().pipe(
            map((meetings) => new DataSource({
                store: new ArrayStore({
                    data: meetings,
                    key: "meetingId",
                }),
                group: (meeting: Meeting) => meeting.team?.name ?? "",
            })),
        );

        // if there is an active meeting, that will be selected first
        this.meetingsService.getFirstActiveMeetingForCurrentPerson().pipe(
            filter((meeting) => !!meeting && this.meetingsService.canEditMeetingForTeam(meeting.team!)),
            this.takeUntilDestroyed(),
        ).subscribe((meeting) => {
            if (meeting) {
                this.meetingSelected(meeting);
            }
        });
    }

    public meetingSelected(meeting: Meeting) {
        // use throttled updater here as it is causing ExpressionChangedAfterItHasBeenChecked from the
        // selection of currently active meeting from constructor and callback from dxSelectBox
        // (timing - not every time but only when getFirstActiveMeetingForCurrentPerson is really fast)
        this.selectMeetingUpdater.next(meeting);
    }

    private selectMeeting(meeting: Meeting) {
        this.selectedAgendaItems = [];
        this.selectedMeeting = meeting;
        this.loadingItems = true;
        this.meetingsService.getAgendaItemsForMeeting(meeting, false).pipe(
            this.takeUntilDestroyed(),
            finalize(() => this.loadingItems = false),
        ).subscribe((agendaItems) => {
            this.agendaItems = agendaItems;
            if (meeting.extensions.firstInProgressItem) {
                // the current in progress meeting agenda item will be pre-selected
                this.selectedAgendaItems = [meeting.extensions.firstInProgressItem];
            }
        });
    }

    @Autobind
    public linkAndSave() {
        if (this.selectedAgendaItems.length > 0) {
            return this.meetingsService.createMeetingItemForAgendaItem(this.selectedAgendaItems[0], this.item.itemId).pipe(
                switchMap((meetingItem) => this.meetingsService.saveEntities([meetingItem])),
                tap(() => this.resolve(this.selectedAgendaItems[0])),
            );
        } else {
            return EMPTY;
        }
    }
}

import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowScope, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";
import { createActivityBriefWorkflow, createWorkshopScheduleWorkflows } from "../../workflow-utilities";

export const gatherInputsWorkflowName = "Gather inputs for strategy";

export const gatherInputsWorkflow = buildLocalWorkflow({
    name: gatherInputsWorkflowName,
    workflowId: WorkflowIdentifier.GatherInputs,
    type: WorkflowType.Journey,
    time: "4 hours",
    people: "Leadership team",
    mindset: "Collaborative",
    iconStyle: "fal fa-briefcase-blank",
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Gather the inputs your leadership team will need to enable setting great objectives in line with your vision.",
    articleSlug: ImplementationKitArticle.GatherInputsOutcomes,
    wrapUpSlug: ImplementationKitArticle.GatherInputsWrapUp,
    category: OrganisationCategoryValue.BuildStrategy,
    scope: WorkflowScope.Team,
    ordinal: 60,
    featuresToEnable: [FeatureName.StrategicInputs, FeatureName.StrategyBoard],
    compulsoryPrerequisites: [WorkflowIdentifier.StrategicThemes],
    wrapUpGuidedTourIdentifier: TourIdentifier.StrategicInputsWrapupTour,
    workflows: [
        createActivityBriefWorkflow(gatherInputsWorkflowName, WorkflowIdentifier.GatherInputs, undefined, ImplementationKitArticle.GatherInputsMeetingDescription, ImplementationKitArticle.GatherInputsMeetingPreWork),
        ...createWorkshopScheduleWorkflows(gatherInputsWorkflowName, WorkflowIdentifier.GatherInputs),
    ],
});

import { Injectable, Injector } from "@angular/core";
import { Survey, SurveyEmailStatus, SurveyStatus, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { UserService } from "@common/user/user.service";
import { BaseUiService } from "@common/ux/base-ui.service/base-ui.service";
import { DateFormats } from "@common/ux/date-formats";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import moment from "moment";
import { of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { WorkflowService } from "../workflow/workflow.service";
import { EditSurveyDialogComponent } from "./edit-survey-dialog/edit-survey-dialog.component";
import { SurveyService } from "./survey.service";
import { SurveyUtils } from "./survey-utils";
import { IViewSurveyOutstandingResponsesData, ViewSurveyOutstandingResponsesDialogComponent } from "./view-survey-outstanding-responses-dialog/view-survey-outstanding-responses-dialog.component";
import { ViewSurveyRespondentsDialogComponent } from "./view-survey-respondents-dialog/view-survey-respondents-dialog.component";

@Injectable({
    providedIn: "root",
})
export class SurveyUiService extends BaseUiService {
    public constructor(
        injector: Injector,
        private surveyService: SurveyService,
        private userService: UserService,
        private orgService: OrganisationService,
        private workflowService: WorkflowService,
    ) {
        super(injector);
    }

    public promptToDeleteUpcomingSurvey(survey: Survey) {
        return this.promptToDeleteAndSaveEntities(
            [survey],
            "Delete upcoming survey?",
            `<p>The upcoming survey <i>${survey.name}</i> starting on ${moment(survey.startTime).format(DateFormats.moment.shortdatetime)} will be deleted.</p>
                <p>Are you sure you want to delete?</p>`,
        );
    }

    public promptToDeleteSurvey(survey: Survey) {
        return this.workflowService.isSurveyUsedByActiveWorkflow(survey.surveyId).pipe(
            switchMap((surveyActivelyUsed) => surveyActivelyUsed
                ? this.dialogService.showMessageDialog(
                    "Deletion failed: Survey in use",
                    `<p>The survey <i>${survey.name}</i> started on ${moment(survey.startTime).format(DateFormats.moment.shortdatetime)}
                        is currently being used by an active pathway and therefore cannot be deleted.</p>`,
                    "OK")
                : this.promptToDeleteAndSaveEntities(
                    [survey],
                    "Deleting survey?",
                    `<p>The survey <i>${survey.name}</i> started on ${moment(survey.startTime).format(DateFormats.moment.shortdatetime)}
                        together with all the submitted responses will be deleted.
                    </p>
                    <p>Are you sure you want to delete?</p>`,
                    "Please verify that you understand this action cannot be undone",
                )),
        );
    }

    public createSurvey(surveyType: SurveyType, team?: Team) {
        const getSurveyorId = team?.teamLeaderPersonId
            ? of(team?.teamLeaderPersonId)
            // rb configuration will have the definition of leader team -> team leader to be the default
            // organisation leader
            : this.surveyService.getResilientBusinessSurveyConfigurations().pipe(
                map((rbConfigs) => rbConfigs.length > 0 && rbConfigs[0].leaderTeam?.teamLeaderPersonId
                    ? rbConfigs[0].leaderTeam.teamLeaderPersonId
                    : this.userService.getCurrentPersonId())); // fall back to current person if cannot find org lead

        return getSurveyorId.pipe(
            map((surveyorId) => ({
                organisationId: this.orgService.getOrganisationId(),
                createdById: this.userService.getCurrentPersonId(),
                name: "",
                status: SurveyStatus.NotStarted,
                startTime: SurveyUtils.startOfWorkingDay(),
                endTime: SurveyUtils.defaultEndTime(),
                surveyType,
                emailStatus: SurveyEmailStatus.NotSent,
                team,
                surveyorId,
            })),
            switchMap((initialData) => this.surveyService.createSurvey(initialData)),
            switchMap((survey) => this.editSurvey(survey)),
        );
    }

    public editSurvey(survey: Survey) {
        return this.dialogService.open(EditSurveyDialogComponent, survey);
    }

    public viewOutstandingResponsesForSurvey(survey: Survey, showEndTime?: boolean) {
        return this.dialogService.open(ViewSurveyOutstandingResponsesDialogComponent, { survey, showEndTime } as IViewSurveyOutstandingResponsesData);
    }

    public viewRespondentsForSurvey(survey: Survey) {
        return this.dialogService.open(ViewSurveyRespondentsDialogComponent, survey);
    }
}

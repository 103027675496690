<div *ngIf="categoryMetadata as metadata"
     #category
     class="category"
     [style.--category-colour]="metadata.colour">
    <i *ngIf="metadata.icon"
       [ngClass]="metadata.icon"></i>
    <span>{{metadata.name}}</span>

    <!-- container set to this div so that it stays open while hovering the tooltip itself -->
    <dx-tooltip [target]="category"
                [container]="category"
                [hideOnParentScroll]="true"
                class="dx-tooltip-element"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                [position]="{ my: 'right', at: 'left', collision: 'flip none' }"
                [maxWidth]="600">
        <div *dxTemplate="let data of 'content'">
            <div class="adapt-tooltip-content text-start">
                {{metadata.description}}
            </div>

            <div *ngIf="latestOdScoreString"
                 class="category-score">
                Health Check Score: {{latestOdScoreString}}%
                <a *ngIf="odSurveySummaryPageRoute as summaryPageRoute"
                   class="btn btn-primary"
                   [routerLink]="summaryPageRoute.path"
                   [queryParams]="summaryPageRoute.queryParams">See Health Check Details</a>
            </div>
        </div>
    </dx-tooltip>
</div>

import { HttpErrorResponse, HttpResponse } from "@angular/common/http";

export interface IIdentityAction {
    httpMethod: string;
    partialUri: string;
    redirectUrl?: string;
    headers?: { [key: string]: string };
    dataTransform?: (...args: any[]) => any;
    preInitialisation?: boolean;
    withCredentials?: boolean;
    onSuccess?: (response: HttpResponse<any>) => void;
    onError?: (response: HttpErrorResponse) => void;
}

export class IdentityActions {
    public static Actions: { [key: string]: IIdentityAction } = {
        login: {
            partialUri: "/Token",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            dataTransform: $.param,
            httpMethod: "post",
        },
        registerOrganisation: {
            partialUri: "/Account/RegisterOrganisation",
            httpMethod: "post",
        },
        forgotPassword: {
            partialUri: "/Account/ForgotPassword",
            httpMethod: "post",
        },
        resetPassword: {
            partialUri: "/Account/ResetPassword",
            httpMethod: "post",
        },
        isRegistered: {
            partialUri: "/Account/IsRegistered",
            httpMethod: "post",
        },
        logout: {
            partialUri: "/Account/Logout",
            httpMethod: "post",
        },
        changePassword: {
            partialUri: "/Account/ChangePassword",
            httpMethod: "post",
        },
        getServerSecurityRoles: {
            partialUri: "/Account/GetServerSecurityRoles",
            httpMethod: "get",
        },
        getHelpjuiceToken: {
            partialUri: "/Account/GetHelpjuiceToken",
            httpMethod: "get",
        },
        addPeople: {
            partialUri: "/Account/AddPeople",
            httpMethod: "post",
            redirectUrl: "/account/set-password",
        },
        sendWelcomeEmail: {
            partialUri: "/Account/SendWelcomeEmail",
            httpMethod: "post",
        },
        validateAuthenticationToken: {
            partialUri: "/Account/ValidateAuthenticationToken",
            httpMethod: "get",
            preInitialisation: true,
            withCredentials: true,
        },
    };
}

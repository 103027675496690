import { Component, Inject, Injector, ViewChild } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { Input as StrategicInput } from "@common/ADAPT.Common.Model/organisation/input";
import { InputLocation } from "@common/ADAPT.Common.Model/organisation/input-location";
import { CanvasType, CanvasTypeLabel, InputsCanvas } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { Theme } from "@common/ADAPT.Common.Model/organisation/theme";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { IAdaptLinkObject } from "@common/route/route.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IDxListItemReorderedEvent } from "@common/ux/dx.types";
import { StrategicViewIcon } from "@org-common/lib/strategy/strategy-view-constants";
import { debounceTime, Observable, startWith, Subject, switchMap } from "rxjs";
import { WorkflowService } from "../../workflow/workflow.service";
import { SelectInputComponent } from "../select-input/select-input.component";
import { StrategicInputsService } from "../strategic-inputs.service";

export interface IAttachInputsDialogData {
    inputName: string;
    canvasType: CanvasType;
    theme: Theme;
    pageRoute$: Observable<string>;
}

@Component({
    selector: "adapt-attach-inputs-dialog",
    templateUrl: "./attach-inputs-dialog.component.html",
    styleUrls: ["./attach-inputs-dialog.component.scss"],
})
export class AttachInputsDialogComponent extends BaseDialogWithDiscardConfirmationComponent<InputLocation[]> {
    public readonly dialogName = "AttachInputs";
    public readonly StrategicViewIcon = StrategicViewIcon;

    public selectedCanvasUpdater = this.createThrottledUpdater<InputsCanvas>((canvas) => this.selectedCanvas = canvas);
    public selectedCanvas?: InputsCanvas;

    public selectedTheme!: Theme;
    public implementationKitArticle = ImplementationKitArticle.StrategicInputAttach;
    public canvasTypeLabel: string;

    public existingLocations: InputLocation[] = [];

    public hasCanvas = false;

    public inputsPathwayRoute$?: Observable<IAdaptLinkObject>;
    protected entitiesToConfirm: InputLocation[] = [];

    private updateDebouncer = new Subject<void>();
    @ViewChild(SelectInputComponent) private selectInputComponent?: SelectInputComponent;

    public constructor(
        injector: Injector,
        private inputsService: StrategicInputsService,
        @Inject(ADAPT_DIALOG_DATA) public data: IAttachInputsDialogData,
        workflowService: WorkflowService,
    ) {
        super(injector);

        this.inputsPathwayRoute$ = workflowService.getWorkflowPageRoute("GatherInputs");

        this.selectedTheme = data.theme;
        this.autoResolveData = this.entitiesToConfirm;

        this.canvasTypeLabel = CanvasTypeLabel[data.canvasType];
        if (data.canvasType === CanvasType.CompetitorAnalysis) {
            this.implementationKitArticle = ImplementationKitArticle.CompetitorAnalysisAttach;
        }

        inputsService.getLatestCanvasOfType(data.canvasType).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((canvas) => {
            this.hasCanvas = !!canvas;
            if (canvas) {
                this.selectedCanvasUpdater.next(canvas);
            }
            this.isInitialised = true;
        });

        this.updateDebouncer.pipe(
            startWith(undefined),
            debounceTime(200), // just want 1 update if both theme and zone changes, theme changed can cause zone to change
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateInputLocations());
    }

    public get hasValidationErrors() {
        return this.entitiesToConfirm.length < 1 || this.entitiesToConfirm.some((entity) => entity.entityAspect.hasValidationErrors);
    }

    public get showCanvasSelector() {
        return this.data.canvasType !== CanvasType.CompetitorAnalysis;
    }

    @Autobind
    public inputFilter(input: StrategicInput) {
        return !this.existingLocations.find((location) => location.inputId === input.inputId);
    }

    public onInputSelected(input?: StrategicInput) {
        if (input) {
            this.inputsService.createInputLocationWithInput(input).pipe(
                switchMap((newInputLocation) => {
                    newInputLocation.theme = this.selectedTheme;
                    this.entitiesToConfirm.push(newInputLocation);
                    return this.inputsService.updateInputLocationOrdinal(newInputLocation);
                }),
                this.takeUntilDestroyed(),
            ).subscribe(() => this.updateDebouncer.next());
        }
    }

    public onInputDetached(input: StrategicInput) {
        const unsavedLocation = this.entitiesToConfirm.find((location) => location.inputId === input.inputId && location.entityAspect.entityState.isAdded());
        if (unsavedLocation) {
            ArrayUtilities.removeElementFromArray(unsavedLocation, this.entitiesToConfirm);
            unsavedLocation.entityAspect.rejectChanges();
            this.updateDebouncer.next();
        } else {
            // not a newly added entity, need to find it from existingLocations and delete it
            const existingLocation = this.existingLocations.find((location) => location.inputId === input.inputId);
            if (existingLocation) {
                this.inputsService.remove(existingLocation)
                    .subscribe(() => {
                        ArrayUtilities.addElementIfNotAlreadyExists(this.entitiesToConfirm, existingLocation);
                        this.updateDebouncer.next();
                    });
            }
        }
    }

    public reorderInputLocations(e: IDxListItemReorderedEvent<InputLocation[]>) {
        SortUtilities.reorderItemInIntegerSortedArray(this.existingLocations, "ordinal", e.fromIndex, e.toIndex);
        ArrayUtilities.addElementIfNotAlreadyExists(this.entitiesToConfirm, ...this.existingLocations);
    }

    public onThemeChanged(theme: Theme) {
        this.selectedTheme = theme;

        this.isInitialised = false;
        this.updateDebouncer.next();
    }

    public filterZone(zone: Zone) {
        return zone !== Zone.ResearchAndDevelopment;
    }

    private updateInputLocations() {
        this.inputsService.getInputLocationsForTheme(this.selectedTheme).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((inputLocations) => {
            this.existingLocations = inputLocations.filter((location) => location.input.canvas.type === this.data.canvasType);
            this.isInitialised = true;
            this.selectInputComponent?.updateDataSource();
        });
    }
}

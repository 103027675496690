<div class="workflow-card"
     #workflowCard
     [attr.data-tour]="'pathway-state-' + WorkflowState[workflowState]"
     [attr.data-workflow-id]="workflow?.workflowId"
     [class.workflow-disabled]="!(isActionable && hasWorkflowAccess)"
     [style.--category-colour]="categoryMetadata?.colour">
    <div class="d-flex flex-column card-content gap-2">
        <div class="card-header">
            <div *ngIf="categoryMetadata?.icon"
                 #categoryIcon
                 class="me-2 category-icon">
                <div class="fa-stack fa-2x">
                    <i class="fa-solid fa-circle fa-stack-2x"></i>
                    <i [ngClass]="categoryMetadata!.icon"
                       class="fa-inverse fa-stack-1x"></i>
                </div>

                <dx-tooltip [target]="categoryIcon"
                            [container]="workflowCard"
                            [wrapperAttr]="{class: 'category-tooltip'}"
                            [showEvent]="{name: 'dxhoverstart', delay: 500}"
                            hideEvent="dxhoverend"
                            position="top">
                    <div *dxTemplate="let data of 'content'">
                        <div class="adapt-tooltip-content text-start">{{categoryMetadata?.name}}</div>
                    </div>
                </dx-tooltip>
            </div>
            <div #workflowLink
                 class="me-auto">
                <h3 class="mb-0">
                    <adapt-link-workflow [workflow]="workflow"
                                         [adaptLinkDisabled]="!workflow || workflow.private"
                                         class="me-auto"></adapt-link-workflow>
                </h3>
            </div>
            <div *ngIf="connection && workflow?.isDismissible && canContinueWorkflow && hasWorkflowAccess"
                 class="ms-auto">
                <button adaptButton="closeBorderless"
                        adaptTooltip="End pathway"
                        [attr.data-test]="'end-pathway-' + workflow?.workflowId"
                        class="workflow-dismiss"
                        (click)="deleteConnection(connection)"></button>
            </div>
        </div>

        <div class="overview-component d-flex flex-column flex-grow-1 gap-2">
            <div *ngIf="workflow && workflow.people"
                 class="d-flex flex-row flex-wrap column-gap-3">
                <!-- hidden for now -->
                <div *ngIf="workflow.time && false"
                     class="text-nowrap"><i class="fas fa-fw fa-clock me-1"></i><b>Time required:</b> {{workflow.time}}
                </div>
                <div *ngIf="workflow.people"
                     class="text-nowrap"><i class="fas fa-fw fa-user-group me-1"></i><b>People
                        required:</b> {{workflow.people}}
                </div>
            </div>

            <ng-container #overviewContainer></ng-container>

            <adapt-workflow-card-action *ngIf="workflow?.overview && !showStepOverviewComponent"
                                        (workflowState)="workflowState = $event"
                                        [workflow]="workflow"
                                        [connection]="connection">
                <div [innerHtml]="workflow!.overview"></div>
            </adapt-workflow-card-action>
        </div>
    </div>
</div>

<dx-tooltip *ngIf="workflow?.category !== OrganisationCategoryValue.Others && (currentStatus === WorkflowStatusEnum.Current || currentStatus === WorkflowStatusEnum.Completed)"
            [target]="workflowLink"
            class="dx-tooltip-element"
            [showEvent]="{name: 'dxhoverstart', delay: 500}"
            hideEvent="dxhoverend"
            position="top"
            [maxWidth]="550">
    <div *dxTemplate="let data of 'content'">
        <div class="adapt-tooltip-content text-start">
            <adapt-show-workflow-completion-status [workflow]="workflow"></adapt-show-workflow-completion-status>
        </div>
    </div>
</dx-tooltip>

<adapt-toolbar-content alignment="right">
    <button [adaptButtonIcon]="hasCollapsed ? 'expandBorderless' : 'collapseBorderless'"
            (click)="hasCollapsed ? expandAll() : collapseAll()">{{hasCollapsed ? "Expand all" : "Collapse all"}}
    </button>
</adapt-toolbar-content>

<ng-template #workflowUnlock
             let-view>
    <!-- hack to set view to initialised when this renders -->
    <!-- if on an object will be truthy, and the function will be executed -->
    <div *ngIf="{_: viewInitialised(view.key)}"
         class="card inner-card">
        <div class="card-body">
            This feature has not been enabled yet.
            <ng-container *ngIf="view.workflow">
                We recommend completing the <strong>
                    <adapt-link-workflow [workflow]="view.workflow"></adapt-link-workflow>
                </strong>
                pathway, which will guide you through using this feature, and enable it for you.
            </ng-container>
        </div>
    </div>
</ng-template>

<div class="d-flex flex-column gap-4">
    <div class="section-large">
        <h2 class="d-print-none"
            (click)="CollapseState[DisplayGroupKey.GoalsObjectivesView] = !CollapseState[DisplayGroupKey.GoalsObjectivesView]">
            Goals & objectives
            <i *ngIf="!isPrinting"
               class="ms-auto fal {{CollapseState[DisplayGroupKey.GoalsObjectivesView] ? 'fa-chevron-down' : 'fa-chevron-up'}}"></i>
        </h2>
        <div [collapse]="CollapseState[DisplayGroupKey.GoalsObjectivesView]"
             [isAnimated]="true"
             class="section-body">
            <div class="d-flex flex-column gap-4 gap-lg-5 section-body-content">
                <adapt-styled-dashboard-element *ngIf="views[DisplayViewKey.ObjectivesView] as view"
                                                [name]="view.text"
                                                [isCollapsible]="!isPrinting"
                                                [(collapsed)]="CollapseState[view.key]"
                                                [iconClass]="view.icon"
                                                [url]="view.hasPermission && !isPrinting ? (view.route | async) : undefined"
                                                [linkText]="view.routeText"
                                                [background]="!view.hasPermission"
                                                [contentCard]="!view.hasPermission"
                                                [class.section-objectives]="view.hasPermission">
                    <div *ngIf="view.hasPermission; else noObjectivesPermission"
                         class="split-entry">
                        <adapt-org-objectives-dashboard-element name="Annual objectives"
                                                                class="avoid-page-break-inside"
                                                                [objectiveType]="ObjectiveType.Annual"
                                                                (initialised)="viewInitialised(view.key)"></adapt-org-objectives-dashboard-element>
                        <adapt-org-objectives-dashboard-element name="Quarterly objectives"
                                                                class="avoid-page-break-inside"
                                                                [objectiveType]="ObjectiveType.Quarterly"
                                                                (initialised)="viewInitialised(view.key)"></adapt-org-objectives-dashboard-element>
                    </div>

                    <ng-template #noObjectivesPermission>
                        <ng-container *ngTemplateOutlet="workflowUnlock; context: {$implicit: view}"></ng-container>
                    </ng-template>
                </adapt-styled-dashboard-element>

                <adapt-styled-dashboard-element *ngIf="views[DisplayViewKey.StrategyGoalsView] as view"
                                                class="avoid-page-break-inside"
                                                [name]="view.text"
                                                [isCollapsible]="!isPrinting"
                                                [(collapsed)]="CollapseState[view.key]"
                                                [iconClass]="view.icon"
                                                [url]="view.hasPermission && !isPrinting ? (view.route | async) : undefined"
                                                [linkText]="view.routeText"
                                                [contentCard]="!view.hasPermission || !hasGoals">
                    <adapt-strategic-goals *ngIf="view.hasPermission; else noStrategyGoalsPermission"
                                           [isEditing]="false"
                                           [updateZoneExpandQueryParams]="false"
                                           (hasDefinedGoals)="hasGoals = $event"
                                           [expandGoals]="true"
                                           (initialised)="viewInitialised(view.key)"></adapt-strategic-goals>
                    <ng-template #noStrategyGoalsPermission>
                        <ng-container *ngTemplateOutlet="workflowUnlock; context: {$implicit: view}"></ng-container>
                    </ng-template>
                </adapt-styled-dashboard-element>
            </div>
        </div>
    </div>

    <div class="section-large avoid-page-break-inside">
        <h2 class="d-print-none"
            (click)="CollapseState[DisplayGroupKey.FoundationsView] = !CollapseState[DisplayGroupKey.FoundationsView]">
            Foundations
            <i *ngIf="!isPrinting"
               class="ms-auto fal {{CollapseState[DisplayGroupKey.FoundationsView] ? 'fa-chevron-down' : 'fa-chevron-up'}}"></i>
        </h2>
        <div [collapse]="CollapseState[DisplayGroupKey.FoundationsView]"
             [isAnimated]="true"
             class="section-body">
            <div class="d-flex flex-column gap-4 gap-lg-5 section-body-content">
                <adapt-styled-dashboard-element *ngIf="views[DisplayViewKey.StrategicAnchorsView] as view"
                                                class="avoid-page-break-inside"
                                                [name]="view.text"
                                                [isCollapsible]="!isPrinting"
                                                [(collapsed)]="CollapseState[view.key]"
                                                [iconClass]="view.icon"
                                                [url]="view.hasPermission && !isPrinting ? (view.route | async) : undefined"
                                                [linkText]="view.routeText"
                                                [contentCard]="!view.hasPermission || anchors.length === 0">
                    <adapt-strategic-anchors *ngIf="view.hasPermission; else noStrategicAnchorsPermissions"
                                             [anchors]="anchors"></adapt-strategic-anchors>
                    <ng-template #noStrategicAnchorsPermissions>
                        <ng-container *ngTemplateOutlet="workflowUnlock; context: {$implicit: view}"></ng-container>
                    </ng-template>
                </adapt-styled-dashboard-element>

                <div class="split-entry">
                    <adapt-styled-dashboard-element *ngIf="views[DisplayViewKey.VisionView] as view"
                                                    class="avoid-page-break-inside"
                                                    [name]="view.text"
                                                    [isCollapsible]="!isPrinting"
                                                    [(collapsed)]="CollapseState[view.key]"
                                                    [iconClass]="view.icon"
                                                    [url]="view.hasPermission && !isPrinting ? (view.route | async) : undefined"
                                                    [linkText]="view.routeText"
                                                    [contentCard]="true">
                        <ng-container *ngIf="view.hasPermission; else noVisionPermissions">
                            <div *ngIf="vision?.vision as visionContent; else noVisionTemplate"
                                 [froalaView]="visionContent"></div>
                            <ng-template #noVisionTemplate>No vision has been defined.</ng-template>
                        </ng-container>
                        <ng-template #noVisionPermissions>
                            <ng-container *ngTemplateOutlet="workflowUnlock; context: {$implicit: view}"></ng-container>
                        </ng-template>
                    </adapt-styled-dashboard-element>

                    <adapt-styled-dashboard-element *ngIf="views[DisplayViewKey.BullseyeView] as view"
                                                    class="avoid-page-break-inside"
                                                    [name]="view.text"
                                                    [isCollapsible]="!isPrinting"
                                                    [(collapsed)]="CollapseState[view.key]"
                                                    [iconClass]="view.icon"
                                                    [url]="bullseyeRoute | async"
                                                    [linkText]="view.routeText"
                                                    [contentCard]="true">
                        <ng-container
                                      *ngIf="view.hasPermission || (views[DisplayViewKey.VisionView].hasPermission && !isAlto); else noBullseyePermissions">
                            <div *ngIf="(view.hasPermission ? bullseye?.statement : vision?.bullseye) as bullseyeContent; else noBullseyeTemplate"
                                 [froalaView]="bullseyeContent"></div>
                            <ng-template #noBullseyeTemplate>No bullseye has been defined.</ng-template>
                        </ng-container>
                        <ng-template #noBullseyePermissions>
                            <ng-container *ngTemplateOutlet="workflowUnlock; context: {$implicit: view}"></ng-container>
                        </ng-template>
                    </adapt-styled-dashboard-element>
                </div>

                <div class="split-entry">
                    <adapt-styled-dashboard-element *ngIf="views[DisplayViewKey.PurposeView] as view"
                                                    class="avoid-page-break-inside"
                                                    [name]="view.text"
                                                    [isCollapsible]="!isPrinting"
                                                    [(collapsed)]="CollapseState[view.key]"
                                                    [iconClass]="view.icon"
                                                    [url]="view.hasPermission && !isPrinting ? (view.route | async) : undefined"
                                                    [linkText]="view.routeText"
                                                    [contentCard]="true">
                        <ng-container *ngIf="view.hasPermission; else noPurposePermissions">
                            <div *ngIf="purpose?.content as purposeContent; else noPurposeTemplate"
                                 [froalaView]="purposeContent"></div>
                            <ng-template #noPurposeTemplate>No purpose has been defined.</ng-template>
                        </ng-container>
                        <ng-template #noPurposePermissions>
                            <ng-container *ngTemplateOutlet="workflowUnlock; context: {$implicit: view}"></ng-container>
                        </ng-template>
                    </adapt-styled-dashboard-element>

                    <adapt-styled-dashboard-element *ngIf="views[DisplayViewKey.ValuesView] as view"
                                                    class="avoid-page-break-inside"
                                                    [name]="view.text"
                                                    [isCollapsible]="!isPrinting"
                                                    [(collapsed)]="CollapseState[view.key]"
                                                    [iconClass]="view.icon"
                                                    [url]="view.hasPermission && !isPrinting ? (view.route | async) : undefined"
                                                    [linkText]="view.routeText"
                                                    [contentCard]="true">
                        <adapt-values-constitution-list-view *ngIf="view.hasPermission; else noValuesPermission"
                                                             [showCTA]="false"
                                                             (initialised)="viewInitialised(view.key)"></adapt-values-constitution-list-view>
                        <ng-template #noValuesPermission>
                            <ng-container *ngTemplateOutlet="workflowUnlock; context: {$implicit: view}"></ng-container>
                        </ng-template>
                    </adapt-styled-dashboard-element>
                </div>
            </div>
        </div>
    </div>
</div>


import { Component, Inject } from "@angular/core";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseConfirmationDialogComponent } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/base-confirmation-dialog.component";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";

export interface IConfirmDeleteLabelData extends IConfirmationDialogData {
    label: Label
}

@Component({
    selector: "adapt-delete-label-dialog",
    templateUrl: "./delete-label-dialog.component.html",
    styleUrls: ["./delete-label-dialog.component.scss"],
})
export class DeleteLabelDialogComponent extends BaseConfirmationDialogComponent {
    public readonly dialogName = "DeleteLabel";

    public selectedDestination?: Label;
    public sourceItemCount = 0;
    public sourceKeyFunctionCount = 0;
    public sourceTeamCount = 0;
    public sourceObjectiveCount = 0;
    public sourceSystemCount = 0;
    public sourceProcessStepCount = 0;
    public sourceRoleCount = 0;
    public label!: Label;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IConfirmDeleteLabelData,
        private labellingService: LabellingService,
    ) {
        super(dialogData);

        this.label = dialogData.label;
        this.getLabelInfomation();

        dialogData.title = `Delete Label`;
        dialogData.confirmButtonText = "Delete & close";
    }

    private getLabelInfomation() {
        const labelLocation$ = this.labellingService.getLabelLocationsForLabel(this.label.labelId);

        labelLocation$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((labelLocations: LabelLocation[]) => {
            this.sourceItemCount = this.countDistinct(labelLocations.filter((l) => !!l.itemId).map((l) => l.itemId!));
            this.sourceKeyFunctionCount = this.countDistinct(labelLocations.filter((l) => !!l.keyFunctionId).map((l) => l.keyFunctionId!));
            this.sourceTeamCount = this.countDistinct(labelLocations.filter((l) => !!l.teamId).map((l) => l.teamId!));
            this.sourceObjectiveCount = this.countDistinct(labelLocations.filter((l) => !!l.objectiveId).map((l) => l.objectiveId!));
            this.sourceSystemCount = this.countDistinct(labelLocations.filter((l) => !!l.systemId).map((l) => l.systemId!));
            this.sourceProcessStepCount = this.countDistinct(labelLocations.filter((l) => !!l.processStepId).map((l) => l.processStepId!));
            this.sourceRoleCount = this.countDistinct(labelLocations.filter((l) => !!l.roleId).map((l) => l.roleId!));
        });
    }

    private countDistinct(idArrays: number[]) {
        return ArrayUtilities.distinct(idArrays).length;
    }

    public get hasImpact(): boolean {
        return (this.sourceItemCount + this.sourceKeyFunctionCount + this.sourceTeamCount + this.sourceObjectiveCount + this.sourceSystemCount + this.sourceProcessStepCount + this.sourceRoleCount) > 0;
    }
}

import { Component, ElementRef, Injector, TemplateRef, ViewChild } from "@angular/core";
import { IMeetingLocation, Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItemType } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaTemplate } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { CalendarIntegrationUtilities } from "@org-common/lib/calendar/calendar-integration-utilities";
import { IMeetingWorkflowCustomData } from "@org-common/lib/meetings/meeting-workflow-custom-data.interface";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { IWorkflowStepFooterTemplate, WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { IRunWorkshopCustomData, WorkflowMeetingService } from "@org-common/lib/workflow/workflow-meeting.service";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import moment from "moment";
import { BehaviorSubject, catchError, forkJoin, from, Observable, of, Subject, switchMap, tap, throwError } from "rxjs";
import { map } from "rxjs/operators";

export interface IScheduleMeetingStepData {
    /**
     * If defined and is a valid meeting template code, the meeting agenda items from the template will be
     * snapshot and copied into the meeting - if not, there won't be any meeting agenda item
     */
    meetingTemplateCode: string;

    /**
     * param of this is the customData from workflow, resultant object will be merged with { workflowConnectionId: number }
     * to set as the value for the meeting.customData field
     */
    meetingCustomData?: (workflowCustomData: any) => {};

    /**
     * set this to false if this step is part of a workflow that has a confirmation step after this
     * - so no need to be multi-staged
     * Not set -> false. I.e. part of a workflow with confirmation step after
     */
    showConfirmationAfterSchedule?: boolean;

    /**
     * article to show above the meeting info if confirmation is being shown.
     */
    confirmationArticleSlug?: ImplementationKitArticle;
}

@WorkflowStepComponent("adapt-schedule-meeting-step")
@Component({
    selector: "adapt-schedule-meeting-step",
    templateUrl: "./schedule-meeting-step.component.html",
})
export class ScheduleMeetingStepComponent extends WorkflowStepComponentAdapter {
    public static RUN_IMMEDIATELY = false;

    public meetingScheduled = false;
    public meeting?: Meeting;
    public meetingLocation?: IMeetingLocation;
    public hasConflicts = false;
    public sendInvitations = false;
    public createTeamsMeeting = false;
    public submitting = false;

    public workflowStepCompleted = new BehaviorSubject<boolean>(false);
    public workflowStepFinishCurrent = new Subject<boolean>();
    public workflowStepFooterTemplates = new Subject<IWorkflowStepFooterTemplate[]>();
    public stepCustomData?: IScheduleMeetingStepData;

    private workflowCustomData?: IRunWorkshopCustomData;
    private meetingAgendaTemplate?: MeetingAgendaTemplate;
    private calendarIntegrationUtilities: CalendarIntegrationUtilities;

    private entitiesToConfirm: IBreezeEntity[] = [];

    @ViewChild("buttonTemplate")
    public set buttonTemplate(ref: TemplateRef<any>) {
        this.workflowStepFooterTemplates.next([
            { alignment: "right", template: ref },
        ]);
    }

    public constructor(
        elementRef: ElementRef,
        injector: Injector,
        private dialogService: AdaptCommonDialogService,
        private meetingsService: MeetingsService,
        private teamsService: CommonTeamsService,
        private workflowMeetingService: WorkflowMeetingService,
        private workflowService: WorkflowService,
    ) {
        super(elementRef);
        this.calendarIntegrationUtilities = new CalendarIntegrationUtilities(injector);
    }

    public workflowStepOnInit() {
        if (this.workflowStep && this.workflowConnection) {
            if (!this.workflowStep.customData) {
                throw new Error("Expecting customData of type IScheduleMeetingStepData to be defined for this workflow step but not found");
            }

            this.stepCustomData = this.workflowStep?.customData as IScheduleMeetingStepData;
            forkJoin([
                this.workflowService.getWorkflowCustomData<IRunWorkshopCustomData>(this.workflowConnection),
                from(this.teamsService.promiseToGetLeadershipTeam()),
                this.meetingsService.getMeetingAgendaTemplateByCode(this.stepCustomData.meetingTemplateCode),
            ]).pipe(
                switchMap(([customData, leadershipTeam, meetingTemplate]) => {
                    if (!leadershipTeam) {
                        throw new Error("Person is expected to be connected to a leadership team to run this workflow");
                    }

                    if (!meetingTemplate) {
                        throw new Error(`Meeting template with Code=${this.stepCustomData!.meetingTemplateCode} does not exist`);
                    }

                    this.meetingAgendaTemplate = meetingTemplate;
                    this.workflowCustomData = customData;
                    if (this.workflowCustomData.meetingId) {
                        return this.meetingsService.getMeetingById(this.workflowCustomData.meetingId).pipe(
                            switchMap((meeting) => {
                                if (meeting) {
                                    this.meetingScheduled = true;
                                    this.workflowStepCompleted.next(true);
                                    return of(meeting);
                                } else {
                                    // linked meeting deleted -> recreate
                                    this.meetingScheduled = false;
                                    return this.meetingsService.createMeeting(leadershipTeam.teamId).pipe(
                                        tap((m) => m.name = meetingTemplate.name),
                                    );
                                }
                            }),
                        );
                    }

                    this.meetingScheduled = false;
                    return this.meetingsService.createMeeting(leadershipTeam.teamId).pipe(
                        tap((m) => m.name = meetingTemplate.name),
                    );
                }),
                // set the meeting end time to be approximately the duration of the agenda items
                switchMap((meeting) => meeting.entityAspect.entityState.isAdded() && this.meetingAgendaTemplate
                    ? this.updateMeetingDurationFromAgendaTemplate(meeting, this.meetingAgendaTemplate)
                    : of(meeting)),
                catchError((e) => {
                    this.workflowStepErrorMessage.next(e);
                    return throwError(() => new Error(e));
                }),
                this.takeUntilDestroyed(),
            ).subscribe((meeting) => this.meeting = meeting);
        }
    }

    @Autobind
    public runMeetingNow() {
        if (!this.meeting) {
            return of(undefined);
        }

        return this.meetingsService.getNonStartableMeetingInfo(this.meeting, true).pipe(
            switchMap((meetingInfo) => {
                if (meetingInfo) {
                    return this.dialogService.showErrorDialog("Cannot start meeting", meetingInfo);
                }

                ScheduleMeetingStepComponent.RUN_IMMEDIATELY = true;
                this.workflowStepFinishCurrent.next(true);
                return of(undefined);
            }),
        );
    }

    public workflowStepNextText() {
        return this.meetingScheduled ? "Close" : "Schedule meeting";
    }

    public onEntitiesChanged(entities: IBreezeEntity[]) {
        entities.forEach((ent) => this.emitEntityChange(ent));
        ArrayUtilities.addElementIfNotAlreadyExists(this.entitiesToConfirm, ...entities);
        this.validateEntities();
    }

    public onMeetingLocationChange(meetingLocation?: IMeetingLocation) {
        this.meetingLocation = meetingLocation;
    }

    public workflowStepNext(interruptShortcut: Subject<void>, promptForConflict = true): Observable<any> {
        if (this.meetingScheduled || !this.meeting) {
            return of(undefined);
        }

        if (this.sendInvitations && this.hasConflicts && promptForConflict && !ScheduleMeetingStepComponent.RUN_IMMEDIATELY) {
            return this.calendarIntegrationUtilities.promptForConflicts().pipe(
                switchMap((res) => {
                    if (!res) {
                        interruptShortcut.next();
                        return of(undefined);
                    }

                    // re-run the workflow next step
                    return this.workflowStepNext(interruptShortcut, false);
                }),
            );
        }

        this.submitting = true;

        return this.updateMeetingFromTemplate(this.meeting!, this.stepCustomData!).pipe(
            map((createdEntities) => ArrayUtilities.addElementIfNotAlreadyExists(this.entitiesToConfirm, ...createdEntities)),
            switchMap(() => this.meetingsService.saveEntities(this.entitiesToConfirm)),
            switchMap(() => !ScheduleMeetingStepComponent.RUN_IMMEDIATELY && this.sendInvitations
                ? this.calendarIntegrationUtilities.createOrUpdateProviderMeeting(this.meeting!, this.meetingLocation, this.createTeamsMeeting)
                : of(undefined)),
            switchMap(() => {
                if (!this.workflowCustomData) {
                    this.workflowCustomData = {};
                }

                this.workflowCustomData.meetingId = this.meeting?.meetingId;
                return this.workflowService.updateWorkflowCustomData(this.workflowConnection!, this.workflowCustomData);
            }),
            tap(() => {
                this.submitting = false;
                this.workflowStepErrorMessage.next(undefined);
                this.meetingScheduled = true;
                this.validateEntities();
                if (this.stepCustomData?.showConfirmationAfterSchedule || ScheduleMeetingStepComponent.RUN_IMMEDIATELY) {
                    // step won't move on
                    interruptShortcut.next();

                    if (ScheduleMeetingStepComponent.RUN_IMMEDIATELY && this.meeting) {
                        // passing true here allows the workflow to continue to the next one, even if continueOnFinish = false.
                        this.workflowStepFinishCurrent.next(true);
                    }
                }
            }),
            catchError((err) => {
                ScheduleMeetingStepComponent.RUN_IMMEDIATELY = false;
                this.submitting = false;
                const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(err);
                this.workflowStepErrorMessage.next(errorMessage);
                this.validateEntities();
                return throwError(() => new Error(errorMessage));
            }),
        );
    }

    private updateMeetingFromTemplate(meeting: Meeting, stepCustomData: IScheduleMeetingStepData) {
        if (!this.meetingAgendaTemplate) {
            return of([]);
        }

        let meetingCustomData = {
            workflowConnectionId: this.workflowConnection?.workflowConnectionId,
        } as IMeetingWorkflowCustomData;
        if (FunctionUtilities.isFunction(stepCustomData.meetingCustomData)) {
            const additionalData = stepCustomData.meetingCustomData(this.workflowCustomData);
            if (additionalData) {
                meetingCustomData = { ...additionalData, ...meetingCustomData };
            }
        }

        meeting.extensions.updateCustomData(meetingCustomData);
        return this.workflowMeetingService.createWorkflowMeeting(meeting, this.meetingAgendaTemplate);
    }

    private updateMeetingDurationFromAgendaTemplate(meeting: Meeting, template: MeetingAgendaTemplate) {
        return this.meetingsService.getAgendaItemsForMeetingAgendaTemplate(template.meetingAgendaTemplateId).pipe(
            tap((agendaItems) => {
                const time = agendaItems
                    .filter((item) => item.type !== MeetingAgendaItemType.PreWork)
                    .reduce((duration, item) => duration + (item.plannedDurationInMinutes ?? 0), 0);
                meeting.endTime = moment(meeting.meetingDateTime)
                    .add(time, "minutes")
                    .toDate();
                meeting.extensions.updateSelectedDuration();
            }),
            catchError(() => of(meeting)),
            map(() => meeting),
        );
    }

    private validateEntities() {
        this.workflowStepCompleted.next((this.meeting?.entityAspect?.validateEntity() ?? false)
            && this.meeting!.meetingAttendees.length > 0);
    }
}

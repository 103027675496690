import { Component } from "@angular/core";
import { IMeetingLocation } from "@common/ADAPT.Common.Model/organisation/meeting";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { of, Subject, switchMap } from "rxjs";
import { ScheduleMeetingWorkflowStepBaseComponent } from "../schedule-meeting-workflow-step-base.component";

@WorkflowStepComponent("adapt-schedule-meeting-schedule-step")
@Component({
    selector: "adapt-schedule-meeting-schedule-step",
    templateUrl: "./schedule-meeting-schedule-step.component.html",
})
export class ScheduleMeetingScheduleStepComponent extends ScheduleMeetingWorkflowStepBaseComponent {
    public constructor(private dialogService: AdaptCommonDialogService) {
        super();
    }

    public workflowStepNext(interruptShortcut: Subject<void>) {
        const organiserInfo = this.runData.organiserInfo;
        if (organiserInfo && !organiserInfo.isOrganiser) {
            return this.dialogService.openConfirmationDialogWithBoolean({
                title: "Calendar integration warning",
                message: `<p>Only the meeting organiser can update the integrated calendar entry. The organiser is the first person to have synced the meeting.</p>
                    <p>Any changes you make will not automatically update the calendars of attendees.</p>
                    <p>Please ask <b>${organiserInfo.name}</b> to sync the meeting once you have finished editing the meeting.</p>`,
                hideCancelButton: false,
            }).pipe(
                switchMap((res) => {
                    if (!res) {
                        interruptShortcut.next();
                    }

                    return of(undefined);
                }),
            );
        }

        if (this.runData.hasConflicts && this.runData.sendInvitations) {
            return this.dialogService.openConfirmationDialogWithBoolean({
                title: "Meeting Has Scheduling Conflicts",
                message: "<p>This meeting has scheduling conflicts.</p><p>Do you still want to continue?</p>",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                hideCancelButton: false,
            }).pipe(
                switchMap((res) => {
                    if (!res) {
                        interruptShortcut.next();
                    }

                    return of(undefined);
                }),
            );
        }

        return of(undefined);
    }

    public onMeetingLocationChange(meetingLocation?: IMeetingLocation) {
        this.runData.meetingLocation = meetingLocation;
        this.validateEntities();
    }
}

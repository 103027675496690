import { Component, Inject } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseConfirmationDialogComponent } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/base-confirmation-dialog.component";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";

export interface IConfirmDeleteObjectiveData extends IConfirmationDialogData {
    objective: Objective;
}

@Component({
    selector: "adapt-delete-objective-confirmation-dialog",
    templateUrl: "./delete-objective-confirmation-dialog.component.html",
    styleUrls: ["./delete-objective-confirmation-dialog.component.scss"],
})
export class DeleteObjectiveConfirmationDialogComponent extends BaseConfirmationDialogComponent {
    public readonly dialogName = "DeleteObjectiveConfirmation";

    public objective: Objective;
    public hasRelatedDeletion: boolean;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IConfirmDeleteObjectiveData,
    ) {
        super(dialogData);

        dialogData.title = "Delete Objective?";
        dialogData.confirmButtonText = "Delete";

        this.objective = dialogData.objective;
        this.hasRelatedDeletion = this.objective.hasKeyResults || this.objective.objectiveLinks.length > 0 ||
            this.objective.itemLinks.length > 0;
    }
}

<ng-container *ngIf="!user; else loggedIn">
    <div class="d-flex align-items-center">
        <div class="d-flex flex-column align-items-end integration-icon">
            <img src="/content/lib/oauth/microsoft-oauth/connect-with-microsoft/assets/outlook.svg"
                 alt="Microsoft Outlook icon"
                 height="32"
                 title="Microsoft 365" />
        </div>

        <button role="button"
                type="button"
                class="btn-connect-microsoft ms-4"
                [disabled]="disabled"
                [adaptBlockingClick]="login">
            <img src="/content/lib/oauth/microsoft-oauth/connect-with-microsoft/assets/logo-ms.svg"
                 alt="Microsoft logo " />
            Connect with Microsoft
        </button>
    </div>
</ng-container>

<ng-template #loggedIn>
    <div class="d-flex align-items-center">
        <img src="/content/lib/oauth/microsoft-oauth/connect-with-microsoft/assets/outlook.svg"
             class="me-2"
             height="32"
             alt="Microsoft Outlook icon"
             title="Microsoft 365" />

        <div *ngIf="user!.profilePicture"
             class="d-flex align-items-center me-2 user-image">
            <img [src]="user!.profilePicture"
                 width="32"
                 height="32"
                 alt="User image" />
        </div>

        <div class="d-flex flex-column user-details">
            <div>Signed in as <strong>{{user!.displayName}}</strong></div>
            <small class="text-muted">{{user!.email}}</small>
        </div>
    </div>

    <div *ngIf="showLogoutButton"
         class="mt-3">
        <button role="button"
                type="button"
                class="btn btn-primary"
                [disabled]="disabled"
                [adaptBlockingClick]="logout">Logout</button>
    </div>
</ng-template>

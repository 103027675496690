<div *ngIf="alwaysShown || (!calendarProvider || (showLocal || !calendarProvider.isLocal))"
     class="alert alert-info pt-2 d-flex gap-3 align-items-end">
    <div class="flex-grow-1">
        <strong class="d-block alert-heading mb-3">
            <a *ngIf="!linkDisabled && calendarProvider && !calendarProvider.isLocal && oauthService.user; else noConfigLink"
               class="text-reset"
               adaptTooltip="Click here to logout or switch accounts for this provider"
               [routerLink]="personalConfigRoute?.path"
               [queryParams]="{section: ConfigArea[ConfigArea.CalendarIntegration]}">Calendar integration</a>
            <ng-template #noConfigLink>Calendar integration</ng-template>
            <i *ngIf="!calendarProvider || !calendarProvider.isLocal"
               class="fal fa-info-circle ms-2"
               adaptTooltip="Meetings will be sent to your nominated calendar provider."></i>
        </strong>

        <p *ngIf="showTextPrompt && !oauthService.user">{{oauthService.getPromptText(true)}}</p>
        <div [ngClass]="{'d-flex justify-content-center': !oauthService.user}">
            <adapt-calendar-provider-select [disabled]="disabled"
                                            [showDisableIntegration]="false"></adapt-calendar-provider-select>
        </div>
    </div>

    <ng-container *ngIf="contentTemplate"
                  [ngTemplateOutlet]="contentTemplate"></ng-container>
</div>

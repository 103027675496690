import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CategoryWorkflowStatus, CategoryWorkflowStatuses } from "../workflow-map-dashboard-element/category-workflow-status.enum";

@Component({
    selector: "adapt-workflow-map-status-filter",
    templateUrl: "./workflow-map-status-filter.component.html",
})
export class WorkflowMapStatusFilterComponent {
    public readonly CategoryWorkflowMapFilters = CategoryWorkflowStatuses;

    @Input() public hiddenStatuses: CategoryWorkflowStatus[] = [];
    @Output() public hiddenStatusesChange = new EventEmitter<CategoryWorkflowStatus[]>();

    public toggleFilter(filter: CategoryWorkflowStatus) {
        this.hiddenStatuses = this.hiddenStatuses.includes(filter)
            ? this.hiddenStatuses.filter((i) => i !== filter)
            : [...this.hiddenStatuses, filter];

        this.hiddenStatusesChange.emit(this.hiddenStatuses);
    }
}

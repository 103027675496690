import { Component, Inject } from "@angular/core";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaTemplate } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import DataSource from "devextreme/data/data_source";
import { lastValueFrom, timer } from "rxjs";
import { finalize, map, tap } from "rxjs/operators";
import { MeetingsService } from "../meetings.service";

@Component({
    selector: "adapt-import-from-agenda-template-dialog",
    templateUrl: "./import-from-agenda-template-dialog.component.html",
    styleUrls: ["./import-from-agenda-template-dialog.component.scss"],
})
export class ImportFromAgendaTemplateDialogComponent extends BaseDialogComponent<boolean, MeetingAgendaItem[]> {
    public readonly dialogName = "ImportFromAgendaTemplateDialog";

    public selectedTemplate?: MeetingAgendaTemplate;
    public dataSource?: DataSource;

    public itemsFromSelectedTemplate: MeetingAgendaItem[] = [];
    public loadingItems = false;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public saveOnClose: boolean,
        private meetingsService: MeetingsService,
    ) {
        super();

        this.dataSource = new DataSource({
            key: "meetingAgendaTemplateId",
            loadMode: "raw",
            load: () => {
                return lastValueFrom(meetingsService.getMeetingAgendaTemplates().pipe(
                    // we don't want the template we're currently creating to show up here...
                    map((templates) => templates.filter((t) => t.entityAspect.entityState.isUnchanged())),
                ));
            },
            group: (template: MeetingAgendaTemplate) => {
                if (template.team) {
                    return `Team - ${template.team.name}`;
                }
                return "Organisation";
            },
        });
    }

    public templateSelected(template?: MeetingAgendaTemplate) {
        if (!template) {
            return;
        }

        this.selectedTemplate = template;
        this.loadingItems = true;
        this.meetingsService.getAgendaItemsForMeetingAgendaTemplate(template.meetingAgendaTemplateId).pipe(
            this.takeUntilDestroyed(),
            finalize(() => this.loadingItems = false),
        ).subscribe((items) => this.itemsFromSelectedTemplate = items);
    }

    @Autobind
    public importItemsFromTemplate() {
        return timer(0).pipe(
            tap(() => this.resolve(this.itemsFromSelectedTemplate)),
        );
    }
}

import { AfterViewInit, Component, Injector, OnInit, ViewChild } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { ElementUtilities } from "@common/lib/utilities/element-utilities";
import { IGroupedData } from "@common/lib/utilities/grouped-data.interface";
import { PromiseUtilities } from "@common/lib/utilities/promise-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IDxListItemIndex, IDxListSelectionChangedEvent } from "@common/ux/dx.types";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import DataSource from "devextreme/data/data_source";
import dxList, { GroupRenderedEvent } from "devextreme/ui/list";
import dxScrollView from "devextreme/ui/scroll_view";
import { InitializedEvent, ValueChangedEvent } from "devextreme/ui/text_box";
import { DxScrollViewComponent } from "devextreme-angular";
import moment from "moment";
import { BehaviorSubject, forkJoin, from, interval, merge, of, Subject } from "rxjs";
import { debounceTime, delay, filter, first, switchMap, tap } from "rxjs/operators";
import { MeetingsService } from "../meetings.service";
import { MeetingsUiService } from "../meetings-ui.service";

const MeetingIdQueryParam = "meetingId";

export interface IMeetingGroupKey {
    label: string;
    openByDefault: boolean;
    // toString is needed for dx to sort the groups appropriately
    toString: () => string;
}

export interface IMeetingGroupItem {
    key: IMeetingGroupKey;
    meeting: Meeting;
}

@Component({
    selector: "adapt-team-meetings-page",
    styleUrls: ["./team-meetings-page.component.scss"],
    templateUrl: "./team-meetings-page.component.html",
})
export class TeamMeetingsPageComponent extends BaseRoutedComponent implements OnInit, AfterViewInit {
    public team?: Team;
    public selectedMeeting?: Meeting;
    public updateData$ = new BehaviorSubject<void>(undefined);
    public meetingListSelectionChanged$ = new BehaviorSubject<void>(undefined);
    public meetingsFetched$ = new Subject<void>();
    public ongoingMeetingList?: dxList;
    public upcomingMeetingList?: dxList;
    public endedMeetingList?: dxList;
    public canEditTeamMeeting = false;

    public upcomingMeetings?: DataSource<IMeetingGroupItem>;
    public endedMeetings?: DataSource<IMeetingGroupItem>;
    public ongoingMeetings: Meeting[] = [];

    public upcomingMeetingsRaw: Meeting[] = [];
    public endedMeetingsRaw: Meeting[] = [];

    protected teamId?: number;
    private focusMeetingId?: number;

    public selectedMeetingElement?: HTMLElement;
    private isUpdatingSelection = false;

    private meetingListScrollView = new QueuedCaller<dxScrollView>();
    @ViewChild("meetingListScrollView") public set meetingListScrollViewComponent(value: DxScrollViewComponent) {
        if (value) {
            this.meetingListScrollView.setCallee(value.instance);
        }
    }

    // used for controlling collapse for groups
    private groupHasBeenRendered = new Map<IMeetingGroupKey, boolean>();

    public constructor(
        private teamsService: CommonTeamsService,
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
        rxjsBreezeService: RxjsBreezeService,
        injector: Injector,
    ) {
        super(injector);

        merge(
            rxjsBreezeService.entityTypeChanged(Meeting),
            this.meetingsService.meetingForMeetingAttendeeChangedForCurrentPerson$,
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((meeting) => {
            // meeting undefined if you no longer has access to the meeting and you were the attendee -> trigger update
            if (!meeting || meeting.teamId === this.teamId) {
                this.updateData$.next();
            }
        });
    }

    public get hasMeeting() {
        return this.endedMeetingsRaw.length > 0
            || this.upcomingMeetingsRaw.length > 0
            || this.ongoingMeetings.length > 0;
    }

    public ngAfterViewInit() {
        // need to wait for the contents of the meeting cards to be loaded or you can't tell if there is a scrollbar or not
        this.meetingListScrollView.call((scrollView) => interval(500).pipe(
            filter(() => GuidedTourUtils.isElementVisible(jQuery(scrollView.element()).get(0))),
            first(),
            tap(() => this.scrollToSelectedMeeting(scrollView)),
            this.takeUntilDestroyed(),
        ).subscribe());
    }

    public ngOnInit() {
        this.updateFromPageParams();
        this.navigationEnd.subscribe(() => this.updateFromPageParams());

        this.meetingsService.meetingIdChange$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            if (!this.focusMeetingId) {
                this.captureFocusMeetingId();
                this.updateData$.next();
            }
        });

        this.updateData$.pipe(
            debounceTime(100), // limit number of refresh from consecutive updates
            switchMap(() => forkJoin([
                this.teamsService.getTeamById(this.teamId!),
                this.meetingsService.getOngoingMeetingsForTeam(this.teamId!),
                this.meetingsService.getUpcomingMeetingsForTeam(this.teamId!),
                this.meetingsService.getEndedMeetingsForTeam(this.teamId!),
                this.meetingsService.primeAgendaItemsForNotStartedMeeting(this.teamId!),
                this.meetingsService.primeAttendeesForNotStartedMeeting(this.teamId!),
            ])),
            this.takeUntilDestroyed(),
        ).subscribe(([team, ongoingMeetings, upcomingMeetings, endedMeetings]) => {
            this.team = team;
            this.canEditTeamMeeting = this.meetingsService.canEditMeetingForTeam(team!);
            this.ongoingMeetings = ongoingMeetings;

            const endedMeetingsGrouped = this.groupMeetingsByDatePeriod(endedMeetings, false);
            const upcomingMeetingsGrouped = this.groupMeetingsByDatePeriod(upcomingMeetings, true);

            this.endedMeetingsRaw = endedMeetings;
            this.endedMeetings = new DataSource({
                store: endedMeetingsGrouped,
                group: (item: IMeetingGroupItem) => item.key,
                sort: [
                    { selector: "meeting.meetingDateTime", desc: true },
                ],
            });

            this.upcomingMeetingsRaw = upcomingMeetings;
            this.upcomingMeetings = new DataSource({
                store: upcomingMeetingsGrouped,
                group: (item: IMeetingGroupItem) => item.key,
                sort: [
                    "meeting.meetingDateTime",
                ],
            });

            this.groupHasBeenRendered.clear();

            if (this.focusMeetingId) {
                this.selectedMeeting = ongoingMeetings
                    .concat(upcomingMeetings)
                    .concat(endedMeetings)
                    .find((m) => m.meetingId === this.focusMeetingId);
            }

            if (!this.selectedMeeting) {
                if (ongoingMeetings.length > 0) {
                    this.selectedMeeting = ongoingMeetings[0];
                } else if (upcomingMeetings.length > 0) {
                    this.selectedMeeting = upcomingMeetings[0];
                } else if (endedMeetings.length > 0) {
                    this.selectedMeeting = endedMeetings[0];
                }

                if (this.selectedMeeting) {
                    this.selectMeeting(this.selectedMeeting);
                }
            }

            // notify any subscribers (e.g. our cadence page)
            this.meetingsFetched$.next();
            this.meetingListSelectionChanged$.next();

            // need to reapply this, as going from no meeting to having a meeting causes the CTA to disappear
            // and therefore reset shell padding
            this.removeDefaultShellPadding();

            // cannot update the list selection here as dxList not yet picking up dataSource changes, will have to wait till content ready
            this.notifyActivated();
        });
    }

    private updateFromPageParams() {
        this.teamId = this.getRouteParamInt("teamId");
        if (!this.teamId) {
            return this.handleUnauthorisedAccess();
        }

        this.verifyHasAccessToRoute(this.meetingsService.canViewTeamMeetings(this.teamId));
        this.captureFocusMeetingId();

        this.updateData$.next();
    }

    @Autobind
    public createTeamMeeting() {
        return this.meetingsUiService.createMeeting(this.teamId!).pipe(
            delay(0),
            tap((newMeeting) => {
                this.selectMeeting(newMeeting);
                this.updateData$.next();
            }),
        );
    }

    public onMeetingDeleted() {
        this.selectMeeting(undefined);
        this.updateData$.next();
    }

    public onSelectionChanged(e: IDxListSelectionChangedEvent<Meeting | IMeetingGroupItem>) {
        if (e.addedItems.length > 0) {
            if (!this.isUpdatingSelection) {
                // selection from Ux
                const item = e.addedItems[0];
                this.selectMeeting(item instanceof Meeting ? item : item.meeting);
            }

            if (this.ongoingMeetingList && this.ongoingMeetingList !== e.component) {
                this.ongoingMeetingList.unselectAll();
            }

            if (this.upcomingMeetingList && this.upcomingMeetingList !== e.component) {
                this.upcomingMeetingList.unselectAll();
            }

            if (this.endedMeetingList && this.endedMeetingList !== e.component) {
                this.endedMeetingList.unselectAll();
            }

            // notify any subscribers (e.g. our cadence page)
            this.meetingListSelectionChanged$.next();
        }
    }

    public updateMeetingListSelection() {
        if (this.selectedMeeting) {
            // there is already a selected meeting and we want to update the selection in the UI
            // - setting this flag so that we won't be updating selectedMeeting again from the callback from the subsequent selectItem.
            this.isUpdatingSelection = true;
            if (this.selectedMeeting.extensions.isInProgress) {
                this.selectAndScrollToMeetingInList(this.ongoingMeetings, this.ongoingMeetingList);
            } else if (this.selectedMeeting.extensions.isNotStarted && this.upcomingMeetings) {
                this.selectAndScrollToMeetingInList(this.upcomingMeetings, this.upcomingMeetingList);
            } else if (this.selectedMeeting.extensions.isEnded && this.endedMeetings) {
                this.selectAndScrollToMeetingInList(this.endedMeetings, this.endedMeetingList);
            }
            this.isUpdatingSelection = false;
        }
    }

    public onGroupRendered(event: GroupRenderedEvent) {
        const group = event.groupData as IGroupedData<IMeetingGroupKey, IMeetingGroupItem>;
        if (!this.groupHasBeenRendered.has(group.key)) {
            // handle scrolling to the group header when expanding the group
            if (event.groupElement) {
                jQuery(event.groupElement)
                    .find(".dx-list-group-header")
                    .on("click", (e) => {
                        const isCollapsed = e.currentTarget.parentElement?.classList.contains("dx-list-group-collapsed");
                        if (isCollapsed) {
                            // wait for the group to expand first
                            setTimeout(() => {
                                // scroll to fit the group in when expanding
                                this.meetingListScrollView.call((scrollViewInstance) => {
                                    this.scrollDxScrollViewToElement(scrollViewInstance, e.currentTarget);
                                });
                            }, 250);
                        }
                    });
            }

            if (!group.key.openByDefault && event.groupIndex) {
                event.component.collapseGroup(event.groupIndex);
            }
            this.groupHasBeenRendered.set(group.key, true);
        }
    }

    @Autobind
    public onEndedMeetingsListSearchInitialised(event: InitializedEvent) {
        // can't use event binding for this, and if we do [onValueChanged] then the search doesn't work...
        event.component?.on("valueChanged", (e: ValueChangedEvent) => {
            // the search box has been cleared, we should collapse all the groups as default
            if (!e.value && this.endedMeetingList) {
                // need to wait as the items will still be filtered by the search
                setTimeout(() => {
                    const groups = this.endedMeetingList!.getDataSource().items() as IGroupedData<IMeetingGroupKey, IMeetingGroupItem>[];
                    for (const group of groups) {
                        if (!group.key.openByDefault) {
                            // don't collapse the group for the currently selected item
                            const selectedItemIsInGroup = group.items.find((i) => i.meeting === this.selectedMeeting);
                            if (!selectedItemIsInGroup) {
                                this.endedMeetingList!.collapseGroup(groups.indexOf(group));
                            }
                        }
                    }
                }, 200);
            }
        });
    }

    protected selectMeeting(meeting?: Meeting) {
        this.selectedMeeting = meeting;
        this.focusMeetingId = this.selectedMeeting?.meetingId;
        this.routeService.updateSearchParameterValue(MeetingIdQueryParam, this.focusMeetingId);
    }

    private selectAndScrollToMeetingInList(targetMeetingList: Meeting[] | DataSource<IMeetingGroupItem>, targetMeetingDxList?: dxList) {
        if (!this.selectedMeeting || !targetMeetingDxList) {
            return;
        }

        let selectionIndex: IDxListItemIndex | undefined;

        // if the first element is a meeting, then it must not be grouped
        if (targetMeetingList instanceof DataSource) {
            const groupedItems = targetMeetingList.items() as IGroupedData<IMeetingGroupKey, IMeetingGroupItem>[];
            const sectionGroup = groupedItems.find((group) =>
                group.items.find((i) => i.meeting === this.selectedMeeting!));
            if (sectionGroup) {
                const group = groupedItems.indexOf(sectionGroup);
                const item = sectionGroup.items.findIndex((i) => i.meeting === this.selectedMeeting);
                selectionIndex = { group, item };
            }
        } else {
            selectionIndex = (targetMeetingList as Meeting[]).indexOf(this.selectedMeeting);
        }

        if (selectionIndex !== undefined) {
            setTimeout(async () => {
                if (selectionIndex instanceof Object) {
                    // expand the group for the selected item so we can actually see it
                    targetMeetingDxList.expandGroup(selectionIndex.group);

                    // wait for group to expand
                    await PromiseUtilities.wait(200);
                }

                targetMeetingDxList.selectItem(selectionIndex);

                this.meetingListScrollView.call((scrollView) => this.scrollToSelectedMeeting(scrollView));
            }, 200);
        }
    }

    private scrollToSelectedMeeting(scrollViewInstance: dxScrollView) {
        // need to wait for the contents of the meeting cards to be loaded or you can't tell if there is a scrollbar or not
        from(scrollViewInstance.update()).pipe(
            switchMap(() => {
                if (!this.selectedMeetingElement) {
                    return of(undefined);
                }

                return interval(200).pipe( // a bit of delay after scrollview update() before start scrolling or scrollbar will be reset
                    filter(() => GuidedTourUtils.isElementVisible(this.selectedMeetingElement)),
                    first(),
                    tap(() => {
                        const selectedMeetingElementBoundingRect = this.selectedMeetingElement?.getBoundingClientRect();
                        const scrollViewElementBoundingRect = jQuery(scrollViewInstance.element()).get(0)?.getBoundingClientRect();
                        if (selectedMeetingElementBoundingRect && scrollViewElementBoundingRect) {
                            // only scroll if the meeting isn't fully visible
                            if (!ElementUtilities.isElementVerticallyWithinContainerBounds(scrollViewElementBoundingRect, selectedMeetingElementBoundingRect)) {
                                this.scrollDxScrollViewToElement(scrollViewInstance, this.selectedMeetingElement!);
                            }
                        }
                    }),
                );
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    private scrollDxScrollViewToElement(scrollViewInstance: dxScrollView, element: Element, offset = 64) {
        const elementBounds = element.getBoundingClientRect();
        const scrollElement = jQuery(scrollViewInstance.element()).get(0);
        if (scrollElement) {
            const scrollElementBounds = scrollElement.getBoundingClientRect();
            const scrollContainerElement = scrollElement.querySelector(".dx-scrollable-container");
            // scrolling the container ourselves so we can use smooth scrolling
            // see: https://supportcenter.devexpress.com/ticket/details/t1092068/scrollview-how-to-animate-scrolling
            scrollContainerElement?.scrollBy({ top: elementBounds.top - scrollElementBounds.top - offset, behavior: "smooth" });
        }
    }

    private captureFocusMeetingId() {
        const meetingIdValue = this.getSearchParameterValue(MeetingIdQueryParam);
        if (meetingIdValue) {
            this.focusMeetingId = Number(meetingIdValue);
            if (isNaN(this.focusMeetingId)) {
                this.focusMeetingId = undefined;
                this.routeService.deleteSearchParameter(MeetingIdQueryParam);
                // location.replace();
            }
        }
    }

    private groupMeetingsByDatePeriod(meetings: Meeting[], isUpcomingMeetings = true) {
        const multiplier = isUpcomingMeetings ? 1 : -1;

        const currentMonth = moment().startOf("month");
        const lastMonth = moment(currentMonth).add(-1 * multiplier, "month");
        const nextMonth = moment(currentMonth).add(1 * multiplier, "month");
        const threeMonthsAway = moment(nextMonth).add(1 * multiplier, "month");
        const sixMonthsAway = moment(threeMonthsAway).add(3 * multiplier, "month");
        const oneYearAway = moment(sixMonthsAway).add(6 * multiplier, "month");

        const groupKeyLastMonth: IMeetingGroupKey = {
            label: lastMonth.format("MMMM YYYY"),
            openByDefault: true,
            // put last month after this month if looking at ended meetings
            toString: () => `${isUpcomingMeetings ? 0 : 1}_last_month`,
        };
        const groupKeyThisMonth: IMeetingGroupKey = {
            label: currentMonth.format("MMMM YYYY"),
            openByDefault: true,
            // put this month before last month if looking at ended meetings
            toString: () => `${isUpcomingMeetings ? 1 : 0}_current_month`,
        };
        const groupKeyNextMonth: IMeetingGroupKey = {
            label: nextMonth.format("MMMM YYYY"),
            openByDefault: true,
            toString: () => "2_next_month",
        };
        const groupKeyWithinThreeToSixMonths: IMeetingGroupKey = {
            label: "Within 3 to 6 months",
            openByDefault: false,
            toString: () => "3_within_3_to_6_months",
        };
        const groupKeyWithinSixToTwelveMonths: IMeetingGroupKey = {
            label: "Within 6 months to 1 year",
            openByDefault: false,
            toString: () => "4_within_6_months_to_1_year",
        };
        const groupKeyOneYearOnwards: IMeetingGroupKey = {
            label: "1 year onwards",
            openByDefault: false,
            toString: () => "5_1_year_onwards",
        };

        // catch all group for meetings that don't fit the above groups
        const groupKeyPast: IMeetingGroupKey = {
            label: isUpcomingMeetings
                ? "Meetings past the scheduled date"
                : "In the future",
            openByDefault: false,
            toString: () => "6_past",
        };

        const grouping: IMeetingGroupItem[] = [];
        for (const meeting of meetings) {
            const dateTimeMoment = moment(meeting.meetingDateTime);

            const isCurrentMonth = dateTimeMoment.isSame(currentMonth, "month");
            const isLastMonth = dateTimeMoment.isSame(lastMonth, "month");
            const isNextMonth = dateTimeMoment.isSame(nextMonth, "month");

            // need to swap the order for isBetween. the first date must always be before the second date
            const isWithinThreeToSixMonths = isUpcomingMeetings
                ? dateTimeMoment.isBetween(threeMonthsAway, sixMonthsAway, "month", "[]")
                : dateTimeMoment.isBetween(sixMonthsAway, threeMonthsAway, "month", "[]");
            const isWithinSixToTwelveMonths = isUpcomingMeetings
                ? dateTimeMoment.isBetween(sixMonthsAway, oneYearAway, "month", "[]")
                : dateTimeMoment.isBetween(oneYearAway, sixMonthsAway, "month", "[]");

            const isAfterOneYear = isUpcomingMeetings
                ? dateTimeMoment.isAfter(oneYearAway)
                : dateTimeMoment.isBefore(oneYearAway);

            let key = groupKeyPast;
            if (isCurrentMonth) {
                key = groupKeyThisMonth;
            } else if (isLastMonth) {
                key = groupKeyLastMonth;
            } else if (isNextMonth) {
                key = groupKeyNextMonth;
            } else if (isWithinThreeToSixMonths) {
                key = groupKeyWithinThreeToSixMonths;
            } else if (isWithinSixToTwelveMonths) {
                key = groupKeyWithinSixToTwelveMonths;
            } else if (isAfterOneYear) {
                key = groupKeyOneYearOnwards;
            }

            grouping.push({ key, meeting });
        }

        // sort by group key
        grouping.sort(SortUtilities.getSortByFieldFunction((g) => g.key.toString()));

        if (!isUpcomingMeetings) {
            grouping.reverse();
        }

        return grouping;
    }
}

import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Account, AccountBreezeModel, SubscriptionSubStatus } from "@common/ADAPT.Common.Model/account/account";
import { PricingModelBreezeModel } from "@common/ADAPT.Common.Model/embed/pricing-model";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { ServiceUri } from "@common/configuration/service-uri";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { BaseService } from "@common/service/base.service";
import { IBannerSpec } from "@common/shell/shell.interface";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { CancelSubscriptionDialogComponent } from "@org-common/lib/configuration/organisation/cancel-subscription-dialog/cancel-subscription-dialog.component";
import moment from "moment";
import { EMPTY, merge, Subject } from "rxjs";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { OrganisationService } from "../organisation.service";
import { ISetInitialSubscriptionDialogData, SetInitialSubscriptionDialogComponent } from "./set-initial-subscription-dialog/set-initial-subscription-dialog.component";

@Injectable({
    providedIn: "root",
})
export class AccountService extends BaseService {
    private readonly subscriptionBanner: IBannerSpec = {
        class: "account-subscription-banner",
        text: "",
        buttonText: "Start subscription",
        buttonAction: () => this.showSubscriptionDialog().subscribe(),
        isDismissible: true,
    };

    private accountStatusUpdated$ = new Subject<void>();
    private firstPaymentReceived$ = new Subject<boolean>();

    constructor(
        injector: Injector,
        private authorisationService: AuthorisationService,
        private authorisationNotificationService: AuthorisationNotificationService,
        private httpClient: HttpClient,
        private shellUiService: ShellUiService,
        private commonDialogService: AdaptCommonDialogService,
        private organisationService: OrganisationService,
        private userService: UserService,
    ) {
        super(injector);
    }

    public get accountStatusChanged$() {
        return this.accountStatusUpdated$.asObservable();
    }

    public get firstSubscriptionPaymentReceived$() {
        return this.firstPaymentReceived$.asObservable();
    }

    public addAccountBanner() {
        // remove the banner when logging out
        this.organisationService.organisationEntityUpdated$.subscribe((org) => {
            if (!org) {
                this.shellUiService.removeBanner(this.subscriptionBanner);
            }
        });

        // need authorisationChanged$ for promiseToGetHasAccess to work as expected
        merge(
            this.authorisationNotificationService.authorisationChanged$,
            this.accountStatusUpdated$,
        ).pipe(
            switchMap(() => this.authorisationService.promiseToGetHasAccess(ConfigurationAuthService.ConfigureOrganisationBilling)),
            filter((hasAccess) => hasAccess),
            switchMap(() => this.getAccount()),
            tap((account) => {
                this.shellUiService.removeBanner(this.subscriptionBanner);

                if (!account
                    // account must be one of: trial, inactive, cancelled
                    || (!account.extensions.isTrial && !account.extensions.isInactive && !account.extensions.isPendingCancellation)) {
                    return;
                }

                const now = moment();
                const nextInvoiceDate = moment(account.nextSubscriptionInvoiceDate);
                const ended = account.extensions.isInactive || now.isAfter(nextInvoiceDate);

                const days = nextInvoiceDate.diff(now, "days", true);
                const roundedDays = Math.round(days);
                const dayText = roundedDays === 1 ? "day" : "days";

                // if they've cancelled, or have ever subscribed before, say "Resume" instead.
                this.subscriptionBanner.buttonText = account.extensions.isPendingCancellation || account.subStatus === SubscriptionSubStatus.SubscriptionCancelled
                    ? "Resume subscription"
                    : "Start subscription";

                if (ended) {
                    if (account.extensions.isZeroDayTrial) {
                        this.subscriptionBanner.text = `To grow your business, start your ${AdaptClientConfiguration.AdaptProjectLabel} subscription now!`;
                    } else {
                        this.subscriptionBanner.text = account.extensions.isTrial || account.extensions.isExpiredTrial
                            ? `The trial period for ${AdaptClientConfiguration.AdaptProjectLabel} has ended. To continue growing your business, start your subscription now!`
                            : `Your subscription for ${AdaptClientConfiguration.AdaptProjectLabel} has ended.`;
                    }
                } else if (account.extensions.isPendingCancellation) {
                    // no need to show the banner at this point if there is a lot of time remaining on their cancelled sub
                    if (days > 31) {
                        return;
                    }

                    const timeSuffix = days < 1
                        ? `today`
                        : `in ${roundedDays} ${dayText}`;
                    this.subscriptionBanner.text = `Your subscription for ${AdaptClientConfiguration.AdaptProjectLabel} ends ${timeSuffix}.`;
                } else {
                    const message = `Start your subscription now to continue access to ${AdaptClientConfiguration.AdaptProjectLabel} after the trial ends.`;
                    this.subscriptionBanner.text = days < 1
                        ? `Your free trial is ending today. ${message}`
                        : `You have ${roundedDays} ${dayText} remaining on your free trial. ${message}`;
                }

                this.shellUiService.addBanner(this.subscriptionBanner);
            }),
            switchMap((account) => account?.extensions.isZeroDayTrial
                ? this.showSubscriptionDialog(!(this.userService.currentPerson?.isCoach() || this.userService.currentPerson?.isStakeholderManager))
                : EMPTY),
        ).subscribe();
    }

    public getAccount() {
        const key = "allAccountsPrimed";
        return this.commonDataService.getWithOptions(AccountBreezeModel, key, {
            navProperty: "currency, eulaPerson, pricingModel.pricingModelUsers",
            forceRemote: true,
        }).pipe(
            map((accounts) => ArrayUtilities.getSingleFromArray(accounts)),
        );
    }

    public getPricingModels() {
        return this.commonDataService.getAll(PricingModelBreezeModel);
    }

    public forceUpdateAccount(account: Account) {
        return this.commonDataService.getById(AccountBreezeModel, account.accountId, true);
    }

    @Autobind
    public showSubscriptionDialog(disallowCancel = false) {
        return this.getAccount().pipe(
            // skip the start sub dialog if they are a cancelled subscription
            switchMap((account) => this.commonDialogService.open(
                SetInitialSubscriptionDialogComponent,
                { account, disallowCancel } as ISetInitialSubscriptionDialogData).pipe(
                    map((originalStatus) => ({ account, originalStatus })),
                )),
            switchMap(({ account, originalStatus }) => {
                const commenceMessage = account?.extensions.isFree
                    ? "<p>You have activated your subscription. No charges have been made to your credit card.</p>"
                    : "<p>Your payment has been received and a receipt has been sent to your email.</p>";
                // subscription is resumed if originally cancelled and the invoice date is in the future.
                const isResumingSubscription = account && account.extensions.canResumeSubscriptionWithoutCharge(originalStatus);
                let message = isResumingSubscription
                    ? `<p>You have resumed your subscription to ${AdaptClientConfiguration.AdaptProjectLabel}. Confirmation has been sent to your email.</p>`
                    : commenceMessage;
                message += "<p>We're looking forward to helping your business get to the next level!</p>";
                return this.commonDialogService.showMessageDialog("Congratulations!", message);
            }),
            // Do this after the above message dialog is dismissed or that dialog will be dismissed by the emit.
            // This subscription dialog will now be spawn from the subscription in addAccountBanner() for 0 day trial,
            // it will switchMap to showSubscriptionDialog. The followings emits will restart
            // the observable chain, switching back to the banner check, which will cancel the message dialog.
            // Is better to re-emit after the confirmation dialog closed anyway - giving user the context of where this is from,
            // i.e. banner still appearing -> need subscription -> that's why we are subscribing and hence this confirmation dialog.
            // After closing that message dialog, the permissions will be refreshed and banner gone.
            tap(() => this.authorisationNotificationService.refreshPermissions()),
            tap(() => {
                this.accountStatusUpdated$.next();
                if (disallowCancel) {
                    this.firstPaymentReceived$.next(true);
                }
            }),
        );
    }

    @Autobind
    public cancelSubscriptionDialog() {
        return this.getAccount().pipe(
            switchMap((account) => this.commonDialogService.open(CancelSubscriptionDialogComponent, account)),
            switchMap((result) => this.cancelSubscription(this.organisationService.getOrganisationId(), result.reason, result.freeText)),
            switchMap(() => this.commonDialogService.showMessageDialog("Success", "You have cancelled your subscription. You will receive an email confirming this.", "OK")),
            catchError((e) => this.commonDialogService.showErrorDialog("Failed To Save", ErrorHandlingUtilities.getHttpResponseMessage(e))),
            tap(() => this.accountStatusUpdated$.next()),
            switchMap(() => this.getAccount()),
        );
    }

    private cancelSubscription(organisationId: number, reason: string, freeText: string) {
        const uri = `${ServiceUri.MethodologyServicesServiceBaseUri}/CancelSubscription`;
        return this.httpClient.post(uri, null, {
            params: { organisationId, reason, freeText },
        });
    }
}

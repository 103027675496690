import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptRenderComponentModule } from "@common/ux/render-component/render-component.module";
import { ConnectWithMicrosoftComponent } from "../oauth/microsoft-oauth/connect-with-microsoft/connect-with-microsoft.component";
import { MicrosoftAuthService } from "../oauth/microsoft-oauth/microsoft-auth.service";
import { AdaptMicrosoftOAuthModule } from "../oauth/microsoft-oauth/microsoft-oauth.module";
import { provideCalendarProvider } from "./calendar.interface";
import { CalendarProviderSelectComponent } from "./calendar-provider-select/calendar-provider-select.component";
import { LinkExternalMeetingComponent } from "./link-external-meeting/link-external-meeting.component";
import { ConnectLocalCalendarIntegrationComponent } from "./local-calendar-integration/connect-local-calendar-integration/connect-local-calendar-integration.component";
import { AdaptLocalCalendarIntegrationModule } from "./local-calendar-integration/local-calendar-integration.module";
import { LocalCalendarIntegrationAuthService } from "./local-calendar-integration/local-calendar-integration-auth.service";
import { ConnectNoCalendarIntegrationComponent } from "./no-calendar-integration/connect-no-calendar-integration/connect-no-calendar-integration.component";
import { AdaptNoCalendarIntegrationModule } from "./no-calendar-integration/no-calendar-integration.module";

@NgModule({
    imports: [
        CommonModule,
        AdaptButtonModule,
        AdaptMicrosoftOAuthModule,
        AdaptLocalCalendarIntegrationModule,
        AdaptNoCalendarIntegrationModule,
        AdaptRenderComponentModule,
    ],
    declarations: [
        CalendarProviderSelectComponent,
        LinkExternalMeetingComponent,
    ],
    exports: [
        CalendarProviderSelectComponent,
        LinkExternalMeetingComponent,
    ],
    providers: [
        provideCalendarProvider({
            id: CalendarIntegrationProvider.Microsoft,
            name: "Microsoft 365",
            authService: MicrosoftAuthService,
            connectComponent: ConnectWithMicrosoftComponent,
            getCalendarLink: (id: string) => `https://outlook.office365.com/owa/?itemid=${encodeURIComponent(id)}&exvsurl=1&path=/calendar/item`,
            isLocal: false,
        }),
        provideCalendarProvider({
            id: CalendarIntegrationProvider.Local,
            name: "Email/iCalendar",
            authService: LocalCalendarIntegrationAuthService,
            connectComponent: ConnectLocalCalendarIntegrationComponent,
            isLocal: true,
        }),
        provideCalendarProvider({
            id: CalendarIntegrationProvider.None,
            name: "No integration",
            authService: LocalCalendarIntegrationAuthService,
            connectComponent: ConnectNoCalendarIntegrationComponent,
            isLocal: true,
        }),
    ],
})
export class AdaptCalendarModule { }

/* eslint-disable max-classes-per-file */
import { Injectable } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Logger } from "@common/lib/logger/logger";
import { DomUtilities } from "@common/lib/utilities/dom-utilities";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { DocumentDescriptor } from "../document-descriptor";
import { DocumentSelectorService } from "../document-selector.service";
import { LazyLoadLibrary } from "./lazy-load-library";
import { IStorageProvider, StorageProviderGroup } from "./storage-provider.interface";
import { StorageProviderUtilities } from "./storage-provider-utilities";

// Note that there will be 2 errors appearing in the console when the picker is showing:
//  Failed to execute 'postMessage' on 'DOMWindow': The target origin provided ('https://docs.google.com') does not match the recipient window's origin ('http://localhost:4301')
//  Invalid 'X-Frame-Options' header encountered when loading 'https://docs.google.com/picker?...
// Searched around and no solution to that. This is a google file picker vs chrome bug: https://groups.google.com/forum/#!msg/google-picker-api/_wF1O_3j7ZE/yfHhQTz2DPwJ
// and is tagged with a 'WONTFIX'. Will have to live with the errors.
@Injectable()
export class GoogleDriveProviderService implements IStorageProvider {
    private static readonly ServiceName = "GoogleDrive";

    // drive oauth scope needed for login request
    public static readonly OAuth2Scope = "https://www.googleapis.com/auth/drive.file";

    public disabled: boolean = false;

    private googleAuth?: google.accounts.oauth2.TokenClient;
    private oauthTokenResponse?: google.accounts.oauth2.TokenResponse;
    private googleDriveLazyLoad = new GoogleDriveLazyLoadLibrary();

    constructor(
        private documentSelectorService: DocumentSelectorService,
    ) { }

    public setupComponent = async () => {
        try {
            await this.googleDriveLazyLoad.promiseToInitialise();
        } catch (e) {
            this.disabled = true;
        }
    };

    public cleanupComponent(): void { /* Nothing to do */ }

    public onOrganisationChanged(organisationId?: number): void {
        if (!organisationId) { // when logging out, clear token
            this.oauthTokenResponse = undefined;
        }
    }

    public getName(): string {
        return GoogleDriveProviderService.ServiceName;
    }

    public getDisplayName(): string {
        return "Google Drive";
    }

    public getIconClass(): string {
        return "fab fa-fw fa-google";
    }

    public getGroupName = () => StorageProviderGroup.CLOUD;

    public getSelectionInProgressText(): string {
        return "Complete selection in the popup Google Drive picker";
    }

    public getDocument(url: string): DocumentDescriptor | null {
        if (StringUtilities.isString(url)) {
            if (StorageProviderUtilities.getUrlServiceNameIdentifier(url) === GoogleDriveProviderService.ServiceName) {
                let name = UrlUtilities.getQueryParamValue(url, "name");

                if (!name) { // link not really created from document selector? Can be someone added in this ServiceNameIdentifier
                    name = UrlUtilities.getFileName(url);
                }

                const result = new DocumentDescriptor(decodeURIComponent(name), url);

                result.setIconClass(this.getIconClass());
                return result;
            }
        }

        return null;
    }

    public openChooser(): Promise<DocumentDescriptor | null> {
        return new Promise((resolve, reject) => this.doChoose(resolve, reject));
    }

    private async doChoose(resolve: (document: DocumentDescriptor | null) => void, reject: (e: any) => void) {
        const self = this;

        let apiLoadedSuccessfully: boolean;
        try {
            await this.googleDriveLazyLoad.promiseToInitialise();
            apiLoadedSuccessfully = true;
        } catch (e) {
            apiLoadedSuccessfully = false;
        }

        if (apiLoadedSuccessfully) {
            // workaround for Google GIS not notifying if popup has closed anymore
            // Sourced from https://stackoverflow.com/a/73485415
            const focusEventHandler = () => {
                if (!this.oauthTokenResponse) {
                    reject(`Authentication has been aborted: popup closed`);
                }
                window.removeEventListener("focus", focusEventHandler);
            };

            this.googleAuth = google.accounts.oauth2.initTokenClient({
                client_id: AdaptClientConfiguration.GoogleClientId,
                scope: GoogleDriveProviderService.OAuth2Scope,
                callback: (tokenResponse) => {
                    window.removeEventListener("focus", focusEventHandler);

                    if (tokenResponse.error) {
                        reject(`Authentication has been aborted: ${tokenResponse.error}`);
                    }

                    this.oauthTokenResponse = tokenResponse;
                    if (google.accounts.oauth2.hasGrantedAnyScope(tokenResponse, GoogleDriveProviderService.OAuth2Scope)) {
                        createPicker(tokenResponse);
                    }
                },
            });

            // adding an event listener to detect if user is back to the webpage
            // if the user "focus" back to window then we shall close the current auth session
            window.addEventListener("focus", focusEventHandler);

            if (!this.oauthTokenResponse) {
                // Prompt the user to select a Google Account and ask for consent to share their data
                // when establishing a new session.
                this.googleAuth.requestAccessToken({ prompt: "consent" });
            } else {
                // Skip display of account chooser and consent dialog for an existing session.
                this.googleAuth.requestAccessToken({ prompt: "" });
            }
        } else {
            if (this.documentSelectorService.isCookiesEnabled()) {
                // Cannot queue this as both the auth and picker dialogs need to be spawned from within user input event
                // So just reject and let the user try again when the API is loaded.
                reject("Google Auth or Picker API not yet loaded. Please try again later.");
            } else { // both API won't load without 3rd party cookie!
                reject("Google APIs cannot be loaded without allowing 3rd-party cookies.");
            }
        }

        function createPicker(tokenResponse?: google.accounts.oauth2.TokenResponse) {
            if (tokenResponse) {
                const docsView = new google.picker.DocsView()
                    .setIncludeFolders(true)
                    .setSelectFolderEnabled(true);

                const foldersView = new google.picker.DocsView(google.picker.ViewId.FOLDERS)
                    .setSelectFolderEnabled(true);

                const sharedDriveView = new google.picker.DocsView()
                    .setEnableDrives(true)
                    .setIncludeFolders(true)
                    .setSelectFolderEnabled(true);

                const uploadView = new google.picker.DocsUploadView()
                    .setIncludeFolders(true);

                const picker = new google.picker.PickerBuilder()
                    .addView(docsView)
                    .addView(foldersView)
                    .addView(sharedDriveView)
                    .addView(uploadView)
                    .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
                    .setOAuthToken(tokenResponse.access_token)
                    .setCallback(onPickerCallback)
                    .build();
                picker.setVisible(true);
            }
        }

        function onPickerCallback(data: google.picker.ResponseObject) {
            if (data.action === google.picker.Action.CANCEL) {
                reject(null);
            } else if (data.action === google.picker.Action.PICKED) {
                if (Array.isArray(data.docs) && data.docs.length === 1) {
                    let link = data.docs[0].url;
                    const name = data.docs[0].name;

                    link = StorageProviderUtilities.addUrlServiceNameIdentifier(link, self);
                    link = UrlUtilities.setQueryParam(link, "name", name);
                    resolve(new DocumentDescriptor(name, link));
                } else {
                    reject(null);
                }
            }
        }
    }
}

class GoogleDriveLazyLoadLibrary extends LazyLoadLibrary {
    private log = Logger.getLogger("GoogleDriveLazyLoadLibrary");

    public constructor() {
        // Google client API (needed for picker)
        super("https://apis.google.com/js/api.js");
    }

    protected doInitialisation(source: Observable<void>) {
        return source.pipe(
            switchMap(() => this.loadGoogleLibrary("picker")),
            switchMap(() => this.loadGoogleLibrary("client")),
            switchMap(() => gapi.client.init({
                apiKey: AdaptClientConfiguration.GoogleApiKey,
            })),
            // Google account API (needed for sign-in)
            switchMap(() => DomUtilities.loadJsScript("https://accounts.google.com/gsi/client")),
        );
    }

    private loadGoogleLibrary(libraries: string) {
        return new Promise((resolve, reject) => {
            gapi.load(libraries, {
                callback: (value) => {
                    this.log.info(`Loaded google libraries: ${libraries}`);
                    resolve(value);
                },
                onerror: (e: any) => {
                    this.log.info(`Failed to load google libraries: ${libraries}`, e);
                    reject(e);
                },
            });
        });
    }
}

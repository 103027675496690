<adapt-dialog [maxWidth]="800">
    <h3 adapt-dialog-title>Schedule time with a coach</h3>

    <ng-container adapt-dialog-content>
        <ng-container *ngIf="scheduled">
            <h3>You've successfully scheduled your coaching session!</h3>
            <p>Check your email for more information.</p>
        </ng-container>

        <ng-container *ngIf="!scheduled">
            <h3>You have successfully paid for a coaching session!</h3>
            <p>A confirmation email has been sent to you. Please select a time and date to talk with a coach below.</p>

            <p *ngIf="!failedToLoad">If there are any issues while scheduling your coaching session,
                please contact us using Intercom (the chat icon at the bottom right of your screen).</p>
        </ng-container>

        <adapt-loading-spinner *ngIf="loading"></adapt-loading-spinner>

        <div *ngIf="failedToLoad"
             class="alert alert-danger">
            There was a problem loading our coach scheduler.
            Please contact us to schedule your session using Intercom (the chat icon at the bottom right of your
            screen).
        </div>

        <iframe *ngIf="frameUrl"
                [src]="frameUrl"
                width="100%"
                height="100%"
                frameBorder="0"
                title="Calendly Scheduling Page"
                (load)="loadingUpdater.next(false)"
                (error)="failedToLoad = true"></iframe>
    </ng-container>

    <div adapt-dialog-footer
         class="ms-auto">
        <button class="btn btn-primary"
                [disabled]="closeButtonDisabled"
                (click)="cancel()">
            <ng-container *ngIf="!closeButtonDisabled; else failed">
                <i class="fal fa-arrow-right me-1"></i> Return to dashboard
            </ng-container>
            <ng-template #failed>Close</ng-template>
        </button>
    </div>
</adapt-dialog>

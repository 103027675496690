export interface IButtonType {
    buttonClass: string;
    buttonText: string;
    iconClass: string;
}

export const buttonPreset = {
    default: {
        buttonClass: "btn btn-secondary",
        buttonText: "",
        iconClass: "",
    },
    primary: {
        buttonClass: "btn btn-primary",
        buttonText: "",
        iconClass: "",
    },
    secondary: {
        buttonClass: "btn btn-secondary",
        buttonText: "",
        iconClass: "",
    },
    return: {
        buttonClass: "btn btn-primary",
        buttonText: "",
        iconClass: "fal fa-fw fa-arrow-turn-down-left",
    },
    outlinePrimary: {
        buttonClass: "btn btn-outline-primary",
        buttonText: "",
        iconClass: "",
    },
    pill: {
        buttonClass: "btn btn-primary btn-pill",
        buttonText: "",
        iconClass: "",
    },
    edit: {
        buttonClass: "btn btn-primary",
        buttonText: "Edit",
        iconClass: "fal fa-edit",
    },
    filter: {
        buttonClass: "btn btn-default",
        buttonText: "Filter",
        iconClass: "fal fa-filter",
    },
    help: {
        buttonClass: "btn btn-help",
        buttonText: "Help",
        iconClass: "fal fa-question-circle",
    },
    iconlessLink: {
        buttonClass: "btn btn-link",
        buttonText: "",
        iconClass: "",
    },
    editLink: {
        buttonClass: "btn btn-link",
        buttonText: "",
        iconClass: "fal fa-edit",
    },
    editBorderless: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fal fa-edit",
    },
    expandBorderless: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fas fa-chevrons-down",
    },
    collapseBorderless: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fas fa-chevrons-up",
    },
    printBorderless: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fal fa-print",
    },
    notCheckedBorderless: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fal fa-square",
    },
    checkedBorderless: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fal fa-check-square",
    },
    add: {
        buttonClass: "btn btn-primary",
        buttonText: "Add",
        iconClass: "fal fa-fw fa-plus-square",
    },
    addLink: {
        buttonClass: "btn btn-link",
        buttonText: "",
        iconClass: "fal fa-plus-square",
    },
    addBorderless: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fal fa-plus-square",
    },
    downloadLink: {
        buttonClass: "btn btn-link",
        buttonText: "",
        iconClass: "fal fa-download",
    },
    fileDownloadLink: {
        buttonClass: "btn btn-link",
        buttonText: "",
        iconClass: "fal fa-file-download",
    },
    detachBorderless: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fal fa-link-slash",
    },
    delete: {
        buttonClass: "btn btn-danger",
        buttonText: "Delete",
        iconClass: "fal fa-trash",
    },
    deleteBorderless: {
        buttonClass: "btn btn-blank btn-danger",
        buttonText: "",
        iconClass: "far fa-trash",
    },
    deleteAndClose: {
        buttonClass: "btn btn-danger",
        buttonText: "Delete & close",
        iconClass: "fal fa-fw fa-trash",
    },
    deleteAndSave: {
        buttonClass: "btn btn-danger",
        buttonText: "Delete & save",
        iconClass: "fal fa-fw fa-trash",
    },
    end: {
        buttonClass: "btn btn-warning",
        buttonText: "End",
        iconClass: "fal fa-minus-square",
    },
    clear: {
        buttonClass: "btn btn-danger",
        buttonText: "Clear",
        iconClass: "fal fa-minus-square",
    },
    save: {
        buttonClass: "btn btn-success",
        buttonText: "Save",
        iconClass: "fal fa-save",
    },
    saveAndClose: {
        buttonClass: "btn btn-primary",
        buttonText: "Save & close",
        iconClass: "fal fa-save",
    },
    wizardLink: {
        buttonClass: "btn btn-link",
        buttonText: "",
        iconClass: "fal fa-sparkles",
    },
    refreshLink: {
        buttonClass: "btn btn-link",
        buttonText: "",
        iconClass: "fal fa-repeat",
    },
    cancel: {
        buttonClass: "btn btn-default",
        buttonText: "Cancel",
        iconClass: "fal fa-undo",
    },
    iconlessCancel: {
        buttonClass: "btn btn-default",
        buttonText: "Cancel",
        iconClass: "",
    },
    tour: {
        // can't use tour on mobile so hide the button
        buttonClass: "btn btn-default d-none d-md-inline-flex",
        buttonText: "Tour",
        iconClass: "fal fa-location-arrow",
    },
    view: {
        buttonClass: "btn btn-default",
        buttonText: "View",
        iconClass: "fal fa-eye",
    },
    viewBorderless: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fal fa-eye",
    },
    dismissBorderless: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fal fa-xmark",
    },
    move: {
        buttonClass: "btn btn-primary",
        buttonText: "Move",
        iconClass: "fal fa-arrows-alt",
    },
    copy: {
        buttonClass: "btn btn-primary",
        buttonText: "Copy",
        iconClass: "fal fa-copy",
    },
    browse: {
        buttonClass: "btn btn-default",
        buttonText: "Browse",
        iconClass: "fal fa-search",
    },
    search: {
        buttonClass: "btn btn-link",
        buttonText: "",
        iconClass: "fal fa-search",
    },
    back: {
        buttonClass: "btn btn-default",
        buttonText: "Back",
        iconClass: "fal fa-arrow-left",
    },
    next: {
        buttonClass: "btn btn-default",
        buttonText: "Next",
        iconClass: "fal fa-fw fa-arrow-circle-right",
    },
    previous: {
        buttonClass: "btn btn-default",
        buttonText: "Previous",
        iconClass: "fal fa-fw fa-arrow-circle-left",
    },
    collapseLeft: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fal fa-chevron-circle-left",
    },
    navigateTo: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fal fa-chevron-circle-right",
    },
    refresh: {
        buttonClass: "btn btn-default",
        buttonText: "Refresh",
        iconClass: "fal fa-sync-alt",
    },
    resetBorderless: {
        buttonClass: "btn btn-blank",
        buttonText: "Reset",
        iconClass: "fal fa-sync-alt",
    },
    merge: {
        buttonClass: "btn btn-default",
        buttonText: "Merge",
        iconClass: "fal fa-compress",
    },
    mergePrimary: {
        buttonClass: "btn btn-primary",
        buttonText: "Merge",
        iconClass: "fal fa-compress",
    },
    configure: {
        buttonClass: "btn btn-default",
        buttonText: "Configure",
        iconClass: "fal fa-cog",
    },
    configureLink: {
        buttonClass: "btn btn-link",
        buttonText: "",
        iconClass: "fal fa-cog",
    },
    dashboardLink: {
        buttonClass: "btn btn-link",
        buttonText: "",
        iconClass: "fal fa-chart-line",
    },
    ok: {
        buttonClass: "btn btn-primary",
        buttonText: "OK",
        iconClass: "fal fa-fw fa-check",
    },
    saveAndReview: {
        buttonClass: "btn btn-primary",
        buttonText: "Save and review",
        iconClass: "fal fa-fw fa-check",
    },
    confirmationOk: {
        buttonClass: "btn btn-danger",
        buttonText: "OK",
        iconClass: "",
    },
    close: {
        buttonClass: "btn btn-default",
        buttonText: "Close",
        iconClass: "",
    },
    closeBorderless: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fal fa-times",
    },
    cloudUpload: {
        buttonClass: "btn btn-primary",
        buttonText: "",
        iconClass: "fal fa-fw fa-cloud-upload",
    },
    duplicateAndEdit: {
        buttonClass: "btn btn-primary",
        buttonText: "Duplicate & edit",
        iconClass: "fal fa-fw fa-clone",
    },
    workflowPrevious: {
        buttonClass: "btn btn-primary",
        buttonText: "",
        iconClass: "fal fa-fw fa-arrow-to-left",
    },
    workflowNext: {
        buttonClass: "btn btn-primary",
        buttonText: "",
        iconClass: "fal fa-fw fa-arrow-to-right",
    },
    workflowSkip: {
        buttonClass: "btn btn-primary",
        buttonText: "",
        iconClass: "fal fa-fw fa-angle-double-right",
    },
    workflowFinish: {
        buttonClass: "btn btn-primary",
        buttonText: "",
        iconClass: "fal fa-fw fa-clipboard-list-check",
    },
    duplicateAndRedirect: {
        buttonClass: "btn btn-primary",
        buttonText: "Duplicate & redirect",
        iconClass: "fal fa-fw fa-clone",
    },
    reorder: {
        buttonClass: "btn btn-primary",
        buttonText: "",
        iconClass: "fal fa-fw fa-bars-staggered",
    },
    coachAccess: {
        buttonClass: "btn btn-open-blue btn-pill",
        buttonText: "Talk to a coach",
        iconClass: "fal fa-whistle",
    },
    archiveBorderless: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fal fa-archive",
    },
    unarchiveBorderless: {
        buttonClass: "btn btn-blank",
        buttonText: "",
        iconClass: "fal fa-box-open",
    },
    archiveAndSave: {
        buttonClass: "btn btn-primary",
        buttonText: "Archive & save",
        iconClass: "fal fa-fw fa-archive",
    },
};

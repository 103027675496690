import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { IMeetingCustomData, Meeting, MeetingStatus } from "@common/ADAPT.Common.Model/organisation/meeting";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { WorkflowStatusEnum } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { GuidedTourService } from "@common/lib/guided-tour/guided-tour.service";
import { IAdaptLinkObject } from "@common/route/route.service";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { Event } from "@microsoft/microsoft-graph-types-beta";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { MicrosoftCalendarService } from "@org-common/lib/calendar/microsoft-calendar.service";
import { OAuthService } from "@org-common/lib/oauth/oauth.service";
import { MeetingTabId } from "@org-common/lib/shell/meeting-tab-content/meeting-tab-id";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { WorkflowAuthService } from "@org-common/lib/workflow/workflow-auth.service";
import { WorkflowMeetingService } from "@org-common/lib/workflow/workflow-meeting.service";
import { saveAs } from "file-saver";
import { EMPTY, lastValueFrom, Observable, of } from "rxjs";
import { catchError, combineLatestWith, debounceTime, filter, map, switchMap, take, tap } from "rxjs/operators";
import { MeetingsService } from "../meetings.service";
import { MeetingsUiService } from "../meetings-ui.service";
import { TeamActiveMeetingTour } from "../team-active-meeting-page/team-active-meeting-tour";

type MenuSize = "sm" | "lg";

@Component({
    selector: "adapt-meeting-details-menu",
    templateUrl: "./meeting-details-menu.component.html",
})
export class MeetingDetailsMenuComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public menuSize: MenuSize = "lg";
    @Input() public meeting!: Meeting;
    @Input() public update$: Observable<void> = of(void 0);
    @Input() public extraMenuOptions?: IAdaptMenuItem[];
    @Input() public showAgendaEditor = false;
    @Input() public showTour = false;
    @Output() public showAgendaEditorChange = new EventEmitter<boolean>();

    @Output() public meetingChange = new EventEmitter<Meeting>();
    @Output() public meetingDeleted = new EventEmitter<Meeting>();

    public useActiveMeetingPage = false;
    public meetingMenu: IAdaptMenuItem[] = [];
    public canEditMeeting = false;

    public constructor(
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
        private workflowService: WorkflowService,
        private workflowMeetingService: WorkflowMeetingService,
        private dialogService: AdaptCommonDialogService,
        private oauthService: OAuthService,
        private microsoftCalendarService: MicrosoftCalendarService,
        private guidedTourService: GuidedTourService,
        private shellUiService: ShellUiService,
        private authorisationService: AuthorisationService,
        injector: Injector,
    ) {
        super();
        this.shellUiService = injector.get(ShellUiService);
    }

    public ngOnInit() {
        this.update$.pipe(
            debounceTime(10),
            switchMap(() => this.updateData()),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.meetingsUiService.isUsingActiveMeetingPage$.pipe(
            combineLatestWith(this.shellUiService.tabIsEnabled(MeetingTabId)),
            this.takeUntilDestroyed(),
        ).subscribe(([useActiveMeetingPage, tabIsEnabled]) => {
            this.useActiveMeetingPage = tabIsEnabled && useActiveMeetingPage;
        });
    }

    public async updateData() {
        const canStartMeeting = await lastValueFrom(this.meetingsService.canStartMeeting(this.meeting));
        await this.buildMenu(canStartMeeting);
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.extraMenuOptions) {
            await this.updateData();
        }
    }

    public editAgendaToggled() {
        if (this.meeting.extensions.isEnded) {
            if (this.showAgendaEditor) {
                this.showAgendaEditor = false;
                this.showAgendaEditorChange.emit(this.showAgendaEditor);
            } else {
                // show confirmation dialog as this will be an unusual operation path
                return this.meetingsUiService.promptForEndedMeetingAgendaEdit().pipe(
                    this.takeUntilDestroyed(),
                ).subscribe(() => {
                    this.showAgendaEditor = true;
                    this.showAgendaEditorChange.emit(this.showAgendaEditor);
                });
            }
        }
    }

    public deleteTeamMeeting() {
        const dialogData: IConfirmationDialogData = {
            title: "Delete Meeting",
            message: "<p>Are you sure you want to delete this meeting?</p>",
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
        };

        if (this.meeting.meetingAgendaItems.length > 0) {
            dialogData.message += `<p>Note that all decisions and minutes associated with the meeting agenda
                items will be deleted and action items will be unlinked from the meeting.</p>`;
        }

        let uidToDelete: string | undefined;
        return this.preventMeetingDeleteIfWorkflowActive().pipe(
            switchMap(() => this.oauthService.isAuthedWithProvider(CalendarIntegrationProvider.Microsoft)),
            // only add deletion message if user is authed
            tap((authed) => {
                const customData = this.meeting.extensions.getCustomData<IMeetingCustomData>();
                if (authed && customData.microsoftUniqueId) {
                    uidToDelete = customData.microsoftUniqueId;
                    if (customData.microsoftUserId === this.oauthService.user?.userId) {
                        dialogData.message += `<p>The meeting will also be deleted from your integrated calendar and the calendars of each attendee.</p>`;
                    } else {
                        dialogData.message += `<p>The meeting will also be deleted from your integrated calendar, however since you are not the organiser it will remain in every other calendar.</p>`;
                    }
                }
            }),
            switchMap(() => this.dialogService.openConfirmationDialogWithBoolean(dialogData)),
            filter((confirmDelete) => !!confirmDelete),
            // only need to delete meeting as MeetingRepositoryEntity will take care of the associated entities deletion
            switchMap(() => this.meetingsService.remove(this.meeting)),
            switchMap(() => this.meetingsService.saveEntities([this.meeting])),
            // remove associated calendar entry
            switchMap(async () => {
                if (uidToDelete) {
                    let existingMeeting: Event | undefined;
                    try {
                        existingMeeting = await this.microsoftCalendarService.getMeeting(uidToDelete);
                    } catch (e) {
                        // failed to fetch the meeting, don't need to delete if so
                        return;
                    }

                    if (existingMeeting) {
                        await this.microsoftCalendarService.deleteMeeting(existingMeeting.id!);
                    }
                }
            }),
            tap(() => this.meetingDeleted.emit(this.meeting)),
        );
    }

    public sendMeetingInvitations() {
        return this.meetingsService.sendMeetingCalendarInvites(this.meeting).pipe(
            switchMap((result) => result
                ? this.dialogService.showMessageDialog("Invitations Sent", "Successfully sent meeting invitations!")
                : EMPTY),
            catchError(() => this.dialogService.showMessageDialog("Invitation Failed", "Failed to send meeting invitations.")),
        );
    }

    public exportMeetingCalendarFile() {
        return this.meetingsService.getMeetingCalendarFile(this.meeting).pipe(
            tap((response) => {
                if (response) {
                    saveAs(response.body!, `${this.meeting.name}.ics`);
                }
            }),
            catchError(() => this.dialogService.showMessageDialog("Export Failed", "Failed to export the meeting calendar.")),
        );
    }

    public restartEndedMeeting() {
        this.meeting.status = MeetingStatus.InProgress;
        return this.meetingsUiService.editMeeting(this.meeting, "Restart Meeting").pipe(
            switchMap(() => this.startMeeting()),
        );
    }

    public startMeeting() {
        return this.meetingsUiService.startMeeting(this.meeting);
    }

    public rescheduleEndedMeeting() {
        this.meetingsService.rescheduleMeeting(this.meeting);
        return this.editTeamMeeting("Reschedule meeting");
    }

    public editTeamMeeting(title?: string) {
        return this.meetingsUiService.editMeeting(this.meeting, title).pipe(
            tap(() => this.meetingChange.emit(this.meeting)),
        );
    }

    public runTour() {
        this.guidedTourService.run(TeamActiveMeetingTour);
    }

    private moveMeetingToSidebar(meeting: Meeting) {
        this.meetingsUiService.moveMeetingToSidebar(meeting);
        return this.meetingsService.gotoTeamMeetingsPage(meeting.team!.teamId);
    }

    private rescheduleMeeting(meeting: Meeting) {
        return this.meetingsUiService.rescheduleMeeting(meeting);
    }

    private preventMeetingDeleteIfWorkflowActive() {
        return this.workflowMeetingService.getMeetingWorkflowConnection(this.meeting).pipe(
            switchMap((workflowConnection) => {
                if (!workflowConnection) {
                    return of(undefined);
                }

                return this.workflowService.getStatusForWorkflow(workflowConnection).pipe(
                    switchMap((status) => {
                        if (!status || status.status === WorkflowStatusEnum.Completed) {
                            return of(undefined);
                        }

                        const workflow = this.workflowService.getWorkflow(workflowConnection);
                        const url$: Observable<IAdaptLinkObject | undefined> = workflow
                            ? this.workflowService.getWorkflowPageRoute(workflow.workflowId)
                            : of(undefined);

                        return url$.pipe(
                            // cant use withLatestFrom with a promise...
                            switchMap(async (url) =>
                                ([url, await this.authorisationService.promiseToGetHasAccess(WorkflowAuthService.EditWorkflow)] as [IAdaptLinkObject, boolean])),
                            switchMap(([url, hasEditWorkflow]) => {
                                const namePhrase = url && workflow
                                    ? `the <b class="mx-1"><a href="${url.path}" target="_blank">${workflow.name} <i class="fal fa-xs fa-external-link-alt align-middle"></i></a></b>`
                                    : "a";

                                let message = `<p>This meeting is part of ${namePhrase} pathway. It cannot be deleted until the pathway has ended.</p>`;
                                message += hasEditWorkflow
                                    ? `<p>Please end the pathway before deleting this meeting.</p>`
                                    : `<p>You do not have permission to run pathways. Please see your team leader or contact us.</p>`;

                                // this will return EMPTY, hence ending the chain
                                return this.dialogService.showErrorDialog("Cannot delete meeting", message);
                            }),
                        );
                    }),
                );
            }),
        );
    }

    private async buildMenu(canStartMeeting: boolean) {
        this.canEditMeeting = this.meetingsService.canEditMeetingForTeam(this.meeting.team!);

        const deleteMeetingItem: IAdaptMenuItem = {
            text: "Delete meeting",
            icon: "fal fa-fw fa-trash-alt",
            separator: true,
            onClick: () => this.deleteTeamMeeting().subscribe(),
        };

        const menuItems: IAdaptMenuItem[] = [];
        if (!this.meeting.extensions.isEnded) {
            if (canStartMeeting) {
                menuItems.push({
                    text: "Start meeting",
                    icon: "fal fa-fw fa-play-circle",
                    onClick: () => this.startMeeting().subscribe(),
                });
            }

            if (this.canEditMeeting) {
                menuItems.push({
                    text: "Edit meeting details & agenda",
                    icon: "fal fa-fw fa-edit",
                    onClick: () => this.editTeamMeeting().subscribe(),
                });
            }

            if (this.canEditMeeting && this.meeting.status == MeetingStatus.InProgress) {
                menuItems.push({
                    text: "Reschedule meeting",
                    icon: "fal fa-fw fa-sync-alt",
                    onClick: () => this.rescheduleMeeting(this.meeting).subscribe(),
                });
            }

            if (this.useActiveMeetingPage && this.meeting.status == MeetingStatus.InProgress) {
                menuItems.push({
                    text: "Move meeting display to sidebar",
                    icon: "fal fa-fw fa-chevron-left",
                    onClick: () => this.moveMeetingToSidebar(this.meeting),
                    separatorTop: true,
                });
            }

            if (this.extraMenuOptions) {
                menuItems.push(...this.extraMenuOptions);
            }

            const isMicrosoft = await lastValueFrom(this.oauthService.authProvider$.pipe(
                take(1),
                map((provider) => provider?.id === CalendarIntegrationProvider.Microsoft),
            ));
            const customData = this.meeting.extensions.getCustomData<IMeetingCustomData>();
            if (this.canEditMeeting && (!isMicrosoft || !customData.invitationsSent)) {
                menuItems.push({
                    text: "Send calendar invitation via email to all attendees",
                    icon: "fal fa-fw fa-envelope-open-text",
                    onClick: () => this.sendMeetingInvitations().subscribe(),
                    separator: true,
                });
            }

            menuItems.push({
                text: "Download to my calendar",
                icon: "fal fa-fw fa-calendar-plus",
                onClick: () => this.exportMeetingCalendarFile().subscribe(),
            });

            if (this.canEditMeeting) {
                menuItems.push(deleteMeetingItem);
            }
        } else {
            if (canStartMeeting) {
                menuItems.push({
                    text: "Restart meeting",
                    icon: "fal fa-fw fa-redo-alt",
                    onClick: () => this.restartEndedMeeting().subscribe(),
                });
            }

            if (this.canEditMeeting) {
                menuItems.push({
                    text: "Edit meeting details & agenda",
                    icon: "fal fa-fw fa-edit",
                    onClick: () => this.editTeamMeeting().subscribe(),
                });

                menuItems.push({
                    text: "Reschedule meeting",
                    icon: "fal fa-fw fa-sync-alt",
                    onClick: () => this.rescheduleEndedMeeting().subscribe(),
                });

                menuItems.push({
                    text: "Toggle agenda editing",
                    icon: "fal fa-fw fa-money-check-edit",
                    onClick: () => this.editAgendaToggled(),
                    separator: true,
                });

                menuItems.push(deleteMeetingItem);
            }
        }

        if (AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto // only alto has tours at the moment
            && this.showTour
            && await lastValueFrom(this.meetingsUiService.isUsingActiveMeetingPage)) {
            menuItems.push({
                text: "Tour",
                icon: "fal fa-fw fa-location-arrow",
                separator: true,
                onClick: () => this.runTour(),
            });
        }

        if (menuItems.length > 0) {
            const rootMenu = this.menuSize === "sm"
                ? MenuComponent.SmallRootMenu
                : MenuComponent.StandardRootMenu;
            const menu: IAdaptMenuItem = { ...rootMenu, items: menuItems };
            this.meetingMenu = [menu];
        } else {
            this.meetingMenu = [];
        }
    }
}

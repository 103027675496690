import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonFlag } from "@common/ADAPT.Common.Model/person/person-flag.enum";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { lastValueFrom } from "rxjs";
import { PersonFlagService } from "../../person/person-flag.service";

@Component({
    selector: "adapt-dismissible-cta",
    templateUrl: "./dismissible-cta.component.html",
    styleUrls: ["./dismissible-cta.component.scss"],
})
export class DismissibleCtaComponent extends BaseComponent implements OnInit {
    @Input() public flag?: PersonFlag;
    @Output() public dismissCta = new EventEmitter<boolean>();

    public dismissed = true;
    public person?: Person;

    public constructor(
        private personFlagService: PersonFlagService,
        private userService: UserService,
    ) {
        super();
    }

    public async ngOnInit() {
        this.person = await this.userService.getCurrentPerson();
        this.dismissed = await this.getDismissedStatus();
    }

    public dismiss(triggeredByRun: boolean = false) {
        if (this.person && this.flag) {
            this.personFlagService.setFlagAndSave(this.person.personId, this.flag, true).pipe(
                this.takeUntilDestroyed(),
            ).subscribe((dismissed) => {
                this.dismissed = dismissed;
                this.dismissCta.next(triggeredByRun);
            });
        }
    }

    private async getDismissedStatus() {
        if (!this.flag) {
            return false;
        }

        if (this.person) {
            return lastValueFrom(this.personFlagService.isFlagEnabled(this.person.personId, this.flag));
        }
        return true;
    }
}

import { Component, Inject } from "@angular/core";
import { KeyResult } from "@common/ADAPT.Common.Model/organisation/key-result";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseConfirmationDialogComponent } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/base-confirmation-dialog.component";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { KeyResultListItemLayout } from "../key-result-list-item.component/key-result-list-item.component";

export interface IConfirmDeleteKeyResultData extends IConfirmationDialogData {
    keyResult: KeyResult;
}

@Component({
    selector: "adapt-delete-key-result-confirmation-dialog",
    templateUrl: "./delete-key-result-confirmation-dialog.component.html",
})
export class DeleteKeyResultConfirmationDialogComponent extends BaseConfirmationDialogComponent {
    public readonly dialogName = "DeleteKeyResultConfirmation";

    public keyResult: KeyResult;
    public listItemLayout = KeyResultListItemLayout.Compact;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IConfirmDeleteKeyResultData,
    ) {
        super(dialogData);

        dialogData.title = "Delete Key Result?";
        dialogData.confirmButtonText = "Delete";

        this.keyResult = dialogData.keyResult;
    }
}

<ng-container *adaptLoading="!runData.meeting">
    <adapt-schedule-meeting #focus
                            (entitiesChange)="onEntitiesChanged($event)"
                            (meetingLocationChange)="onMeetingLocationChange($event)"
                            (hasConflictsChange)="runData.hasConflicts = $event"
                            (organiserInfoChange)="runData.organiserInfo = $event"
                            [(sendInvitations)]="runData.sendInvitations"
                            [(createTeamsMeeting)]="runData.createTeamsMeeting"
                            [meeting]="runData.meeting"
                            [editableDetails]="true"></adapt-schedule-meeting>
</ng-container>

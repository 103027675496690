import { Injectable, Injector } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { Bullseye, BullseyeBreezeModel } from "@common/ADAPT.Common.Model/organisation/bullseye";
import { BullseyeQuadrant, BullseyeQuadrantStatement, BullseyeQuadrantStatementBreezeModel } from "@common/ADAPT.Common.Model/organisation/bullseye-quadrant-statement";
import { BullseyeStatementLocation, BullseyeStatementLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/bullseye-statement-location";
import { Theme } from "@common/ADAPT.Common.Model/organisation/theme";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { IAdaptMenuItem } from "@common/ux/menu/menu.component";
import { defer, forkJoin, map, Observable, of, shareReplay, Subject, switchMap, tap } from "rxjs";
import { AuthorisationService } from "../authorisation/authorisation.service";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { OrganisationService } from "../organisation/organisation.service";
import { StrategyAuthService } from "../strategy/strategy-auth.service";
import { StrategicViewIcon } from "../strategy/strategy-view-constants";
import { AttachBullseyeStatementsDialogComponent, IAttachBullseyeStatementDialogData } from "./attach-bullseye-statements-dialog/attach-bullseye-statements-dialog.component";

@Injectable({
    providedIn: "root",
})
export class BullseyeService extends BaseOrganisationService {
    private bullseyeAttachmentChanged = new Subject<void>();
    private bullseye$?: Observable<Bullseye>;

    public constructor(
        injector: Injector,
        private orgService: OrganisationService,
        private dialogService: AdaptCommonDialogService,
        private authorisationService: AuthorisationService,
    ) {
        super(injector);
    }

    protected organisationInitialisationActions() {
        return [
            defer(() => {
                this.bullseye$ = undefined;
                return of(undefined);
            }),
        ];
    }

    public get bullseyeAttachmentChanged$() {
        return this.bullseyeAttachmentChanged.asObservable();
    }

    public getAttachBullseyeStatementMenuItem(theme: Theme) {
        return {
            text: "Attach bullseye inputs",
            icon: StrategicViewIcon.BullseyeIcon,
            onClick: () => this.attachBullseyeStatement(theme).pipe(
                tap(() => this.bullseyeAttachmentChanged.next()),
            ).subscribe(),
        } as IAdaptMenuItem;
    }

    /**
     * The creation of the bullseye if it does not exist is implicit and saved - so this function
     * will always return a bullseye regardless of whether it exists before or not.
     * There will only be 1 bullseye per organisation - (bullseye identifier is the organisationId)
     * - bullseye can be incomplete though - and only completed bullseye will be shown in the bullseye page
     *
     * @returns Bullseye entity for the organisation
     */
    public getBullseye() {
        if (!this.bullseye$) {
            this.bullseye$ = this.commonDataService.getById(BullseyeBreezeModel, this.organisationId).pipe(
                switchMap((bullseye) => bullseye
                    ? of(bullseye)
                    : forkJoin([
                        this.authorisationService.promiseToGetHasAccess(StrategyAuthService.EditBullseye),
                        this.commonDataService.create(
                            BullseyeBreezeModel,
                            {
                                organisationId: this.orgService.getOrganisationId(),
                                quadrantStatements: [],
                            } as Partial<Bullseye>,
                        )]).pipe(
                            switchMap(([hasAccess, newBullseye]) => {
                                if (!hasAccess) {
                                    newBullseye.entityAspect.rejectChanges();
                                    return of(newBullseye);
                                }

                                return this.commonDataService.saveEntities(newBullseye).pipe(map(() => newBullseye));
                            }),
                        )),
                shareReplay(1),
            );
        }

        return this.bullseye$;
    }

    public getStatementsForQuadrant(quadrant?: BullseyeQuadrant) {
        return this.getBullseye().pipe(
            map((bullseye) => bullseye.quadrantStatements
                .filter((statement) => statement.quadrant === quadrant)
                .sort((a, b) => a.bullseyeQuadrantStatementId - b.bullseyeQuadrantStatementId)), // creation order
        );
    }

    public createStatementForQuadrant(quadrant?: BullseyeQuadrant) {
        return this.getBullseye().pipe(
            switchMap((bullseye) => this.commonDataService.create(BullseyeQuadrantStatementBreezeModel, {
                organisationId: bullseye.organisationId,
                quadrant,
            })),
        );
    }

    public deleteStatement(statement: BullseyeQuadrantStatement) {
        return this.commonDataService.remove(statement);
    }

    public createBullseyeStatementLocationWithStatement(bullseyeStatement: BullseyeQuadrantStatement) {
        return this.commonDataService.create(BullseyeStatementLocationBreezeModel, {
            bullseyeStatement,
            ordinal: 0,
            organisationId: this.organisationId,
        });
    }

    public getBullseyeStatementLocationsForTheme(theme: Theme) {
        const predicate = new MethodologyPredicate<BullseyeStatementLocation>("themeId", "==", theme.themeId);
        return this.commonDataService.getByPredicate(BullseyeStatementLocationBreezeModel, predicate);
    }

    public getBullseyeStatementLocationsForZone(zone: Zone) {
        const predicate = new MethodologyPredicate<BullseyeStatementLocation>("theme.zone", "==", zone);
        return this.commonDataService.getByPredicate(BullseyeStatementLocationBreezeModel, predicate).pipe(
            map((inputLocations) => inputLocations.sort((l1, l2) => l1.ordinal - l2.ordinal)),
        );
    }

    public updateBullseyeStatementLocationOrdinal(bullseyeStatementLocation: BullseyeStatementLocation) {
        return this.getBullseyeStatementLocationsForTheme(bullseyeStatementLocation.theme).pipe(
            tap((locations) => {
                const maxOrdinal = locations
                    .filter((i) => i.bullseyeStatementLocationId !== bullseyeStatementLocation.bullseyeStatementLocationId)
                    .reduce((max, i) => i.ordinal > max ? i.ordinal : max, 0);
                bullseyeStatementLocation.ordinal = maxOrdinal + 1;
            }),
        );
    }

    public attachBullseyeStatement(theme: Theme) {
        return this.dialogService.open(AttachBullseyeStatementsDialogComponent, { theme } as IAttachBullseyeStatementDialogData);
    }

    public detachBullseyeStatementLocation(bullseyeStatementLocation: BullseyeStatementLocation) {
        const dialogData: IConfirmationDialogData = {
            title: `Detaching Bullseye Input...`,
            message: `<p>You are about to detach bullseye input:</p>
                <blockquote><b>${bullseyeStatementLocation.bullseyeStatement.statement}</b></blockquote>
                <p>from the current location.</p>
                <p>Are you sure you want to continue?</p>`,
            confirmButtonText: "Confirm & Detach",
            cancelButtonText: "Cancel",
        };
        return this.dialogService.openConfirmationDialog(dialogData).pipe(
            // don't have to delete input locations as InputRepositoryEntity will handle that and return deleted associated entities
            // in saveMap to clean up our breeze manager cache
            switchMap(() => this.remove(bullseyeStatementLocation)),
            switchMap(() => this.saveEntities(bullseyeStatementLocation)),
        );
    }
}

import { Component, Inject } from "@angular/core";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { IEmailTemplate } from "../email-template.interface";

@Component({
    selector: "adapt-preview-email-dialog-component",
    templateUrl: "./preview-email-dialog-component.component.html",
    styleUrls: ["./preview-email-dialog-component.component.scss"],
})
export class PreviewEmailDialogComponent extends BaseDialogComponent<void> {
    public readonly dialogName = "PreviewEmail";

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public template: IEmailTemplate,
    ) {
        super();
    }
}

import { Component, Inject, Input } from "@angular/core";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ORGANISATION_CONFIGURATION_PAGE, PERSONAL_CONFIGURATION_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { IAdaptLinkObject } from "@common/route/route.service";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { lastValueFrom } from "rxjs";
import { AuthorisationService } from "../../../authorisation/authorisation.service";
import { ICalendarProvider } from "../../../calendar/calendar.interface";
import { OAuthService } from "../../../oauth/oauth.service";
import { ConfigurationAuthService } from "../../configuration-auth.service";
import { ConfigArea } from "../../configuration-types";

@Component({
    selector: "adapt-configure-calendar-integration",
    templateUrl: "./configure-calendar-integration.component.html",
})
export class ConfigureCalendarIntegrationComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;
    @Input() public personalConfig = false;

    public readonly projectLabel = AdaptClientConfiguration.AdaptProjectLabel;
    public readonly ConfigArea = ConfigArea;

    public hasConfigureAccess = false;
    public calendarProvider?: ICalendarProvider;
    public personalConfigRoute?: IAdaptLinkObject;
    public organisationConfigRoute?: IAdaptLinkObject;

    public constructor(
        configurationService: ConfigurationService,
        private authorisationService: AuthorisationService,
        public oauthService: OAuthService,
        @Inject(PERSONAL_CONFIGURATION_PAGE) public personalConfigurationPageRoute: IAdaptRoute<{}>,
        @Inject(ORGANISATION_CONFIGURATION_PAGE) public organisationConfigurationPageRoute: IAdaptRoute<{}>,
    ) {
        super(configurationService);

        this.oauthService.authProvider$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((provider) => this.calendarProvider = provider);
    }

    public get integrationIsDisabled() {
        return this.calendarProvider?.id === CalendarIntegrationProvider.None;
    }

    public get providerText() {
        if (this.calendarProvider) {
            return this.calendarProvider.name;
        }

        return "Unknown";
    }

    public async initialiseData() {
        this.hasConfigureAccess = await this.authorisationService.promiseToGetHasAccess(ConfigurationAuthService.ConfigureOrganisation);
        this.personalConfigRoute = await lastValueFrom(this.personalConfigurationPageRoute.getRouteObject());
        this.organisationConfigRoute = await lastValueFrom(this.organisationConfigurationPageRoute.getRouteObject());
    }

    @Autobind
    public resetIntegration() {
        return this.oauthService.resetDefaultProvider();
    }
}

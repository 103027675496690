import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, Inject, Injector, OnInit, ViewChild } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { RoleInOrganisation } from "@common/ADAPT.Common.Model/organisation/connection";
import { SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { AdaptProject, AdaptProjectLabel } from "@common/configuration/adapt-client-configuration";
import { IdentityService } from "@common/identity/identity.service";
import { IdentityStorageService } from "@common/identity/identity-storage.service";
import { loginPageRoute } from "@common/identity/ux/login-page/login-page.route";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ILogInstance } from "@common/lib/logger/log-provider.interface";
import { Logger } from "@common/lib/logger/logger";
import { SentryLogProvider } from "@common/lib/logger/sentry-log-provider";
import { Trace } from "@common/lib/logger/trace";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { PERSONAL_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute, PageRouteBuilder } from "@common/route/page-route-builder";
import { ReturnToStartupPathwayTourIdentifier } from "@common/shell/toolbar/return-path-button/return-path-tour";
import { BaseRoutedComponent, setReturnPathSearchParam } from "@common/ux/base-routed.component";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { PrivacyPolicyPageRoute$, TermsConditionsPageRoute$ } from "@org-common/lib/pages/privacy-terms-conditions-page/privacy-terms-conditions-page.component";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { organisationDiagnosticSurveySummaryPage } from "@org-common/lib/survey/survey-summary-page/survey-summary-page.component";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { DxValidationGroupComponent } from "devextreme-angular";
import { catchError, EMPTY, finalize, lastValueFrom, switchMap, tap } from "rxjs";
import { Workflows } from "../../workflows/workflows";
import { AccountService, ISignupSelfLedBindingModel } from "../account.service";

@Component({
    selector: "adapt-signup-page",
    templateUrl: "./signup-page.component.html",
    styleUrls: ["./signup-page.component.scss"],
})
export class SignupPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly TermsConditionsPageRoute$ = TermsConditionsPageRoute$();
    public readonly PrivacyPolicyPageRoute$ = PrivacyPolicyPageRoute$();
    public readonly projectLabel = AdaptProjectLabel[AdaptProject.Alto];

    public loginPage$ = loginPageRoute.getRoute();
    public signupData: ISignupSelfLedBindingModel = {
        firstName: "",
        lastName: "",
        emailAddress: "",
        password: "",
        companyName: "",
        roleInOrganisation: RoleInOrganisation.OwnerLeader,
    };

    public hasCompletedHealthCheck = false;
    public errorMessage?: string;
    public isProcessing = false;
    @ViewChild("validationGroup") private validationGroup?: DxValidationGroupComponent;
    private validationGroupValid = false;

    public constructor(
        private injector: Injector,
        elementRef: ElementRef,
        private identityStorageService: IdentityStorageService,
        private identityService: IdentityService,
        private orgService: OrganisationService,
        private accountService: AccountService,
        private workflowService: WorkflowService,
        private featuresService: FeaturesService,
        @Inject(PERSONAL_DASHBOARD_PAGE) private personalDashboardPageRoute: IAdaptRoute<{}>,
    ) {
        super(injector, elementRef);
    }

    public get canSignup() {
        return this.fieldsHaveValues && this.validationGroupValid;
    }

    public get fieldsHaveValues() {
        return !!this.signupData.firstName &&
            !!this.signupData.lastName &&
            !!this.signupData.password &&
            !!this.signupData.emailAddress &&
            !!this.signupData.companyName;
    }

    public ngOnInit() {
        this.notifyActivated();
        this.removeDefaultShellPadding();

        this.signupData.referrer = this.getSearchParameterValue("ref");
        this.hasCompletedHealthCheck = SurveyService.hasStoredCompletedSurvey(SurveyType.OrganisationDiagnostic);
    }

    public validateGroup() {
        // delay validating the whole form until all the fields have values
        // so that the fields don't appear invalid before they've been filled out
        if (this.fieldsHaveValues) {
            this.validationGroupValid = !!this.validationGroup?.instance.validate().isValid;
        }
    }

    @Autobind
    public signup() {
        this.isProcessing = true;
        this.errorMessage = undefined;

        const sentryLogger = Logger.getLogProviderOfType(SentryLogProvider);
        sentryLogger?.write(
            {
                level: Trace.Info,
                timestamp: new Date(),
                moduleId: "SignupPageComponent",
                message: "Sign-up data",
                data: [this.signupData],
            } as ILogInstance,
        );

        if (this.hasCompletedHealthCheck) {
            const storedResponses = SurveyService.getSurveyQuestionsFromStorage(SurveyType.OrganisationDiagnostic);
            if (storedResponses) {
                this.signupData.healthCheckQuestionResponses = storedResponses.map((q) => ({
                    surveyQuestionId: q.questionId,
                    response: q.response,
                }));
            }
        }

        let detourToHealthCheckSummary = false;
        return this.accountService.signupOrganisation(this.signupData).pipe(
            switchMap(async (result) => {
                this.shellUiService.setViewIsLoading(true);

                // make sure we go direct to the new org, don't need to follow normal flow of checking for default org etc.
                this.orgService.setOrganisationUrlIdentifierOverride(result.organisationUrlIdentifier);

                this.identityStorageService.sessionData = {
                    access_token: result.accessToken,
                    userName: this.signupData.emailAddress,
                };

                // need this to set cookie or the initialisation cookie check will fail
                await this.identityService.validateAuthenticationToken();
                // If this is not there, we will sometimes get a 401 when trying to switch as that's attempting to access entity and without
                // organisation ready - user entity not obtained -> auth header not set
                await this.orgService.promiseToWaitForOrganisationReady();

                if (result.surveyId) {
                    SurveyService.clearSurveyQuestionsFromStorage(SurveyType.OrganisationDiagnostic);
                    SurveyService.storeCompletedHealthCheck(this.injector, result.surveyId);
                    detourToHealthCheckSummary = true;
                    const featureStatus = await this.featuresService.promiseToSetFeatureStatus(FeatureName.OrganisationDiagnostic, undefined, true);
                    await lastValueFrom(this.featuresService.saveEntities(featureStatus));
                }

                return result;
            }),
            // assign the onboarding workflow to the user so it shows in their active pathways
            switchMap(() => this.workflowService.createWorkflowConnectionForWorkflow(Workflows[WorkflowIdentifier.WelcomeOnboarding])),
            tap(() => this.routeService.skipDialogCloseAll = true),
            switchMap(() => detourToHealthCheckSummary
                ? this.personalDashboardPageRoute.getRoute(undefined, { startOnboarding: true }).pipe(
                    switchMap((returnRoute) => {
                        const returnRouteSearchParams = setReturnPathSearchParam(
                            this.injector,
                            {},
                            organisationDiagnosticSurveySummaryPage.id,
                            "Proceed to onboarding",
                            returnRoute,
                            ReturnToStartupPathwayTourIdentifier,
                            true);
                        return organisationDiagnosticSurveySummaryPage.gotoRoute(undefined, returnRouteSearchParams);
                    }))
                : this.personalDashboardPageRoute.gotoRoute(undefined, { startOnboarding: true })),
            catchError((e: HttpErrorResponse) => {
                this.errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e);
                return EMPTY;
            }),
            finalize(() => this.isProcessing = false),
        );
    }
}

export const SignupPageRoute = new PageRouteBuilder()
    .usingNgComponent("adapt-signup-page", SignupPageComponent)
    .atUrl("/account/signup")
    .requiresLogin(false)
    .requiresAnonymous(true)
    .build();

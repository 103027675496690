import { Component, Inject, ViewChild } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { IdentityService } from "@common/identity/identity.service";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { DxValidationGroupComponent } from "devextreme-angular";

@Component({
    selector: "adapt-change-password-dialog",
    templateUrl: "./change-password-dialog.component.html",
})
export class ChangePasswordDialogComponent extends BaseDialogComponent<Person> {
    public readonly dialogName = "ChangePassword";

    @ViewChild(DxValidationGroupComponent) public validationGroup?: DxValidationGroupComponent;

    public oldPassword = "";
    public newPassword = "";
    public confirmPassword = "";

    public saveDisabled = true;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public person: Person,
        private identityService: IdentityService,
    ) {
        super();
    }

    @Autobind
    public passwordComparison() {
        return this.newPassword;
    }

    public validateGroup() {
        this.saveDisabled = !this.validationGroup || !this.validationGroup.instance.validate().isValid;
    }

    @Autobind
    public async saveAndClose() {
        const payload = {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
        };

        try {
            await this.identityService.changePassword(payload);
            this.resolve(this.person);
        } catch (error) {
            this.oldPassword = "";

            const message = ErrorHandlingUtilities.getHttpResponseMessage(error);
            this.setErrorMessage(message);
        }
    }
}

<div class="card"
     *adaptLoadingUntilFirstEmit="let account of account$">
    <div class="card-header">
        <h3 class="card-title">Subscription</h3>
    </div>
    <div class="card-body">
        <adapt-configure-eula [account]="account"
                              class="mb-3"></adapt-configure-eula>
        <ng-container *ngIf="account && account.extensions.isActive; else notBilledTemplate">
            <ng-container *ngIf="account.extensions.isFree; else chargedSubscriptionTemplate">
                You are on a free plan.
            </ng-container>

            <button class="mt-2"
                    adaptButton="primary"
                    [adaptBlockingClick]="accountService.cancelSubscriptionDialog"
                    [unsubscribeOnDestroy]="false">Cancel subscription</button>
        </ng-container>
    </div>

    <ng-template #chargedSubscriptionTemplate>
        <ng-container *ngIf="account && account.extensions.isUsingPricingModel; else legacyBillingModelTemplate">
            <p>
                Your {{AdaptProjectLabel}} subscription
                <span data-test="subscription-payment-summary"
                      *ngIf="account.pricingModel?.monthlyFeeDollars">
                    has a fixed {{ account.extensions.billingPeriodLabel() }} fee of
                    {{account.extensions.subscriptionCost | currency: account.currency?.code?.toUpperCase() : 'symbol' : '1.0-0' }}
                    <small>(ex. GST)</small>
                </span>
                <span *ngIf="!account.pricingModel?.monthlyFeeDollars">is based on the amount of users:</span>
            </p>
            <adapt-display-pricing-breakdown *ngIf="!account.pricingModel?.monthlyFeeDollars"
                                             [pricingModel]="account.pricingModel"
                                             [organisation]="account.organisation"></adapt-display-pricing-breakdown>
        </ng-container>

        <ng-template #legacyBillingModelTemplate>
            <p>Billed {{account?.monthlyFeeDollars | currency:account?.currency?.code?.toUpperCase()}}/month
                <small> (ex. GST)</small>
            </p>
        </ng-template>

        <div *ngIf="invoice$ | async as invoice">
            <p>Your next payment is due <strong>{{invoice.date | adaptDate}}</strong></p>
            <button *adaptIfAlto="true"
                    adaptButton="primary"
                    data-test="change-payment-frequency"
                    [adaptBlockingClick]="changePaymentFrequency"
                    [unsubscribeOnDestroy]="true">Change payment frequency</button>
        </div>
    </ng-template>

    <ng-template #notBilledTemplate>
        <p *ngIf="!account?.extensions?.isPendingCancellation">Currently, your organisation does not have an active subscription
            to adapt.</p>
        <p *ngIf="account?.extensions?.isPendingCancellation">You have cancelled your subscription.</p>

        <ng-container *ngIf="(canChargeOrganisation$ | async); else noPermissionToChargeSubscriptionTemplate">
            <div role="alert"
                 class="alert alert-info mt-3">
                <h4><span class="fal fa-info-circle me-1"></span>Potential Subscription Costs</h4>

                <label>How much would we be charging you?</label>
                <p *ngIf="account?.extensions?.isFree; else potentialChargedSubscriptionTemplate">
                    Nothing! You are on a free plan.
                </p>

                <ng-template #potentialChargedSubscriptionTemplate>
                    <ng-container *ngIf="account?.extensions?.isUsingPricingModel; else potentialLegacyBillingModelTemplate">
                        <p>You would be billed on a per user basis. The amount charged will be based on the number of
                            active users on the day of billing.</p>
                        <adapt-display-pricing-breakdown *ngIf="account?.pricingModel as pricingModel"
                                                         [pricingModel]="pricingModel"
                                                         [organisation]="account?.organisation">
                        </adapt-display-pricing-breakdown>
                    </ng-container>
                    <ng-template #potentialLegacyBillingModelTemplate>
                        <p>Annual Fee: {{account?.extensions?.annualSubscriptionCost | currency:account?.currency?.code?.toUpperCase()}} ex.
                            GST</p>
                    </ng-template>
                </ng-template>
            </div>
        </ng-container>
        <ng-template #noPermissionToChargeSubscriptionTemplate>
            <ng-container *adaptIfAlto="false">Please see your resilient business coach to initiate a subscription.</ng-container>
        </ng-template>
    </ng-template>
</div>
